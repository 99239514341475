import {
  // backgrounds
  bedroom,
  readingCorner,
  diningRoomBlue,
  livingRoom,

  // images
  fishing,
  holiday,
  stackFam,
  wedding,
  piano,
  guy,
  orangeGirl,
  baby,
  illustration,
  hercules,
  fam,
  gerriKev,
  arielWedding,
} from '../../../media';
import { AbbreviatedShapeOption } from '../../../../../common/types';

export const galleryBackgrounds = [
  bedroom,
  readingCorner,
  diningRoomBlue,
  livingRoom,
];

export const galleryImages: { src: string; shape: AbbreviatedShapeOption }[] = [
  {
    src: fishing,
    shape: 'L',
  },
  {
    src: holiday,
    shape: 'L',
  },
  {
    src: stackFam,
    shape: 'P',
  },
  {
    src: wedding,
    shape: 'P',
  },
  {
    src: piano,
    shape: 'P',
  },
  {
    src: guy,
    shape: 'S',
  },
  {
    src: orangeGirl,
    shape: 'S',
  },
  {
    src: baby,
    shape: 'S',
  },
  {
    src: illustration,
    shape: 'S',
  },
  {
    src: hercules,
    shape: 'P',
  },
  {
    src: fam,
    shape: 'L',
  },
  {
    src: gerriKev,
    shape: 'P',
  },
  {
    src: arielWedding,
    shape: 'L',
  },
];
