import React, { useState, useEffect } from 'react';

import { BladePosition } from '../../components';
import { isTablet } from '../../helpers';
import { UserHydratedOrder } from '../../types';
import { getAllOrders } from '../../api';

import { OrderRecord, ViewSelector } from './components';
import { OrderDisplay, orderViewOptions } from './types';

export type Props = {
  position: BladePosition;
};

export const Orders = ({ position }: Props) => {
  const [orders, setOrders] = useState<UserHydratedOrder[] | null>(null);
  const [openOrders, setOpenOrders] = useState<UserHydratedOrder[] | null>(
    null
  );
  const [completeOrders, setCompleteOrders] = useState<
    UserHydratedOrder[] | null
  >(null);

  const [orderComponents, setOrderComponents] = useState<JSX.Element[] | null>(
    null
  );
  const [openComponents, setOpenComponents] = useState<JSX.Element[] | null>(
    null
  );
  const [completeComponents, setCompleteComponents] = useState<
    JSX.Element[] | null
  >(null);

  const [display, setDisplay] = useState<OrderDisplay>('open');

  const [tablet, setTablet] = useState<boolean>(isTablet());

  /* checks for window size on mount */
  useEffect(() => {
    const onResize = () => {
      setTablet(isTablet());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    const getOrders = async () => {
      setOrders(await getAllOrders());
    };
    getOrders();
  }, []);

  useEffect(() => {
    if (display === 'open') {
      if (!openOrders) {
        setOpenOrders(orders && orders.filter((order) => !order.completed));
      }
    } else if (display === 'complete') {
      if (!completeOrders) {
        setCompleteOrders(orders && orders.filter((order) => order.completed));
      }
    }
  }, [display, orders, completeOrders, openOrders]);

  useEffect(() => {
    if (!openComponents) {
      setOpenComponents(
        openOrders &&
        openOrders.map((order, index) => (
          <OrderRecord key={index} order={order} tablet={tablet} />
        ))
      );
    }
  }, [openOrders, openComponents, tablet]);

  useEffect(() => {
    if (!completeComponents) {
      setCompleteComponents(
        completeOrders &&
        completeOrders.map((order, index) => (
          <OrderRecord key={index} order={order} tablet={tablet} />
        ))
      );
    }
  }, [completeOrders, completeComponents, tablet]);

  useEffect(() => {
    if (!orderComponents) {
      setOrderComponents(
        orders &&
        orders.map((order, index) => (
          <OrderRecord key={index} order={order} tablet={tablet} />
        ))
      );
    }
  }, [orders, orderComponents, tablet]);

  let currComponents;
  switch (display) {
    case 'open':
      currComponents = openComponents;
      break;
    case 'complete':
      currComponents = completeComponents;
      break;
    default:
      currComponents = orderComponents;
      break;
  }

  return (
    <div className={`slider-page orders-page ${position} custom-scroll`}>
      <div className="orders-container">
        <div className="admin-header">
          <h2>Orders</h2>
        </div>
        <ViewSelector
          currView={display}
          changeView={setDisplay}
          viewOptions={orderViewOptions}
        />
        {/* header */}
        <div className="admin-order-header">
          <div className="flex-cell left-align-col vertical-padded-cell horizontal-padded-cell underline">
            <p className="large-text purple">Order Date</p>
          </div>
          <div className="flex-cell center-align-col vertical-padded-cell underline">
            <p className="large-text purple">Order #</p>
          </div>
          {!tablet && (
            <div className="flex-cell center-align-col vertical-padded-cell underline">
              <p className="large-text purple">Tracking #</p>
            </div>
          )}
          <div className="flex-cell left-align-col vertical-padded-cell horizontal-padded-cell underline">
            <p className="large-text purple">Customer</p>
          </div>
          {!tablet && (
            <div className="flex-cell left-align-col vertical-padded-cell horizontal-padded-cell underline">
              <p className="large-text purple">Receiver(s)</p>
            </div>
          )}
          {!tablet && (
            <div className="flex-cell center-align-col vertical-padded-cell underline">
              <p className="large-text purple"># of BrixPix</p>
            </div>
          )}
          {!tablet && (
            <div className="flex-cell left-align-col vertical-padded-cell horizontal-padded-cell underline">
              <p className="large-text purple">Price</p>
            </div>
          )}
          {!tablet && (
            <div className="flex-cell left-align-col vertical-padded-cell horizontal-padded-cell underline">
              <p className="large-text purple">Shipping Price</p>
            </div>
          )}
          <div className="flex-cell center-align-col vertical-padded-cell underline">
            <p className="large-text purple">Completed</p>
          </div>
        </div>
        {/* main content */}
        <div className="admin-order-table custom-scroll">{currComponents}</div>
      </div>
    </div>
  );
};
