import React, { useState, useEffect } from 'react';

import './FadingImage.css';

/*
 * FadingImage
 * Fades in and out of various images
 */

export type Props = {
  images: string[];
  size: 'extra-small' | 'small' | 'medium' | 'large';
  interval?: number; // how often pics should rotate in milliseconds
  rotation?: number; // 0-360 rotation clockwise (defaults to 0)
  initial?: number; // 0-(size - 1) of images arr to start with (defaults to 0)
};

export const FadingImage = ({
  images,
  interval = 10000,
  size,
  rotation = 0,
  initial = 0,
}: Props) => {
  const [image, setImage] = useState<number | null>(null); // index of currently displayed image

  useEffect(() => {
    setImage(initial);
  }, [initial]);

  /* makes images rotate on a timer */
  useEffect(() => {
    /*
     * rotateImages
     * fades one image out and the next one in
     * no parameters
     */
    const rotateImages = () => {
      const maxImage = images ? images.length - 1 : 0;

      if (image === maxImage) {
        setImage(0);
      } else {
        setImage(image ?? 0 + 1);
      }
    };

    const timer = setTimeout(rotateImages, interval); // on specified interval

    return () => clearTimeout(timer);
  }, [image, images, interval]);

  return (
    <div className={`fade-image-container ${size}-fade-image-container`}>
      {images &&
        images.map((pic, index) => (
          <img
            src={pic}
            className={`fade-image 
                        ${size}-fade-image 
                        ${image === index ? 'visible' : 'invisible'}`}
            style={{ transform: `rotate(${rotation}deg)` }}
            alt="rotating"
            key={index}
          />
        ))}
    </div>
  );
};
