import {
  colorfulWoman,
  colorfulWomanPixelated,
  oldMan,
  oldManPixelated,
  greenHair,
  greenHairPixelated,
  couple,
  couplePixelated,
  smirkingMan,
  smirkingManPixelated,
  smilingWoman,
  smilingWomanPixelated,
  brixPieces,
} from '../../../media';

export { brixPieces };
export const images = [
  {
    original: colorfulWoman,
    pixelated: colorfulWomanPixelated,
  },
  {
    original: oldMan,
    pixelated: oldManPixelated,
  },
  {
    original: greenHair,
    pixelated: greenHairPixelated,
  },
  {
    original: couple,
    pixelated: couplePixelated,
  },
  {
    original: smirkingMan,
    pixelated: smirkingManPixelated,
  },
  {
    original: smilingWoman,
    pixelated: smilingWomanPixelated,
  },
];
