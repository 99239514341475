export const capitalizeFirstChar = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getSidewaysDisplacement = (text: string, extraShift?: boolean) => {
  const spaces = (text.match(/ /g) || []).length;
  const thinCharacters =
    (text.match(/i/g) || []).length +
    (text.match(/l/g) || []).length +
    (text.match(/I/g) || []).length +
    (text.match(/j/g) || []).length;
  const fatCharacters =
    (text.match(/m/g) || []).length +
    (text.match(/w/g) || []).length +
    (text.match(/M/g) || []).length +
    (text.match(/W/g) || []).length +
    (text.match(/G/g) || []).length;
  const characters = text.length;
  const adjustedCharacters =
    characters - thinCharacters / 2 + fatCharacters / 3;

  let displacement: number;
  if (characters === 0) {
    displacement = 0;
  } else if (spaces === 0) {
    if (adjustedCharacters <= 5) {
      displacement = 1.4 - (adjustedCharacters - 1) * 0.4;
    } else {
      displacement = 1.6 - (adjustedCharacters - 1) * 0.35;
    }
  } else if (spaces === 1) {
    if (adjustedCharacters <= 13) {
      displacement = 2.4 - (adjustedCharacters - 1) * 0.34;
    } else {
      displacement = 2.9 - (adjustedCharacters - 1) * 0.32;
    }
  } else if (spaces === 2) {
    if (adjustedCharacters <= 16) {
      displacement = 2.0 - (adjustedCharacters - 1) * 0.32;
    } else {
      displacement = 2.2 - (adjustedCharacters - 1) * 0.31;
    }
  } else {
    if (adjustedCharacters <= 16) {
      displacement = 2.0 - (adjustedCharacters - 1) * 0.3;
    } else {
      displacement = 2.6 - (adjustedCharacters - 1) * 0.31;
    }
  }

  if (extraShift) {
    displacement -= 0.5;
  }

  return `${displacement}`;
};

export const isLast = (arr: Array<any>, index: number) => {
  return index === arr.length - 1;
};
