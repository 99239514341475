import React, { useEffect } from 'react';

import { setCurrentBlade } from '../../../local-storage';
import { MobileBladePage } from '../../../components';

import { GalleryPage } from './GalleryPage';

export const GalleryMobile = () => {
  useEffect(() => {
    setCurrentBlade('gallery');
  }, []);

  return <MobileBladePage bladeLabel="Gallery" component={GalleryPage} />;
};
