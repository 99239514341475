import React, { useState, useEffect } from 'react';

import { SizeCode } from '../../../common/types';

import './RoomTester.css';

/*
 * RoomTester
 * Displays an image in a room to test how it looks
 */

export type Props = {
  room: string;
  source?: string;
  size: SizeCode;
  wide?: boolean;
  small?: boolean;
};

export const RoomTester = ({ room, source, size, wide, small }: Props) => {
  const [width, setWidth] = useState<number>(0); // width of image
  const [height, setHeight] = useState<number>(0); // height of image
  const [imageSize, setImageSize] = useState<number>(3); // 0 mobile, 1 small tablet, 2 tablet, 3 laptop

  /* initializes width and height to props size */
  useEffect(() => {
    switch (size) {
      case 'SS':
        setWidth(2);
        setHeight(2);
        break;
      case 'SM':
        setWidth(3);
        setHeight(3);
        break;
      case 'SL':
        setWidth(4);
        setHeight(4);
        break;
      case 'PS':
        setWidth(2);
        setHeight(3);
        break;
      case 'PM':
        setWidth(2);
        setHeight(4);
        break;
      case 'PL':
        setWidth(3);
        setHeight(4);
        break;
      case 'LS':
        setWidth(3);
        setHeight(2);
        break;
      case 'LM':
        setWidth(4);
        setHeight(2);
        break;
      case 'LL':
        setWidth(4);
        setHeight(3);
        break;
      default:
        break;
    }
  }, [size]);

  // tracks size of screen dynamically
  const isMobile = () => window.matchMedia('(max-width: 500px)').matches;
  const isSmallTablet = () => window.matchMedia('(max-width: 800px)').matches;
  const isTablet = () => window.matchMedia('(max-width: 1000px)').matches;

  const resizeImage = () => {
    if (isMobile()) {
      setImageSize(1);
    } else if (isSmallTablet()) {
      setImageSize(5);
    } else if (isTablet()) {
      setImageSize(8);
    } else {
      setImageSize(18);
    }
  };

  /* initializes size of image on mount */
  useEffect(() => {
    resizeImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* checks for screen changes and resizes image appropriately */
  useEffect(() => {
    window.addEventListener('resize', resizeImage);
    return () => window.removeEventListener('resize', resizeImage);
  });

  // determines sizing and spacing of image in space
  let sizeMult, spacingBottom;
  if (wide) {
    switch (imageSize) {
      case 0:
        sizeMult = 12;
        spacingBottom = 160;
        break;
      case 1:
        sizeMult = 16;
        spacingBottom = 80;
        break;
      case 2:
        sizeMult = 18;
        spacingBottom = 60;
        break;
      case 3:
        sizeMult = 50;
        spacingBottom = 75;
        break;
      default:
        sizeMult = 50;
        spacingBottom = 75;
        break;
    }
  } else if (small) {
    sizeMult = 14;
    spacingBottom = 70;
  } else {
    sizeMult = 20;
    spacingBottom = 70;
  }

  // determines vertical positioning
  const spacingStyle = { bottom: `${spacingBottom - height * 5}%` };

  return (
    <div className="room-tester">
      {/* room */}
      <img
        src={room}
        className={`
                    test-room 
                    ${wide ? 'wide-room' : ''} 
                    ${small ? 'small-room' : ''}
                `}
        alt="room background"
      />
      {/* image */}
      {source && (
        <img
          src={source}
          className="test-image"
          height={height * sizeMult}
          width={width * sizeMult}
          style={spacingStyle}
          alt="test pic"
        />
      )}
    </div>
  );
};
