import React from 'react';

import { fetchImage, drawImage, adjustFilter } from '../../../../helpers';
import { Pixelater } from '../../../../pixelation';

import { ItemInfo } from '../../../../types';

/*
 * ImageProcessor
 * uploads and pixelates original image and gathers its data
 * does NOT convert to brix representation
 */

export type Props = {
  url: string;
  brightness: number;
  contrast: number;
  saturation: number;
  pxWidth: number;
  pxHeight: number;
  width: number;
  height: number;
  finish: (
    info: Pick<ItemInfo, 'colorCounts' | 'colorMap' | 'imageData'>
  ) => void;
  redrawImage: () => void;
};

export class ImageProcessor extends React.Component<Props, {}> {
  /*
   * getImage
   * Retrieves original image from the corresponding HTML element
   * Converts image to base64 data readable by a canvas
   * Calls this.getPixels() to pixelate the original image once ready
   * triggered via ref by parent
   */
  getImage = async () => {
    const canvas = document.getElementById(
      'hidden-canvas'
    )! as HTMLCanvasElement;
    const image = document.getElementById(
      'original-image'
    )! as HTMLImageElement;

    await fetchImage(image, this.props.url, async () => {
      drawImage(
        canvas,
        image,
        this.props.pxWidth,
        this.props.pxHeight,
        this.props.brightness,
        this.props.contrast,
        this.props.saturation
      );
      await this.getPixels();
    });
    return;
  };

  /*
   * touchUpImage
   * calls adjustFilter on the hidden-canvas
   * need to make sure that hidden-canvas changes are saved to smaller-canvas
   
  touchUpOptions = () => {
    const canvas = document.getElementById(
      'smaller-canvas'
    )! as HTMLCanvasElement;
    adjustFilter(
      canvas,
      this.props.brightness,
      this.props.contrast,
      this.props.saturation
    );
    const canvas2 = document.getElementById(
      'hidden-canvas'
    )! as HTMLCanvasElement;
    adjustFilter(
      canvas2,
      this.props.brightness,
      this.props.contrast,
      this.props.saturation
    );
    this.getPixels();
  };

  */

  /*
   * getPixels
   * Retrieves pixels from original image, pixelates, and renders pixelated image
   * Called after original image has been selected and loaded
   */
  getPixels = async () => {
    const canvas = document.getElementById(
      'hidden-canvas'
    )! as HTMLCanvasElement;
    const context = canvas.getContext('2d')!;

    // gets pixel data from original image
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const horBlocks = this.props.width / 10; //originally 10!!!!
    const vertBlocks = this.props.height / 10; //originally 10!!!! 
    //reducing the divisor increases the number of blocks in the resulting images

    // create pixelater image and pixelate original image
    const pixelater = new Pixelater(imageData, horBlocks, vertBlocks);
    const newImageData = pixelater.pixelate(); //new image data comes from pixelation

    // checks for success, won't render image if failed to pixelate
    if (newImageData === null) {
      // TODO: display error for not large enough to users
      return;
    }
    // retrieve array of counts of colors to print
    const colorCounts = pixelater.getColorCounts();

    // holds list of all color/icon indices in image
    const colorMap = pixelater.getColorMap();

    this.updateCanvases(newImageData);
    this.props.finish({
      colorCounts,
      colorMap,
      imageData: newImageData,
    });
  };

  updateCanvases = (imageData: ImageData) => {
    const canvas = document.getElementById(
      'hidden-canvas' //originally hidden-canvas
    )! as HTMLCanvasElement;
    const context = canvas.getContext('2d')!;

    canvas.width = imageData.width;
    canvas.height = imageData.height;

    //multiplying both by 2 cuts size by 4
    //dividing both by 2 enlargens size by 4, but image takes up same space

    context.putImageData(imageData, 0, 0);
    this.props.redrawImage();
  };

  render() {
    const imageStyles = {
      filter: `brightness(${this.props.brightness}%) 
               contrast(${this.props.contrast}%) 
               saturate(${this.props.saturation}%)`,
    };

    return (
      <>
        <img
          id="original-image"
          src={this.props.url}
          className="offscreen"
          alt="Original"
          style={imageStyles}
        />
        <canvas
          id="hidden-canvas"
          className="offscreen"
          width={this.props.pxWidth}
          height={this.props.pxHeight}
        />
      </>
    );
  }
}
