export const cloudinaryStyles = {
  palette: {
    window: '#FFFFFF',
    sourceBg: '#F5F5F5',
    windowBorder: '#90a0b3',
    tabIcon: '#5F4158',
    inactiveTabIcon: '#69778A',
    menuIcons: '#5F4158',
    link: '#6D8CC3',
    action: '#5F4158',
    inProgress: '#C296A8',
    complete: '#39D4BF',
    error: '#DE0235',
    textDark: '#555555',
    textLight: '#FFFFFF',
  },
  fonts: {
    default: null,
    "'Quicksand', sans-serif": {
      url: 'https://fonts.googleapis.com/css2?family=Quicksand:wght@300',
      active: true,
    },
  },
};
