import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { Coupon } from '../../../../../common/types';
import { createPaymentIntent, updatePaymentIntent } from '../../../api';
import stripeStyle from '../../../styles/Stripe';
import { CartItem } from '../../../types';

export type Props = {
  ready: boolean;
  focused: boolean;
  items: CartItem[];
  shippingIndex: number | null;
  zip: string;
  username: string | null;
  orderNum: string;
  finishCheckout: () => void;
  coupon: Coupon | null;
};

export const CheckoutForm = ({
  ready,
  focused,
  items,
  shippingIndex,
  zip,
  username,
  orderNum,
  finishCheckout,
  coupon,
}: Props) => {
  const [succeeded, setSucceeded] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [clientSecret, setClientSecret] = useState<string>('');
  const [stripeId, setStripeId] = useState<string>('');
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (ready && focused && shippingIndex != null) {
      const info = {
        items: items,
        shippingIndex: shippingIndex,
        zip: zip,
        user: username,
        orderNumber: orderNum,
        coupon: coupon,
      };

      if (clientSecret) {
        updatePaymentIntent({ ...info, id: stripeId });
      } else {
        createPaymentIntent(info).then((res: any) => {
          setClientSecret(res.data.clientSecret);
          setStripeId(res.data.id);
        });
      }
    }
  }, [
    ready,
    focused,
    clientSecret,
    coupon,
    items,
    orderNum,
    shippingIndex,
    stripeId,
    username,
    zip,
  ]);

  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async (ev: React.ChangeEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe!.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements!.getElement(CardElement)!,
      },
    });
    if (payload.error) {
      setError(`Payment failed: ${payload.error.message}`);
      setProcessing(false);
    } else {
      finishCheckout();
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  return (
    <div className="payment-form-container">
      <form id="payment-form" className="payment-form" onSubmit={handleSubmit}>
        <CardElement
          id="card-element"
          className="card-element"
          options={stripeStyle}
          onChange={handleChange}
        />
        {!succeeded && (
          <button
            disabled={processing || disabled || succeeded}
            id="submit"
            className="stripe-button"
          >
            <span id="button-text">
              {processing ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                'Pay and Complete Order'
              )}
            </span>
          </button>
        )}
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}
        {/* Show a success message upon completion */}
        <p className={succeeded ? 'result-message' : 'result-message hidden'}>
          Payment succeeded!
        </p>
      </form>
    </div>
  );
};
