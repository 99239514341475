import React from 'react';

import { MobileBladePage } from '../../components';

import { Orders } from './Orders';

const OrdersMobile = () => {
  return <MobileBladePage bladeLabel="Orders" component={Orders} />;
};

export default OrdersMobile;
