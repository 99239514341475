var stripeStyle = {
    style: {
        base: {
            color: "#6D8CC3",
            fontFamily: "Avenir Light, sans-serif",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": { color: "#555" }
        },
        invalid: {
            fontFamily: "Avenir Light, sans-serif",
            color: "#DE0235",
            iconColor: "#DE0235"
        }
    }
};

module.exports = stripeStyle;