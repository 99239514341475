import { creditCard as credit, addCart as cart, settings } from '../../media';

export const accountInfo = [
  {
    text: 'View My Order History',
    image: credit,
  },
  {
    text: 'Reorder',
    image: cart,
  },
  {
    text: 'Edit My Account',
    image: settings,
  },
];
