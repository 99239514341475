import React, { useState, useEffect } from 'react';

import { Order } from '../../../../common/types';
import { getOrderNum, parsePrettyDate } from '../../helpers';
import { DateHydratedOrder } from '../../types';

import { NoOrders, OrderHistoryTable } from './components';
import './OrderHistory.css';

/*
 * OrderHistory
 * Displays entire order history for current user
 */

export type Props = {
  orders: Order[] | null;
  view: (index: number) => void;
};

export const OrderHistory = ({ orders, view }: Props) => {
  // all orders with no structure in reverse chronological order arr
  const [allOrders, setAllOrders] = useState<DateHydratedOrder[]>([]);

  /* initialize allOrders based on props */
  useEffect(() => {
    let tempOrders: DateHydratedOrder[] = [];
    let prettyDate;
    if (Array.isArray(orders) && allOrders.length === 0) {
      for (var order of orders) {
        prettyDate = parsePrettyDate(order.date);
        let currOrder: DateHydratedOrder = {};

        // initialize and format date
        currOrder.date = prettyDate.date;
        currOrder.month = prettyDate.month;
        currOrder.year = prettyDate.year;

        currOrder.orderNum = getOrderNum(order);
        currOrder.suborders = order.suborders;

        tempOrders.push(currOrder);
      }
    }
    setAllOrders(tempOrders.slice());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  // checks if user has ordered anything before
  const ordersExist = orders && Array.isArray(orders) && allOrders.length;

  return (
    <div className="account-subpage order-history">
      <h3 className="purple account-header">Order History</h3>
      <br />
      {ordersExist ? (
        <OrderHistoryTable view={view} orders={allOrders} />
      ) : (
        <NoOrders />
      )}
    </div>
  );
};
