import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { verifyUserToken } from '../api';
import { User } from '../../../common/types';
import { getCurrentUser } from '../local-storage';

export type Props = {
  component: React.ElementType;
  path?: string;
  exact?: boolean;
};

export const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const [user, setUser] = useState<User | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const token = getCurrentUser();
    verifyUserToken({ token: token })
      .then((res: any) => {
        res.data.user && setUser(res.data.user._id);
        setLoaded(true);
      })
      .catch((err: any) => console.log(err));
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        loaded ? (
          user ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        ) : (
          <></>
        )
      }
    />
  );
};
