import React, { useEffect } from 'react';

import { MobileBladePage } from '../../../components';
import { setCurrentBlade } from '../../../local-storage';

import { PricingPage } from './PricingPage';

export const PricingMobile = () => {
  useEffect(() => {
    setCurrentBlade('pricing');
  }, []);

  return <MobileBladePage bladeLabel="Pricing" component={PricingPage} />;
};
