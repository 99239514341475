import React from 'react';

/*
 * PastOrder
 * Displays single past order and its info
 */

export type Props = {
  date: number;
  month: string;
  year: number;
  orderNum: string;
  clickable?: boolean;
  click?: () => void;
  image?: string;
};

export const PastOrder = ({
  date,
  month,
  year,
  orderNum,
  clickable,
  click,
  image,
}: Props) => {
  return (
    <>
      {image && (
        <div className="flex-cell center-align-col padded-cell order-thumbnail-cell">
          <img src={image} className="order-thumbnail" alt="order thumbnail" />
        </div>
      )}
      <div className="flex-cell horizontal-padded-cell vertical-centered-cell">
        <p
          onClick={clickable ? click : undefined}
          className={`blue large-text vertical-padded-cell ${
            clickable ? 'clickable' : ''
          } order-history-text order-detail-text`}
        >
          {date} {month}, {year}
        </p>
      </div>
      <div className="flex-cell horizontal-padded-cell vertical-centered-cell center-align-col">
        <p
          onClick={clickable ? click : undefined}
          className={`blue large-text vertical-padded-cell ${
            clickable ? 'clickable' : ''
          } order-history-text order-detail-text`}
        >
          {orderNum}
        </p>
      </div>
    </>
  );
};
