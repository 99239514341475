import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import {
  login,
  checkResetLink,
  editCoreUser,
  validatePasswords,
} from '../../api';
import { Blade, ClassicButton, TextInput } from '../../components';
import { changeField } from '../../helpers';
import { eye as eyeIcon } from '../../media';

import './Password.css';

export type Props = {
  match: {
    params: {
      token: string;
    };
  };
};

export const ResetPassword = (props: Props) => {
  const [user, setUser] = useState<string>('');

  const [password, setPassword] = useState<string>('');
  const [confirmation, setConfirmation] = useState<string>('');

  const [passwordMessage, setPasswordMessage] = useState<string>('');
  const [confirmationMessage, setConfirmationMessage] = useState<string>('');

  // tracks visibility of password field
  const [showPass, setShowPass] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const [redirect, setRedirect] = useState<string | null>(null);

  useEffect(() => {
    checkResetLink(props.match.params.token).then((res: any) => {
      res.data.id ? setUser(res.data.id) : setRedirect('/reset-error');
    });
  }, [props.match.params.token]);

  /*
   * checkForm
   * validates form inputs when user submits
   * displays error messages if errors
   * otherwise resets password and logs user in
   */
  const checkForm = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    let problem;
    const res = await validatePasswords({
      password: password,
      confirmation: confirmation,
      checkStrength: true,
    });

    if (res) {
      problem = res.data.problem;
      setPasswordMessage(res.data.message[0]);
      setConfirmationMessage(res.data.message[1]);
    } else {
      problem = true;
    }

    if (!problem) {
      await editCoreUser(user, {
        password: password,
      }); // overrides page reload
      login(user, password);
    }
  };

  return (
    <div className="password page">
      {redirect && <Redirect to={redirect} />}
      <Blade
        index={0}
        frozen
        position="left"
        value="Reset Password"
        color="blue"
      />
      <div className="password-container pixelated-background">
        <div className="password-form-container">
          <h1 className="password-title">Reset Password</h1>
          <p className="purple password-subtitle">
            Enter your new password, and you'll be Brixelating in no time!
          </p>
          <form className="password-form" onSubmit={checkForm}>
            <div className="input-line">
              <TextInput
                name="Password"
                value={password}
                change={changeField}
                setter={setPassword}
                messageSetter={setPasswordMessage}
                password={!showPass}
                message={passwordMessage}
              />
              <div className="input-icon-container">
                <img
                  className="input-icon clickable"
                  src={eyeIcon}
                  onClick={() => setShowPass(!showPass)}
                  alt="eye"
                />
              </div>
            </div>
            <div className="input-line">
              <TextInput
                name="Confirm Password"
                value={confirmation}
                change={changeField}
                setter={setConfirmation}
                messageSetter={setConfirmationMessage}
                password={!showConfirmation}
                message={confirmationMessage}
              />
              <div className="input-icon-container">
                <img
                  className="input-icon clickable"
                  src={eyeIcon}
                  onClick={() => setShowConfirmation(!showConfirmation)}
                  alt="eye"
                />
              </div>
            </div>
            <div className="password-button-container">
              <ClassicButton value="Reset Password" color="blue" submit />
            </div>
          </form>
          <div className="password-padded">
            <p className="purple password-text">
              Remembered your password?{' '}
              <b>
                <a href="/login">Go back to login.</a>
              </b>
            </p>
            <p className="purple small password-text">
              Remember, we will never share or sell your information. At BrixPix
              we value privacy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
