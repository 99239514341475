import React, { useState, useEffect } from 'react';

import { validateName, validatePhone, validatePasswords } from '../../api';
import { ClassicButton, TextInput } from '../../components';
import {
  envelope as envelopeIcon,
  eye as eyeIcon,
  phone as phoneIcon,
} from '../../media';
import {
  changeField,
  punctuatePhone,
  unpunctuatePhone,
  removePhonePunctuation,
} from '../../helpers';
import { User } from '../../../../common/types';
import { editCoreUser } from '../../api';

import './EditAccount.css';

/*
 * EditAccount
 * Page for editing and updating account info
 */

export type Props = {
  user: User | null;
};

export const EditAccount = ({ user }: Props) => {
  // hold state of form fields
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [oldPassword, setOldPassword] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  // hold error message (if applicable) for form fields
  const [firstNameMessage, setFirstNameMessage] = useState<string>('');
  const [lastNameMessage, setLastNameMessage] = useState<string>('');
  const [emailMessage, setEmailMessage] = useState<string>('');
  const [phoneMessage, setPhoneMessage] = useState<string>('');
  const [oldPasswordMessage, setOldPasswordMessage] = useState<string>('');
  const [passwordMessage, setPasswordMessage] = useState<string>('');
  const [confirmPasswordMessage, setConfirmPasswordMessage] =
    useState<string>('');

  // tracks if phone number currently punctated/needs to be punctuated
  const [punctuated, setPunctuated] = useState<boolean>(false);

  // tracks visibility of password fields
  const [showOldPass, setShowOldPass] = useState<boolean>(false);
  const [showPass, setShowPass] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  // tracks if there's a problem with submitted form fields
  const [problem, setProblem] = useState<boolean>(false);
  const [isCheckingNewPasswords, setIfCheckingNewPasswords] = useState<boolean>(false)

  // punctuates phone every time it's updated
  useEffect(() => {
    if (!punctuated && phone.length >= 4) {
      setPunctuated(true);
      setPhone(punctuatePhone(phone));
    }
  }, [phone, punctuated]);

  useEffect(() => {
    console.log("the problem be" + problem);
    if ((problem === false) && (isCheckingNewPasswords === true)) {
      editCoreUser(user?.email ?? '', {
        firstName: firstName,
        lastName: lastName,
        phone: parseInt(phone),
        password: password,
      });
    }

  }, [isCheckingNewPasswords, problem]);


  // updates phone punctuation and formatting based on user typing
  const typePhone = (value: string) => {
    if (value.length <= 14) {
      setPunctuated(false);

      let tempPhone = unpunctuatePhone(phone, value);
      if (tempPhone !== null) {
        setPhone(tempPhone);
      }
    }
  };

  async function setProblemFunc() {
    await setProblem(false);
    console.log(problem);

  }

  const checkForm: React.MouseEventHandler<HTMLDivElement> = async (e) => {
    console.log("Our problem: " + problem);
    console.log("checking form!");
    e.preventDefault();



    setProblemFunc().then(() => {
      if (!problem) {
        checkFirstName();
      }

    });
    /*
    if (problem) {
      setProblem(false); // initialize problem to false (again)
      //literally have to not call checkFirstName to solve the issue??
      //document.getElementById("orangeSoda")?.click(); //this does work, but not really
    }
    else {
      checkFirstName(); // begin validation process
      console.log("form checked!");
    }

    */
    //for some reason, thing recognizes an error as being there on 2nd submission, but lets you do it on 3rd submission


  };

  const checkFirstName = async () => {
    setProblem(false);
    // moves on if blank
    if (firstName !== '') {
      await validateName({ name: firstName, type: 'first' })
        .then((res: any) => {
          setProblem(problem || res.data.problem);
          setFirstNameMessage(res.data.message);

          checkLastName();
        })
        .catch((err: any) => console.log(err));
    } else {
      checkLastName();
    }
  };

  const checkLastName = async () => {
    // moves on if blank
    if (lastName !== '') {
      await validateName({ name: lastName, type: 'last' })
        .then((res: any) => {
          setProblem(problem || res.data.problem);
          setLastNameMessage(res.data.message);

          checkPhone();
        })
        .catch((err: any) => console.log(err));
    } else {
      checkPhone();
    }
  };

  const checkPhone = async () => {
    // moves on if blank
    if (phone !== '') {
      await validatePhone({ phone: removePhonePunctuation(phone) })
        .then((res: any) => {
          setProblem(problem || res.data.problem);
          setPhoneMessage(res.data.message);
          checkOldPassword();
        })
        .catch((err: any) => console.log(err));
    } else {
      checkOldPassword();
    }
  };

  const checkOldPassword = async () => {
    // can't check new passwords if this is blank
    if (oldPassword !== '') {
      await validatePasswords({
        password: oldPassword,
        checkStrength: false,
        user: user?.email,
      })
        .then((res: any) => {
          setProblem(problem || res.data.problem);
          setOldPasswordMessage(res.data.message);
          checkNewPasswords();
        })
        .catch((err: any) => console.log(err));
    } else if (password !== '' || confirmPassword !== '') {
      setProblem(true);
      setOldPasswordMessage(
        'You have to enter your old password if you want to change to a new password'
      );
    } else {
      editCoreUser(user?.email ?? '', {
        firstName: firstName,
        lastName: lastName,
        phone: parseInt(phone),
      });
    }
  };

  // validates new password and its confirmation - updates user if NO PROBLEM
  const checkNewPasswords = async () => {

    await validatePasswords({ password: password, confirmation: confirmPassword })
      .then((res: any) => {
        setProblem(problem || res.data.problem);
        setIfCheckingNewPasswords(true);
        setPasswordMessage(res.data.message[0]);
        setConfirmPasswordMessage(res.data.message[1]);

        if (!problem && !res.data.problem) {
          console.log("doing this step " + problem);
          /*
          editCoreUser(user?.email ?? '', {
            firstName: firstName,
            lastName: lastName,
            phone: parseInt(phone),
            password: password,
          });
          */
        }



      })
      .catch((err: any) => console.log(err));
    setIfCheckingNewPasswords(false);
    //setProblemFunc();
    setProblem(false);


  };


  const setProblemAsync = async (isProblematic) => {
    await setProblem(isProblematic);
    console.log("problem is: " + problem);
  }

  /*
 
  const updateUserInfo = async () => {
    if (!problem) {
      editCoreUser(user?.email ?? '', {
        firstName: firstName,
        lastName: lastName,
        phone: parseInt(phone),
        password: password,
      });
    }
  }
  */

  return (
    <div className="account-subpage edit-account">
      <h3 className="purple account-header">Edit My Account</h3>
      <br />
      <p className="purple account-text">
        Please note that you may not edit your email, since that's what allows
        us to locate your account. Don't forget to enter your current password
        if you'd like to change it. You only need to edit the fields that you'd
        like to change.
      </p>
      {/* main form */}
      <div className="edit-account-form-container custom-scroll">
        <form className="edit-account-form" >
          <div className="input-line multi-field">
            <TextInput
              name="First Name"
              value={firstName}
              change={changeField}
              setter={setFirstName}
              messageSetter={setFirstNameMessage}
              message={firstNameMessage}
              placeholder={user?.firstName}
            />
            <TextInput
              name="Last Name"
              value={lastName}
              change={changeField}
              setter={setLastName}
              messageSetter={setLastNameMessage}
              message={lastNameMessage}
              placeholder={user?.lastName}
            />
          </div>
          <div className="input-line">
            <TextInput
              name="Email"
              value={email}
              change={changeField}
              setter={setEmail}
              messageSetter={setEmailMessage}
              message={emailMessage}
              placeholder={user?.email}
              readOnly
            />
            <div className="input-icon-container">
              <img className="input-icon" src={envelopeIcon} alt="envelope" />
            </div>
          </div>
          <div className="input-line">
            <TextInput
              name="Phone"
              value={phone}
              change={changeField}
              setter={typePhone}
              messageSetter={setPhoneMessage}
              message={phoneMessage}
              placeholder={punctuatePhone('' + user?.phone)}
            />
            <div className="input-icon-container">
              <img className="input-icon" src={phoneIcon} alt="phone" />
            </div>
          </div>
          <div className="input-line">
            <TextInput
              name="Current Password"
              value={oldPassword}
              change={changeField}
              setter={setOldPassword}
              messageSetter={setOldPasswordMessage}
              password={!showOldPass}
              message={oldPasswordMessage}
              placeholder="********"
            />
            <div className="input-icon-container">
              <img
                className="input-icon clickable"
                src={eyeIcon}
                onClick={() => setShowOldPass(!showOldPass)}
                alt="eye"
              />
            </div>
          </div>
          <div className="input-line">
            <TextInput
              name="New Password"
              value={password}
              change={changeField}
              setter={setPassword}
              messageSetter={setPasswordMessage}
              password={!showPass}
              message={passwordMessage}
            />
            <div className="input-icon-container">
              <img
                className="input-icon clickable"
                src={eyeIcon}
                onClick={() => setShowPass(!showPass)}
                alt="eye"
              />
            </div>
          </div>
          <p className="form-note small blue">
            Your password must be at least 8 characters long and include at
            least one letter, number, and special character.
          </p>
          <div className="input-line">
            <TextInput
              name="Confirm New Password"
              value={confirmPassword}
              change={changeField}
              setter={setConfirmPassword}
              messageSetter={setConfirmPasswordMessage}
              password={!showConfirm}
              message={confirmPasswordMessage}
            />
            <div className="input-icon-container">
              <img
                className="input-icon clickable"
                src={eyeIcon}
                onClick={() => setShowConfirm(!showConfirm)}
                alt="eye"
              />
            </div>
          </div>
        </form>
      </div>
      <div className="edit-account-button-area">
        <div className="edit-account-button-container">
          <ClassicButton action={checkForm} value="Save Changes" color="blue" />

        </div>
      </div>
    </div>
  );
};
