export const blueMosaicGradient =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1606611864/brixpix-assets/backgrounds/blue-mosaic-gradient_eogcw1.png';
export const nookPixels =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1608657973/brixpix-assets/backgrounds/HomePage_-_WebBackGrounds_mgepn7.jpg';
export const tablePixels =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1608657974/brixpix-assets/backgrounds/What_is_Brix_-_WebBackGrounds_ggdiup.jpg';
export const livingRoomPixels =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1608657973/brixpix-assets/backgrounds/Start_Order_Page_-_WebBackGrounds_rnnobw.jpg';
export const familyRoomPixels =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1608670753/brixpix-assets/backgrounds/Pricing_Page_-_WebBackGrounds_tz9tco.jpg';

export const livingRoomGradient =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1606930580/brixpix-assets/backgrounds/Weblayout-PricingPage_gqpruk.png';
export const entryHall =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1606864790/brixpix-assets/backgrounds/Entryway_bebg7w.png';
export const familyRoom =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1606864790/brixpix-assets/backgrounds/FamilyRoom_l1u4xp.png';
export const livingRoom =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1606934057/brixpix-assets/backgrounds/Living_Room-01_gjrej9.png';
export const readingCorner =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1606933701/brixpix-assets/backgrounds/ReadingCorner-01_gy9bgo.png';
export const bedroom =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1606864790/brixpix-assets/backgrounds/Bedroom_tq7ivs.png';
export const diningRoomBlue =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1606931744/brixpix-assets/backgrounds/DinningRoom_bzetfz.png';
