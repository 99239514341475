import React, { useState, useEffect } from 'react';

import {
  isTablet,
  isMobile,
  shapeOptions,
  getDescription,
} from '../../../helpers';
import { ShapeOption as ShapeOptionType } from '../../../../../common/types';
import { ItemInfo } from '../../../types';
import { ShapeOption } from './ShapeOption';
import './ShapeStep.css';

/*
 * ShapeStep
 * first step in order process, choose general shape
 */

export type Props = {
  finish: (info: Pick<ItemInfo, 'shape' | 'shapeDescription'>) => void;
};

export const ShapeStep = ({ finish }: Props) => {
  const [shape, setShape] = useState<ShapeOptionType | ''>('');
  const [tablet, setTablet] = useState<boolean>(isTablet()); // if screen size is <= tablet
  const [mobile, setMobile] = useState<boolean>(isMobile()); // if screen size is <= mobile

  /* checks for window size on mount */
  useEffect(() => {
    // updates on window resize
    const onResize = () => {
      setTablet(isTablet());
      setMobile(isMobile());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  /* allows next step to be reached with info of curr selected shape */
  useEffect(() => {
    if (shape) {
      finish({
        shape: shape,
        shapeDescription: getDescription(shape)!,
      });
    }
  }, [shape, finish]);

  return (
    <div className="shape-step">
      <h2 className="shape-header">Select BrixPix Shape</h2>
      <div className="shape-option-container">
        {shapeOptions.map((shapeOption, index) => (
          <ShapeOption
            height={parseInt(shapeOption.height)}
            width={parseInt(shapeOption.width)}
            title={shapeOption.name as ShapeOptionType}
            selected={shapeOption.name === shape}
            description={shapeOption.description}
            select={setShape}
            tablet={tablet}
            mobile={mobile}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};
