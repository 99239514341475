import React from 'react';

export const AboutBottom = () => {
  return (
    <>
      <p className="purple">It’s a simple process:</p>
      <div className="ordered-list">
        <div className="ordered-list-item">
          <div className="inline-block">
            <p className="purple">1)</p>
          </div>
          <div className="inline-block">
            <p className="purple">Select a size and shape for your puzzle.</p>
          </div>
        </div>
        <div className="ordered-list-item">
          <div className="inline-block">
            <p className="purple">2)</p>
          </div>
          <div className="inline-block">
            <p className="purple">
              Upload your picture and let our custom Brixilator work its magic!
            </p>
          </div>
        </div>
        <div className="ordered-list-item">
          <div className="inline-block">
            <p className="purple">3)</p>
          </div>
          <div className="inline-block">
            <p className="purple inline">
              Using our Brixilator, make desired adjustments to your photo.
            </p>
          </div>
        </div>
        <div className="ordered-list-item">
          <div className="inline-block">
            <p className="purple">4)</p>
          </div>
          <div className="inline-block">
            <p className="purple inline">Review and submit.</p>
          </div>
        </div>
      </div>
      <p className="purple">
        The rest is up to us! Once your order has been submitted, your blueprint
        and pieces required to construct your one-of-a-kind BrixPix will be
        shipped, elegantly packaged and ready to assemble.
      </p>
      <h4 className="blue about-end">
        Relive the memories you cherish while building new ones, piece-by-piece.
      </h4>
    </>
  );
};
