import React from 'react';
import ReactSlider from 'react-slider';

import { SliderLabel } from '../../../../components';
import { capitalizeFirstChar } from '../../../../helpers';
import { resetArrow as reset } from '../../../../media';

/*
 * CustomizationOptions 
 * sliders and reset options to customize an image
 */

export type FilterOption = 'brightness' | 'contrast' | 'saturation';

export type Props = {
  options: {
    brightness: number;
    contrast: number;
    saturation: number;
  };
  updateOption: (key: FilterOption, value: number) => void;
  process: () => void; //comment out all process mentions to revert back to last ver
  revert: () => void;
};

export const CustomizationOptions = ({
  options,
  updateOption,
  process,
  revert,
}: Props) => {
  return (
    <div className="sliders-container">
      <div className="sliders">
        {Object.keys(options).map((key, index) => (
          <div className="slider-inner-container" key={index}>
            <SliderLabel value={capitalizeFirstChar(key)} />
            <ReactSlider
              className="slider"
              trackClassName="slider-track"
              thumbClassName="slider-thumb"
              defaultValue={100}
              value={options[key as FilterOption]}
              renderThumb={(props, state) => (
                <div {...props}>{state.value}</div>
              )}
              minDistance={10}
              onChange={(value) => {

                updateOption(key as FilterOption, value);
                process();
              }}
              min={0}
              max={200}
            />
          </div>
        ))}
        <div onClick={revert} className="revert-button revert-area">
          <img src={reset} className="revert-icon" alt="reset arrow" />
          <p className="purple">Reset</p>
        </div>
      </div>
    </div>
  );
};
