import React from 'react';

/*
 * SidewaysButton
 * Button that faces left or right, primarily for "next" and "previous" type actions
 */

export type Props = {
  value: string;
  action: () => void;
  color: string;
  left?: boolean;
  right?: boolean;
  disabled?: boolean;
  small?: boolean;
};

export const SidewaysButton = ({
  value,
  action,
  color,
  left,
  right,
  disabled,
  small,
}: Props) => {
  return (
    <div className="sideways-button-container">
      <div className="sideways-button" onClick={disabled ? undefined : action}>
        {/* optional left dot */}
        {left && (
          <div
            className={`sideways-button-dot ${
              disabled ? 'disabled' : color + '-background'
            } ${small ? 'small' : ''}`}
          />
        )}
        {/* main body */}
        <div
          className={`${
            disabled ? 'disabled' : color + '-background'
          } sideways-button-body ${small ? 'small' : ''}`}
        >
          <p>{value}</p>
        </div>
        {/* optional right dot */}
        {right && (
          <div
            className={`sideways-button-dot ${
              disabled ? 'disabled' : color + '-background'
            } ${small ? 'small' : ''}`}
          />
        )}
      </div>
    </div>
  );
};
