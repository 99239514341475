import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { verifyUserToken } from '../api';
import { User } from '../../../common/types';
import { getCurrentUser } from '../local-storage';

export type Props = {
  component: React.ElementType;
  restricted?: boolean;
  path?: string;
  exact?: boolean;
};

export const PublicRoute = ({
  component: Component,
  restricted,
  ...rest
}: Props) => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const token = getCurrentUser();
    verifyUserToken({ token: token })
      .then((res: any) => {
        res.data.user && setUser(res.data.user._id);
      })
      .catch((err: any) => console.log(err));
  }, []);

  return (
    // restricted = false -> public
    // restricted = true -> restricted, NOT for logged in
    <Route
      {...rest}
      render={(props) =>
        user && restricted ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
};
