import React, { useState, useEffect } from 'react';

import {
  arrowLeftTranslucent as leftArrowTranslucent,
  arrowLeftSolid as leftArrowSolid,
  arrowRightTranslucent as rightArrowTranslucent,
  arrowRightSolid as rightArrowSolid,
} from '../media';
import { AbbreviatedShapeOption } from '../../../common/types';

import { Thumbnail } from './Thumbnail';
import './ImageCarousel.css';

/*
 * ImageCarousel
 * Holds rotating bar of images to click through with arrows
 */

export type ImageObj = {
  src: string;
  shape: AbbreviatedShapeOption;
};
export type Props = {
  images: ImageObj[];
  select: (index: number) => void;
};

export const ImageCarousel = ({ images, select }: Props) => {
  // arr of thumbnail components
  const [thumbnails, setThumbnails] = useState<JSX.Element[] | null>(null);

  // how many times carousel rotated to right
  const [rotations, setRotations] = useState<number>(0);

  // position of carousel based on rotations
  const [carouselStyle, setCarouselStyle] = useState<React.CSSProperties>({
    left: '0',
  });

  const imageIncrements = 10; // how much to rotate by
  const totalWidth = 43; // total width of carousel, including hidden parts

  // bool if all the way at right end of carousel display
  const [toRight, setToRight] = useState<boolean>(false);

  const carouselWidth = 20; // width of carousel (based on screen size)

  /* initalize thumbnails based on props */
  useEffect(() => {
    setThumbnails(
      images.map((img: ImageObj, index: number) => (
        <Thumbnail image={img.src} key={index} action={() => select(index)} />
      ))
    );
  }, [images, carouselStyle, select]);

  /*
   * rotateCarousel
   * rotates images one way or another
   * triggered on click of arrow
   */
  const rotateCarousel = (direction: 'right' | 'left') => {
    let newStyle;
    // rotating right
    if (direction === 'right') {
      // makes sure it won't overshoot, will only rotate until last image
      if ((rotations + 1) * imageIncrements > totalWidth - carouselWidth) {
        newStyle = { left: `${-1 * (totalWidth - carouselWidth + 4)}em` };
        setCarouselStyle(newStyle);
        setRotations(rotations + 1);
        setToRight(true);
      } else {
        newStyle = { left: `${(rotations + 1) * -1 * imageIncrements}em` };
        setCarouselStyle(newStyle);
        setRotations(rotations + 1);
      }
    }
    // rotating left
    else {
      if (rotations !== 0) {
        newStyle = { left: `${(rotations - 1) * -1 * imageIncrements}em` };
        setCarouselStyle(newStyle);
        setRotations(rotations - 1);
        setToRight(false);
      }
    }
  };

  return (
    <div className="image-carousel">
      {/* left arrow */}
      <div className="carousel-arrow-container">
        <img
          src={rotations === 0 ? leftArrowTranslucent : leftArrowSolid}
          className={`carousel-left-arrow carousel-arrow ${
            rotations !== 0 ? 'clickable' : ''
          }`}
          onClick={() => (rotations !== 0 ? rotateCarousel('left') : null)}
          alt="left arrow"
        />
      </div>
      {/* images w/in carousel */}
      <div className="carousel-image-section" id="carousel">
        <div className="carousel-moving-images" style={carouselStyle}>
          {thumbnails}
        </div>
      </div>
      {/* right arrow */}
      <div className="carousel-arrow-container">
        <img
          src={toRight ? rightArrowTranslucent : rightArrowSolid}
          className={`carousel-right-arrow carousel-arrow ${
            toRight ? '' : 'clickable'
          }`}
          onClick={() => (toRight ? null : rotateCarousel('right'))}
          alt="right arrow"
        />
      </div>
    </div>
  );
};
