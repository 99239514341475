import React, { useState, useEffect } from 'react';

import {
  Size,
  SizeCode,
  SizeOption as SizeOptionType,
} from '../../../../../common/types';
import { getSizeData } from '../../../api';
import { RadioButton, RoomTester } from '../../../components';
import { isTablet, isSidewaysTablet } from '../../../helpers';
import { livingRoom } from '../../../media';
import { ItemInfo } from '../../../types';

import { sizeImages } from './sizeImages';
import './SizeStep.css';

const sizeKeys = {
  Small: 0,
  Medium: 1,
  Large: 2,
  Custom: 3, // usually not in use
};

/*
 * SizeStep
 * displays size step to select which size
 */

export type Props = {
  info: Pick<ItemInfo, 'shape' | 'shapeDescription'>;
  finish: (info: Pick<ItemInfo, 'sizeData'>) => void;
};

export const SizeStep = ({ info, finish }: Props) => {
  const [size, setSize] = useState<SizeOptionType | ''>('');
  const [units, setUnits] = useState<'inches' | 'centimeters'>('inches'); // units to display sizes in
  const [sizeOptions, setSizeOptions] = useState<Size[] | null>(null);
  const [testImage, setTestImage] = useState<string | null>(null);

  const [bigTablet, setBigTablet] = useState(isSidewaysTablet());
  const [tablet, setTablet] = useState(isTablet());

  /* checks for window size on mount */
  useEffect(() => {
    const onResize = () => {
      setBigTablet(isSidewaysTablet());
      setTablet(isTablet());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  /* on mount, initializes size options for given shape */
  useEffect(() => {
    const initializeSizes = async () => {
      const res = await getSizeData({
        keys: ['shape'],
        values: [info.shape],
      });
      setSizeOptions(res.data.data);
    };

    initializeSizes();
  }, [info.shape]);

  /* whenever size changes, choose new matching picture */
  useEffect(() => {
    setTestImage(sizeImages[sizeCode]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  /* if size has been chosen, allow moving to next step */
  useEffect(() => {
    if (size && sizeOptions != null) {
      finish({
        sizeData: sizeOptions[sizeKeys[size]],
      });
    }
  }, [size, sizeOptions, finish]);

  const sizeCode = (info.shape[0] + size[0]) as SizeCode;

  return (
    <div className="size-step">
      <h2 className="size-header">Select BrixPix Size</h2>
      <p className="purple size-subheader">
        Note: the larger the BrixPix, the better the resolution of the final
        product!
      </p>
      <div className="size-display">
        <div className="size-preview-container">
          <RoomTester
            room={livingRoom}
            source={testImage ?? undefined}
            size={sizeCode}
            small={bigTablet}
          />
          <p className="dark-grey align-center">{info.shapeDescription}</p>
        </div>
        <div className="size-option-container">
          {sizeOptions &&
            sizeOptions.map((sizeOption, index) => (
              <RadioButton
                label={`${sizeOption.size} - $${sizeOption.price}`}
                select={() => setSize(sizeOption.size)}
                selected={sizeOption.size === size}
                sublabel={`${
                  units === 'inches'
                    ? sizeOption.imperial.width + 'in'
                    : sizeOption.metric.width + 'cm'
                } x ${
                  units === 'inches'
                    ? sizeOption.imperial.height + 'in'
                    : sizeOption.metric.height + 'cm'
                }`}
                medium={tablet}
                key={index}
              />
            ))}
          <div className="unit-container">
            <p className="large-text dark-grey inline-block">Show size in: </p>
            {tablet && <br />}
            <RadioButton
              label="inches"
              select={() => setUnits('inches')}
              selected={units === 'inches'}
              inline
              small
            />
            {tablet && <br />}
            <RadioButton
              label="centimeters"
              select={() => setUnits('centimeters')}
              selected={units === 'centimeters'}
              inline
              small
            />
          </div>
        </div>
      </div>
    </div>
  );
};
