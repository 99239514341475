export const frameSquare =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1606875116/brixpix-assets/frames/S-20x-20-S_qqjaic.png';
export const framePortraitSmall =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1606875116/brixpix-assets/frames/P-20x-30-S_fx7kzl.png';
export const framePortraitMedium =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1606875116/brixpix-assets/frames/P-20x-40-M_wjem7p.png';
export const framePortraitLarge =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1606875116/brixpix-assets/frames/P-30x-40-L_dnxf6j.png';
export const frameLandscapeSmall =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1606875116/brixpix-assets/frames/L-30x-20-S_txlx15.png';
export const frameLandscapeMedium =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1606875117/brixpix-assets/frames/L-40x-20-M_sik3cu.png';
export const frameLandscapeLarge =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1606875116/brixpix-assets/frames/L-40x-30-L_thm58z.png';
