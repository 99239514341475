import React, { useState } from 'react';

import { Blade } from '../../components';

import { ContactForm } from './ContactForm';
import './Contact.css';

export const Contact = () => {
  const [sent, setSent] = useState<boolean>(false);

  return (
    <div className="contact page">
      <Blade index={0} position="left" color="blue" value="Contact Us" frozen />
      <div className="contact-content">
        <div className="contact-notes">
          <div className="contact-notes-main">
            <p className="purple">
              All BrixPit kits are assembled in and shipped from Chicago, IL.
            </p>
            <br />
            <p className="purple">
              We look forward to your comments or questions.
            </p>
          </div>
          <p className="blue small">
            We will never share or sell your information. At BrixPix we value
            privacy.
          </p>
        </div>
        <div className="contact-main">
          <h2 className="blue">Contact Us</h2>
          <br />
          <p className="purple">
            We would love to answer any questions you might have. Please email
            us directly at{' '}
            <b>
              <a href="mailto:hello@brixpix.com">hello@brixpix.com</a>
            </b>{' '}
            or complete the form below and we will get back to you promptly.
          </p>
          {sent ? (
            <div className="contact-success">
              <h4 className="purple">
                Your email has been received, and you'll hear back from us
                shortly.
              </h4>
              <br />
              <p className="purple">
                You should receive an email with a copy of this contact form
                submission within the next few minutes!
              </p>
            </div>
          ) : (
            <ContactForm finish={() => setSent(true)} />
          )}
        </div>
      </div>
    </div>
  );
};
