export const huntermoose =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1606930551/brixpix-assets/images/huntermoose-Crop_acz2xb.jpg';
export const huntermoosePixelated =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1606930559/brixpix-assets/images/huntermoose-Brix2_sbpv1p.png';

// review step
export const familyRoomSmall =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607908829/brixpix-assets/images/SampleRoomsBlue_Fam-01_ybwt6f.png';
export const diningRoomBlueSmall =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607908828/brixpix-assets/images/SampleRooms-GreenDining-01_uwgrtf.png';
export const stripeyRoomSmall =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607908830/brixpix-assets/images/SampleRooms-DarkGrey_Fam-01_gduwvb.png';
export const entryHallSmall =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607908829/brixpix-assets/images/SampleRooms-DarkEntry-01_swvwu7.png';
export const familyRoomGreySmall =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607638101/brixpix-assets/backgrounds/SampleRooms-GrayFamilyRoom-01-01_vwubli.png';
export const bedroomSmall =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607908827/brixpix-assets/images/SampleRooms-Bedroom-01_jebdb2.png';

// all large size, gallery page
export const holidayUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607911625/brixpix-assets/images/Christmas_kbph60.png';
export const holiday =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619590/brixpix-assets/images/Holiday-Large_0421_lgaefk.png';
export const fishingUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607911625/brixpix-assets/images/GoneFishing_bjthqg.png';
export const fishing =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619590/brixpix-assets/images/Fishing-Large_0421_zypk3s.png';
export const stackFamUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607911625/brixpix-assets/images/VertFamily_zb2ak8.png';
export const stackFam =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619589/brixpix-assets/images/StackFam-Small_0421_ddyxpc.png';
export const weddingUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607911624/brixpix-assets/images/Wedding_pm3wsu.png';
export const wedding =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619590/brixpix-assets/images/Xany3_0421_npg4hg.png';
export const pianoUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607911624/brixpix-assets/images/Piano_unz78f.png';
export const piano =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619590/brixpix-assets/images/Piano-0421_wlmhyl.png';
export const guyUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607911624/brixpix-assets/images/SquareHandsomeDude_hbtmht.png';
export const guy =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619589/brixpix-assets/images/HandsomeGuy-Large_0421_ulvwxv.png';
export const orangeGirlUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607911623/brixpix-assets/images/OrangeGirl_qpv1kr.png';
export const orangeGirl =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619589/brixpix-assets/images/OrangeGirl-Brix_0421_a1ktck.png';
export const babyUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607911623/brixpix-assets/images/Mikayla_hdyoig.png';
export const baby =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619590/brixpix-assets/images/MckBaby-Large_0421_zvfcso.png';
export const illustrationUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607911623/brixpix-assets/images/IllustrationBlue_zciqox.png';
export const illustration =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619589/brixpix-assets/images/SpencerIllus_0421_mslavy.png';
export const herculesUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607911622/brixpix-assets/images/Hercules_feovcd.png';
export const hercules =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619590/brixpix-assets/images/Hercueles-Large-0421_c3cg6w.png';
export const famUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607911622/brixpix-assets/images/CircleFamily_uvggbz.png';
export const fam =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619590/brixpix-assets/images/CircleFam-Large-1_0421_qsrse9.png';
export const gerriKevUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607911622/brixpix-assets/images/GerriKev_foyqoi.png';
export const gerriKev =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619589/brixpix-assets/images/GerriKev-Large-0421_f3bln5.png';
export const arielWeddingUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607911622/brixpix-assets/images/ArielWedding_s6ydiy.png';
export const arielWedding =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619589/brixpix-assets/images/Arial_Wedding-BrixButton_0421_ln2gha.png';

// start making memories page
export const doThis =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607918370/brixpix-assets/images/W-StartBrix-Photos-Dothis_Do_this_naid6y.png';
export const notThis =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607918371/brixpix-assets/images/W-StartBrix-Photos_Not_this_k83mtp.png';

// pricing page
export const hercString =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607920332/brixpix-assets/images/HercStringCropSq-01-01_dhrsdh.jpg';
export const hercStringUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607920333/brixpix-assets/images/HercStringBrixSq-01-01_b1zmco.jpg';
export const hercStringPix =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618623424/brixpix-assets/images/HercStringBrixSq-01-01-1_cwegsn.png';

// home page
export const esteeGrass =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607992011/brixpix-assets/images/EsteeGrassCrop-01_ugffmv.jpg';
export const esteeGrassUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607992011/brixpix-assets/images/EsteeGrassBrix-01_otvslt.jpg';
export const esteeGrassPix =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619290/brixpix-assets/images/EsteeGrassBrix-01-1_tjptap.png';
export const colorFun =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607992011/brixpix-assets/images/ColorFunsCrop-01_sf8pue.jpg';
export const colorFunUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607992011/brixpix-assets/images/ColorFunsBrix-01_kezo0k.jpg';
export const colorFunPix =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619290/brixpix-assets/images/ColorFunsBrix-01-1_zrqmwu.png';
export const adam =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1608326589/brixpix-assets/images/AdamCrop-01_bahsu3.png';
export const adamUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1608326588/brixpix-assets/images/AdamBrix-01_kqhfj3.png';
export const adamPix =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619290/brixpix-assets/images/AdamBrix-01-1_rv6mkt.png';
export const mintiaGerri =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607992012/brixpix-assets/images/MintiaGerriCrop-01_mgvefc.jpg';
export const mintiaGerriUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1607992012/brixpix-assets/images/MintiaGerriBrix-01_hpgld6.jpg';
export const mintiaGerriPix =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619290/brixpix-assets/images/MintiaGerriBrix-01-2_w9qi2s.png';
export const pedersons =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1608326588/brixpix-assets/images/PedersensCrop-01_u9anjf.png';
export const pedersonsUnpatterned =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1608326588/brixpix-assets/images/PedersensBrix-01_uxuzxc.png';
export const pedersonsPix =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618619290/brixpix-assets/images/PedersensBrix-01-1_lkzmky.png';

// NEW home page
export const colorfulWoman =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1621554612/brixpix-assets/images/Step1-ArtImage_fl3kpy.png';
export const colorfulWomanPixelated =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1621554612/brixpix-assets/images/Step3-ArtImage_dldljo.png';
export const oldMan =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1621554613/brixpix-assets/images/Step1-Olderguy_dkodzs.png';
export const oldManPixelated =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1621554612/brixpix-assets/images/Step3-OlderguyFrame_cqy8wb.png';
export const greenHair =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1621554612/brixpix-assets/images/Step1-HispanicBeauty_sirq76.png';
export const greenHairPixelated =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1621554613/brixpix-assets/images/Step3-HispanicBeautyFrame_g5zzui.png';
export const couple =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1621554613/brixpix-assets/images/Step1-Couple_pwjxx4.png';
export const couplePixelated =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1621554612/brixpix-assets/images/Step3-CoupleFramed_sexoh7.png';
export const smirkingMan =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1621554613/brixpix-assets/images/Step1-BWHip_ugjp25.png';
export const smirkingManPixelated =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1621554613/brixpix-assets/images/Step3-BWHip_a0ado9.png';
export const smilingWoman =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1621554613/brixpix-assets/images/Step1-SmileGirl-Vertical_xfeatb.png';
export const smilingWomanPixelated =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1621554614/brixpix-assets/images/Step3-SmilingFrame_tocfrm.png';
export const brixPieces =
  'https://res.cloudinary.com/di4tdtrgn/image/upload/v1621554613/brixpix-assets/images/Step2-HomePageMontage_nwtxuj.png';
