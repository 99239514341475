import React, { useState, useEffect } from 'react';

import { verifyUserToken, getUserById } from '../../api';
import { Blade } from '../../components';
import { isTablet } from '../../helpers';
import { Order, User } from '../../../../common/types';
import { getCurrentUser } from '../../local-storage';

import './Account.css';
import { accountInfo as info } from './accountMenuInfo';
import {
  AccountButtonArea,
  AccountMenuDesktop,
  AccountMenuMobile,
} from './components';
import { EditAccount } from './EditAccount';
import { OrderDetails } from './OrderDetails';
import { OrderHistory } from './OrderHistory';
import { Reorder } from './Reorder';

/*
 * Account
 * PARENT PAGE > Displayed at /account
 * Tracks info for currently logged in user, e.g. past orders
 */

export const Account = () => {
  const [user, setUser] = useState<User | null>(null);
  const [orders, setOrders] = useState<Order[] | null>(null);

  // page index (0 = order history, 1 = reoder, 2 = edit account, 3 = order detail)
  const [currPage, setCurrPage] = useState<number>(0);

  // index of order in past orders array to display if necessary
  const [detailIndex, setDetailIndex] = useState<number>(0);

  // index of suborber in order detail array to display if necessary
  const [detailSubindex, setDetailSubindex] = useState<number>(0);
  const [tablet, setTablet] = useState<boolean>(isTablet());

  /* checks for window size on mount */
  useEffect(() => {
    const onResize = () => {
      setTablet(isTablet());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  /* collect user data on page load */
  useEffect(() => {
    const initializeOrders = async () => {
      // checks for logged in user
      const token = getCurrentUser();
      const res = await verifyUserToken({ token: token });

      if (res) {
        // if user logged in, gather their info
        let email = res.data.user ? res.data.user._id : null;
        email &&
          getUserById(email)
            .then((res: any) => {
              setUser(res.data.data);
              setOrders(res.data.data.orders.reverse());
            })
            .catch((err: any) => console.log(err));
      }
    };

    initializeOrders();
  }, []);

  const viewDetails = (index: number): void => {
    if (orders == null) {
      return;
    }
    setCurrPage(3); // go to order detail view

    let tempIndex = 0;
    for (let i = 0; i < orders?.length; i++) {
      for (let j = 0; j < orders[i].suborders.length; j++) {
        if (index === tempIndex) {
          // find right suborder
          setDetailIndex(i);
          setDetailSubindex(j);
          return;
        }
        tempIndex++;
      }
    }
  };

  // preps first name for display
  const firstName = user ? user.firstName : '';

  // displays appropriate page based on currPage (0 - 3)
  let page;
  switch (currPage) {
    // 0 = order history
    case 0:
      page = <OrderHistory orders={orders} view={viewDetails} />;
      break;
    // 1 = reorder
    case 1:
      page = <Reorder orders={orders} email={user?.email ?? ''} />;
      break;
    // 2 = edit account
    case 2:
      page = <EditAccount user={user} />;
      break;
    // 3 = order detail
    case 3:
      page = (
        <OrderDetails
          order={orders?.[detailIndex]}
          suborderIndex={detailSubindex}
        />
      );
      break;
    default:
      break;
  }

  return (
    <div className="account-page page">
      <Blade index={0} position="left" color="blue" value="My Account" frozen />
      <div className="account-page-inner">
        {tablet ? (
          <AccountMenuMobile // top menu for tablets and mobiles
            name={firstName}
            selectPage={setCurrPage}
            pageIndex={currPage}
            info={info}
          />
        ) : (
          <AccountMenuDesktop // left sidebar for desktops
            name={firstName}
            selectPage={setCurrPage}
            info={info}
          />
        )}
        {/* main area */}
        <div className="account-content">
          <div className="account-subpage-container">
            {/* appropriate page */}
            {page}
            <AccountButtonArea
              backButton={currPage === 3}
              backAction={() => setCurrPage(0)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
