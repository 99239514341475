import React, { useState } from 'react';

import './Button.css';

/*
 * BrixButton
 * Reusable gradient button in style of Brix, primarily for navbar
 */

export type Props = {
  action: () => void;
  value: string;
  color?: string;
  disabled?: boolean;
  active?: boolean;
};

export const BrixButton = ({
  action,
  color,
  value,
  disabled,
  active,
}: Props) => {
  const [hover, setHover] = useState<boolean>(false); // bool if button hovered over right now

  return (
    <div
      className={`brix-button ${disabled && 'inactive-button'}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={action}
    >
      {/* dots */}
      <div className="dot-container">
        <div
          className={`brix-dot ${color}-brix ${(active || hover) && 'active'}`}
        ></div>
      </div>
      {/* main body */}
      <div
        className={`brix-button-body button ${color}-brix ${
          (active || hover) && 'active'
        }`}
      >
        {value}
      </div>
    </div>
  );
};
