import React, { useState } from 'react';

import { login, validateEmail, validatePasswords } from '../../../api';
import { ClassicButton, TextInput } from '../../../components';
import { changeField } from '../../../helpers';
import { envelope as envelopeIcon, eye as eyeIcon } from '../../../media';

export type Props = {
  signup: () => void;
};

export const LoginCheckoutBox = ({ signup }: Props) => {
  const [email, setEmail] = useState<string>('');
  const [emailMessage, setEmailMessage] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordMessage, setPasswordMessage] = useState<string>('');

  // tracks visibility of password fields
  const [showPass, setShowPass] = useState<boolean>(false);

  const checkForm: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();

    var problem = false;

    validateEmail({ email: email, shouldExist: true })
      .then((res: any) => {
        problem = problem || res.data.problem;
        setEmailMessage(res.data.message);

        if (!problem) {
          validatePasswords({
            password: password,
            checkStrength: false,
            user: email,
          })
            .then((res: any) => {
              problem = problem || res.data.problem;
              setPasswordMessage(res.data.message);

              if (!problem) {
                login(email, password, undefined);
              }
            })
            .catch((err: any) => console.log(err));
        }
      })
      .catch((err: any) => console.log(err));
  };

  return (
    <div className="checkout-option-box">
      <p className="large-text blue underline">Sign in to my account</p>
      <div className="checkout-option-content">
        <p className="large-text dark-grey">
          Sign in for faster checkout and to view tracking and order history.
        </p>
        <form className="user-checkout-form custom-scroll">
          <div className="input-line">
            <TextInput
              name="Email"
              value={email}
              change={changeField}
              setter={setEmail}
              messageSetter={setEmailMessage}
              message={emailMessage}
            />
            <div className="input-icon-container">
              <img className="input-icon" src={envelopeIcon} alt="envelope" />
            </div>
          </div>
          <div className="input-line">
            <TextInput
              name="Password"
              value={password}
              change={changeField}
              setter={setPassword}
              messageSetter={setPasswordMessage}
              password={!showPass}
              message={passwordMessage}
            />
            <div className="input-icon-container">
              <img
                className="input-icon clickable"
                src={eyeIcon}
                onClick={() => setShowPass(!showPass)}
                alt="eye"
              />
            </div>
          </div>
        </form>
        <div className="checkout-login-note">
          <p className="purple">
            Don't have an account yet?{' '}
            <b className="rose clickable" onClick={signup}>
              Make one here.
            </b>
          </p>
        </div>
        <ClassicButton color="eggplant" value="Checkout" action={checkForm} />
      </div>
    </div>
  );
};
