import React from 'react';

import './DividerLine.css';

export type Props = {
  color: string;
};

export const DividerLine = ({ color }: Props) => {
  return (
    <div className="divider-line-container">
      <div className={`divider-line ${color}-background`} />
    </div>
  );
};
