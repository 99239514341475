import React, { createContext, useEffect, useState } from 'react';

import { verifyUserToken } from '../api';
import { Item } from '../../../common/types';
import { getCurrentUser } from '../local-storage';

export type AuthContextInterface = {
  isLoggedIn: boolean;
  id: string;
  cart: Item[];
};

export type Props = {
  children: React.ReactNode;
};

export const AuthContext = createContext<AuthContextInterface | null>(null);

export const AuthContextProvider = ({ children }: Props) => {
  const [userInfo, setUserInfo] = useState<AuthContextInterface | null>(null);

  const setDefaultUserInfo = () => {
    setUserInfo({
      isLoggedIn: false,
      id: '',
      cart: [],
    });
  };

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        // checks if user logged in
        const token = getCurrentUser();

        if (!token) {
          setDefaultUserInfo();
          return;
        }

        const res = await verifyUserToken({ token: token });
        const id = res.data.user.email;
        if (!id) {
          setDefaultUserInfo();
          return;
        }

        setUserInfo({
          isLoggedIn: true,
          id,
          cart: res.data.user.cart,
        });
      } catch (e) {
        setDefaultUserInfo();
      }
    };
    getUserInfo();
  }, []);

  return (
    <AuthContext.Provider value={userInfo}>{children}</AuthContext.Provider>
  );
};
//basically line 61 means that all the children of AuthContext refresh
//whenever the userInfo changes. thus, AuthContext provider must be on top