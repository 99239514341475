import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { BladeManager, ResponsivePage } from '../../components';
import { getCurrentBlade } from '../../local-storage';
import { isTablet } from '../../helpers';

import { HomeMobile } from './Home';
import { mainPageInfo as info } from './mainPageInfo';
import './MainPage.css';

/*
 * MainPage
 * responsible for all pages on landing page on desktop, or their mobile equivalents
 */

export const MainPage = () => {
  const [tablet, setTablet] = useState<boolean>(isTablet());
  const [defaultBlade, setDefaultBlade] = useState<number>(0); // last visited blade

  /* checks for window size on mount */
  useEffect(() => {
    const findBlade = () => {
      // gets name of current blade from local storage
      // so that navigating away from a blade then back brings you to where you left off
      const bladeName = getCurrentBlade();
      return bladeName ? info.findIndex((page) => page.key === bladeName) : 0; // defaults to homepage if none specified
    };

    // updates on window resize
    const onResize = () => {
      setTablet(isTablet());
      setDefaultBlade(findBlade());
    };

    setDefaultBlade(findBlade()); // initializes blade
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <>
      {tablet ? (
        defaultBlade === 0 ? (
          <HomeMobile />
        ) : (
          <Redirect to={info[defaultBlade].url} />
        )
      ) : (
        <ResponsivePage
          component={BladeManager}
          info={info}
          defaultBlade={defaultBlade}
        />
      )}
    </>
  );
};
