/*
 * setCurrentBlade
 * tracks most recently visited blade of home
 */
export const setCurrentBlade = (bladeName: string) => {
  window.localStorage.setItem('blade', bladeName);
};

/*
 * getCurrentBlade
 * retrieves name of most recently visited blade if there is one, otherwise returns undefined
 */
export const getCurrentBlade = (): string => {
  return window.localStorage.getItem('blade') ?? '';
};

/*
 * removeCurrentBlade
 * clears out the most recently visited blade
 */
export const removeCurrentBlade = () => {
  if (window.localStorage.getItem('blade')) {
    window.localStorage.removeItem('blade');
  }
};
