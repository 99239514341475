import React from 'react';

import './Button.css';

/*
 * ClassicButton
 * General-purpose button to trigger onscreen events
 */

export type Props = {
  value: string;
  color: string;
  action?: React.MouseEventHandler<HTMLDivElement>;
  submit?: boolean; // if this is submit button for a form
  disabled?: boolean;
};

export const ClassicButton = ({
  value,
  color,
  action,
  submit,
  disabled,
}: Props) => {
  const inputRef = React.useRef(null)
  return (
    <div className="classic-button-container">
      {submit ? (
        <input
          type="submit"
          value={value}
          className={`classic-button ${disabled ? 'disabled' : color + '-background'
            }`}
        />
      ) : (
        <div ref={inputRef}
          className={`classic-button ${disabled ? 'disabled' : color + '-background'
            }`}
          onClick={disabled ? () => { } : action}
        >
          {value}
        </div>
      )}
    </div>
  );
};
