import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import {
  Checkbox,
  ClassicButton,
  RadioButton,
  SelectInput,
} from '../../components';
import { changeField, states, abbrevs } from '../../helpers';
import { Address, ShippingOption, User } from '../../../../common/types';

import Analytics from 'analytics'
import { AnalyticsProvider } from 'use-analytics'
import googleAnalyticsPlugin from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'BrixPix dev',
  plugins: [
    googleAnalyticsPlugin({
      trackingId: 'UA-190448776-2',
    })]
})


export type Props = {
  user: User | null;
  updateAddress: (zip: string) => void;
  updateShipping: (index: number) => void;
  changeStep: (address: Address, gift: boolean, shipping: number) => void;
  shippingOptions: ShippingOption[] | null;
  startable: boolean;
  expandSelf: (stepNum: number) => void;
  stepNum: number;
  expanded: boolean;
};

export const ReviewSection = ({
  user,
  updateAddress,
  updateShipping,
  changeStep,
  shippingOptions,
  startable,
  expandSelf,
  stepNum,
  expanded,
}: Props) => {

  const [optionsComp, setOptionsComp] = useState<ShippingOption[] | null>();
  const [address, setAddress] = useState<string>('');
  const [addressMessage, setAddressMessage] = useState<string>('');

  const [shipping, setshipping] = useState<number | null>(null);
  const [gift, setGift] = useState<boolean>(false);

  const addressOptions = user?.address.map(
    (address, index) =>
      `${address.name}, 
      ${address.streetAddress}, 
      ${address.streetAddress2 ? ' ' + address.streetAddress2 + ', ' : ''}
      ${address.city}, 
      ${abbrevs[states.indexOf(address.state)]}`
  );

  useEffect(() => {
    if (shippingOptions != null) {
      setOptionsComp(shippingOptions);
    }
  }, [shippingOptions]);

  //set react state once shipping options comes in not null
  //make react components directly access the react state 

  useEffect(() => {
    setAddress(addressOptions?.[0] ?? "");
  }, [addressOptions]);
  /*
    useEffect(() => {
      if (address === addressOptions?.[0]) {
        setAddress(addressOptions?.[0] ?? '');
      }
      for (let i = 0; i < (addressOptions?.length ?? 0); i++) {
        if (addressOptions?.[i] === address) {
          setAddress(addressOptions?.[i] ?? '');
        }
      }
      //setAddress(addressOptions?.[0] ?? '');
    }, [addressOptions]);
  */
  //this is probably what is causing the problem

  useEffect(() => {
    if (!_.isEmpty(user?.address)) {
      for (let i = 0; i < user!.address.length; i++) {
        if (
          address.includes(user!.address[i].name) &&
          address.includes(user!.address[i].streetAddress)
        ) {
          updateAddress(`${user!.address[i].zip}`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, user?.address]);

  useEffect(() => {
    if (shipping !== null) {
      updateShipping(shipping);
    }
  }, [shipping, updateShipping]);

  const getAddressObject = () => {
    let addressObj: Address | null = null;
    analytics.track("Go to Payment Pressed", {
      category: "Checkout Step"
    });

    if (!_.isEmpty(user?.address)) {
      for (let i = 0; i < user!.address.length; i++) {
        if (
          address.includes(user!.address[i].name) &&
          address.includes(user!.address[i].streetAddress)
        ) {
          addressObj = user!.address[i];
        }
      }
    }
    if (shipping == null || addressObj == null) {
      return;
    }
    changeStep(addressObj, gift, shipping);
  };
  //const options = shippingOptions; OG code
  /*
    var shippingLine1 = options
      ? `${options[0].name} - $${options[0].cost.toFixed(2)}`
      : 'none';
    var shippingSub1 = options ? options[0].time : '';
    var shippingLine2 = options
      ? `${options[1].name} - $${options[1].cost.toFixed(2)}`
      : 'none';
    var shippingSub2 = options ? options[1].time : '';
    var shippingLine3 = options
      ? `${options[2].name} - $${options[2].cost.toFixed(2)}`
      : 'none';
    var shippingSub3 = options ? options[2].time : '';
    }
    */


  return (
    <div>
      <p
        className={`purple large-text underline ${startable ? 'clickable' : ''
          }`}
        onClick={() =>
          startable ? expandSelf(stepNum === 2 ? -1 : 2) : undefined
        }
      >
        Step 2: Shipping Details
      </p>
      <br />
      <div className={expanded ? 'visible-slider' : 'hidden-slider'}>
        <div className="review-section-content">
          <p className="purple">
            Select the address where you'd like to send your BrixPix, as well as
            your shipping options. (may take a moment to load)
          </p>
          {/* {user.address.length > 1 && props.items.length > 1 && <p className="purple">Ship to more than one address?</p>} */}
          <div>
            <form className="shipping-final-form">
              <div className="shipping-final-form-left">
                <SelectInput
                  name="Shipping Address"
                  options={addressOptions ?? []}
                  value={address}
                  setter={setAddress}
                  message={addressMessage}
                  messageSetter={setAddressMessage}
                  change={changeField}
                />
                <Checkbox
                  label="Is this a gift?"
                  checked={gift}
                  check={() => setGift(!gift)}
                  sublabel="If this option is selected, your BrixPix kit will be delivered without an included preview of the completed image, ensuring that the finished art piece is a total surprise to the recipient."
                />
              </div>
              <div className="shipping-radio-section">
                <RadioButton
                  //label={shippingLine1}
                  label={optionsComp ? `${optionsComp[0].name} - $${optionsComp[0].cost.toFixed(2)}`
                    : ''}

                  select={() => setshipping(0)}
                  selected={shipping === 0}
                  small
                  //sublabel={shippingSub1}
                  sublabel={optionsComp ? optionsComp[0].time : ''}
                />
                <RadioButton
                  //label={shippingLine2}
                  label={optionsComp ? `${optionsComp[1].name} - $${optionsComp[1].cost.toFixed(2)}`
                    : ''}
                  select={() => setshipping(1)}
                  selected={shipping === 1}
                  small
                  //sublabel={shippingSub2}
                  sublabel={optionsComp ? optionsComp[1].time : ''}
                />
                <RadioButton
                  //label={shippingLine3}
                  label={optionsComp ? `${optionsComp[2].name} - $${optionsComp[2].cost.toFixed(2)}`
                    : ''}
                  select={() => setshipping(2)}
                  selected={shipping === 2}
                  small
                  //sublabel={shippingSub3}
                  sublabel={optionsComp ? optionsComp[2].time : ''}
                />
              </div>
            </form>
          </div>
          <div className="review-button-container">
            <ClassicButton
              value="Go to Payment"
              color="eggplant"
              disabled={shipping === null}
              action={getAddressObject}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
