import React from 'react';

import { ErrorMessage } from './ErrorMessage';

export type Props = {
  name: string;
  value: string;
  setter: (value: string) => void;
  message: string;
  messageSetter: (value: string) => void;
  change: (
    setter: (value: string) => void,
    messageSetter: (message: string) => void,
    value: string
  ) => void;
};

export const TextArea = ({
  name,
  value,
  setter,
  message,
  messageSetter,
  change,
}: Props) => {
  return (
    <div className="text-area-container">
      <label>{name}</label>
      <textarea
        name={name}
        className="text-area"
        value={value}
        onChange={(e) => change(setter, messageSetter, e.target.value)}
      />
      {/* error */}
      <ErrorMessage message={message} />
    </div>
  );
};
