/*
 * dataURItoBlob
 * converts dataURI to blob format, allows blob to be uploaded as image to Cloudinary API
 * Code taken from https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
 */
export const dataURItoBlob = (dataURI: string): Blob => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
};

/*
 * toHTTPS
 * converts http url to https equivalent
 */
export const toHTTPS = (http: string): string => {
  // let http = result.info.url;
  let https = http;
  if (http.substring(0, 5) !== 'https') {
    https = http.slice(0, 4) + 's' + http.slice(4);
  }
  return https;
};
