import React from 'react';

import { SidewaysButton } from '../../../components';
import useGAEventsTracker from '../../../hooks/useGAEventsTracker';

import Analytics from 'analytics'
import { AnalyticsProvider } from 'use-analytics'
import googleAnalyticsPlugin from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'BrixPix dev',
  plugins: [
    googleAnalyticsPlugin({
      trackingId: 'UA-190448776-2',
    })]
})

/*
 * ContinueShoppingSection
 * Section at bottom of cart page with buttons
 */

export type Props = {
  toCheckout: () => void;
  sum: string;
  mobile: boolean;
};

export const ContinueShoppingSection = ({ toCheckout, sum, mobile }: Props) => {
  return (
    <div className="continue-shopping-section">
      {/* continue shopping button */}
      <SidewaysButton
        value="Keep Shopping"
        action={() => {
          analytics.track("Keep Shopping Pressed", {
            category: "Review Page"
          });
          (window.location.href = '/order')
        }
        }
        color="eggplant"
        left
        small={mobile}
      />
      {/* to checkout button */}
      <div className="to-precheckout-button">
        <SidewaysButton
          value="Checkout"
          action={toCheckout}
          color="eggplant"
          right
          disabled={parseInt(sum) === 0}
          small={mobile}
        />
      </div>
    </div>
  );
};
