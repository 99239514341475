import React from 'react';

import { Checkbox } from '../../../components';
import { Coupon } from '../../../../../common/types';
import { activateCoupon } from '../../../api';

export type Props = {
  coupon: Coupon;
};

export const CouponRecord = ({ coupon }: Props) => {
  const code = coupon.code ? coupon.code : '';
  const type = coupon.type ? coupon.type : '';

  const quantity = `${type === 'Dollars' ? '$' : ''}${coupon.quantity}${
    type === 'Percent' ? '%' : ''
  } off`;

  const active = coupon && coupon.active ? true : false;

  const handleActivate = () => {
    activateCoupon(coupon, !active);
  };

  return (
    <>
      <div className="flex-cell left-align-col vertical-padded-cell horizontal-padded-cell">
        <p className="large-text purple">{code}</p>
      </div>
      <div className="flex-cell center-align-col vertical-padded-cell">
        <p className="large-text purple">{type}</p>
      </div>
      <div className="flex-cell center-align-col vertical-padded-cell">
        <p className="large-text purple">{quantity}</p>
      </div>
      <div className="flex-cell center-align-col vertical-padded-cell">
        <Checkbox checked={active} check={handleActivate} />
      </div>
    </>
  );
};
