export const fillOneBigPixel = (
  data: Uint8ClampedArray,
  startOfBigPix: number,
  pixToCombine: number,
  width: number,
  red: number,
  green: number,
  blue: number
) => {
  let index = startOfBigPix;
  let startOfBigPixRow;

  // for every small pixel in the big pixel, vertically
  for (let i = 0; i < pixToCombine; i++) {
    // mark the index at the left of the pixel row within a big pixel
    startOfBigPixRow = index;

    // for every small pixel in the big pixel, horizontally
    for (let j = 0; j < pixToCombine; j++) {
      // replace the individual RGB values in each pixel with the averaged ones
      data[index] = red;
      data[index + 1] = green;
      data[index + 2] = blue;
      data[index + 3] = 255;

      index += 4;
    }
    // move to next row in big pixel
    index = startOfBigPixRow + width * 4;
  }
};
