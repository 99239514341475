import React, { useState } from 'react';

import { login, validateEmail, validatePasswords } from '../../api';
import { Blade, ClassicButton, TextInput } from '../../components';
import { changeField } from '../../helpers';
import {
  envelope as envelopeIcon,
  eye as eyeIcon,
  alertBlue as alert,
} from '../../media';

import './Login.css';

export const Login = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [emailMessage, setEmailMessage] = useState<string>('');
  const [passwordMessage, setPasswordMessage] = useState<string>('');

  // tracks visibility of password field
  const [showPass, setShowPass] = useState<boolean>(false);

  /*
   * checkForm
   * validates form inputs when user submits
   * displays error messages if errors
   * otherwise creates user
   */
  const checkForm: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();

    var problem = false;

    validateEmail({ email: email, shouldExist: true })
      .then((res: any) => {
        problem = problem || res.data.problem;
        setEmailMessage(res.data.message);

        if (!problem) {
          validatePasswords({
            password: password,
            checkStrength: false,
            user: email,
          })
            .then((res: any) => {
              problem = problem || res.data.problem;
              setPasswordMessage(res.data.message);

              if (!problem) {
                login(email, password);
              }
            })
            .catch((err: any) => console.log(err));
        }
      })
      .catch((err: any) => console.log(err));
  };

  return (
    <div className="login page">
      <Blade
        index={0}
        frozen
        position="left"
        value="Account Login"
        color="blue"
      />
      <div className="login-container">
        <div className="login-form-container">
          <h1 className="login-title">Account Login</h1>
          <p className="purple login-subtitle">
            Login to your BrixPix account to access your orders and tracking
            details, and to re-order.
          </p>
          <form className="login-form">
            <div className="input-line">
              <TextInput
                name="Email"
                value={email}
                change={changeField}
                setter={setEmail}
                messageSetter={setEmailMessage}
                message={emailMessage}
              />
              <div className="input-icon-container">
                <img className="input-icon" src={envelopeIcon} alt="envelope" />
              </div>
            </div>
            <div className="input-line">
              <TextInput
                name="Password"
                value={password}
                change={changeField}
                setter={setPassword}
                messageSetter={setPasswordMessage}
                password={!showPass}
                message={passwordMessage}
              />
              <div className="input-icon-container">
                <img
                  className="input-icon clickable"
                  src={eyeIcon}
                  onClick={() => setShowPass(!showPass)}
                  alt="eye"
                />
              </div>
            </div>
          </form>
          <div className="login-button-container">
            <ClassicButton
              action={checkForm}
              value="Login to My Account"
              color="blue"
            />
          </div>
          <div className="login-padded login-alert-line login-bottom">
            <p className="blue inline-block">
              Help,{' '}
              <b>
                <a href="/forgot-password">I forgot my password</a>
              </b>
            </p>
            <img
              src={alert}
              className="tiny-alert inline-block clickable"
              onClick={() => (window.location.href = '/forgot-password')}
              alt="alert exclamation point"
            />
          </div>
          <div className="login-padded">
            <p className="purple login-bottom">
              Don't have an account yet?{' '}
              <b>
                <a href="/signup">Make one here.</a>
              </b>
            </p>
            <p className="purple small login-bottom">
              Remember, we will never share or sell your information. At BrixPix
              we value privacy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
