import React from 'react';

import { RadioButton } from '../../../components';
import { ShapeOption as ShapeOptionType } from '../../../../../common/types';

/*
 * shapeOption
 * displays single row for a single shape option in order process
 */

export type Props = {
  height: number;
  width: number;
  title: ShapeOptionType;
  description: string;
  select: (shape: ShapeOptionType) => void;
  selected: boolean;
  tablet: boolean;
  mobile: boolean;
};

export const ShapeOption = ({
  height,
  width,
  title,
  description,
  select,
  selected,
  tablet,
  mobile,
}: Props) => {
  // sets dimensions of sample shape
  const style = { height: `${height}em`, width: `${width}em` };

  return (
    <div
      className="shape-option"
      onClick={() => select(title)}
    >
      <div className="shape-option-button">
        <RadioButton
          label={title}
          select={select}
          selected={selected}
          medium={tablet}
          sublabel={mobile ? description : undefined}
        />
      </div>
      <div className="example-shape-container">
        <div
          className="example-shape clickable"
          style={style}
          onClick={() => select(title)}
        ></div>
      </div>
      <div className="shape-description-container">
        <p className="large-text dark-grey">{description}</p>
      </div>
    </div>
  );
};
