import React, { useState } from 'react';

import { validateEmail, forgotPassword } from '../../api';
import { Blade, ClassicButton, TextInput } from '../../components';
import { changeField } from '../../helpers';
import { envelope as envelopeIcon } from '../../media';

import './Password.css';

export const ForgotPassword = () => {
  const [email, setEmail] = useState<string>('');
  const [emailMessage, setEmailMessage] = useState<string>('');

  const [done, setDone] = useState<boolean>(false);

  /*
   * checkForm
   * validates form inputs when user submits
   * displays error messages if errors
   * otherwise sends password reset link
   */
  const checkForm = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    let problem;

    const res = await validateEmail({
      email: email,
      shouldExist: true,
    });

    if (res) {
      problem = res.data.problem;
      setEmailMessage(res.data.message);
    } else {
      problem = true;
    }

    if (!problem) {
      setDone(true);
      forgotPassword(email);
    }
  };

  return (
    <div className="password page">
      <Blade
        index={0}
        frozen
        position="left"
        value="Forgot Password"
        color="blue"
      />
      <div className="password-container pixelated-background">
        <div className="password-form-container">
          <h1 className="password-title">Forgot Password?</h1>
          <p className="purple password-subtitle">
            Enter your email and we'll send you a password reset link.
          </p>
          <form className="password-form" onSubmit={checkForm}>
            <div className="input-line">
              <TextInput
                name="Email"
                value={email}
                change={changeField}
                setter={setEmail}
                messageSetter={setEmailMessage}
                message={emailMessage}
              />
              <div className="input-icon-container">
                <img className="input-icon" src={envelopeIcon} alt="envelope" />
              </div>
            </div>
            {!done && (
              <div className="password-button-container">
                <ClassicButton value="Send Reset Link" color="blue" submit />
              </div>
            )}
          </form>
          <div className="password-padded">
            {done ? (
              <h4 className="blue password-text">
                You will be receiving an email with a password reset link
                shortly.
              </h4>
            ) : (
              <>
                <p className="purple password-text">
                  Don't have an account yet?{' '}
                  <b>
                    <a href="/signup">Make one here.</a>
                  </b>
                </p>
                <p className="purple password-text">
                  Or remembered your password?{' '}
                  <b>
                    <a href="/login">Go back to login.</a>
                  </b>
                </p>
                <p className="purple small password-text">
                  Remember, we will never share or sell your information. At
                  BrixPix we value privacy.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
