import React from 'react';

export const PricingBottom = () => {
  return (
    <>
      <p className="purple">
        Please note that shipping and handling are not included in these prices.
        They will automatically be calculated and added to your total at
        checkout!
      </p>
      <br />
      <p className="purple">
        Looking for additional sizes? We'd be happy to help with all custom size
        inquiries. For more information, please contact{' '}
        <a href="mailto:solutions@brixpix.com">solutions@brixpix.com</a>.
      </p>
    </>
  );
};
