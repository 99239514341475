import React from 'react';

import { alertPurple as alertIcon } from '../../media';
import './NotFound.css';

export const NotFound = () => {
  return (
    <div className="not-found page">
      <div className="not-found-content">
        <div>
          <div className="alert-404-container">
            <img
              src={alertIcon}
              className="alert-404"
              alt="alert exclamation point"
            />
          </div>
          <div className="alert-404-headings">
            <h2 className="purple">Oops.</h2>
            <h2 className="purple">Looks like we're lost!</h2>
          </div>
        </div>
        <div className="not-found-copy">
          <p className="purple not-found-text">
            Something has gone wrong. Return to the BrixPix quick-links:
          </p>
          <p className="purple not-found-text">
            Return to home:{' '}
            <b>
              <a href="/">BrixPix.com</a>
            </b>
          </p>
          <p className="purple not-found-text">
            Login to your account:{' '}
            <b>
              <a href="/login">BrixPix.com/login</a>
            </b>
          </p>
          <p className="purple not-found-text">
            If you are still having challenges, let us know where things went
            awry:{' '}
            <a href="mailto:404notice@brixpix.com">404notice@brixpix.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};
