import React from 'react';

import { cloudinaryStyles } from '../../../styles/Cloudinary';
import { ClassicButton } from '../../../components';
import { toHTTPS } from '../../../helpers';

import { ItemInfo } from '../../../types';
import './UploadStep.css';

/*
 * UploadStep
 * step in order process to upload image
 */

export type Props = {
  info: Pick<ItemInfo, 'sizeData' | 'shape'>;
  finish: (info: Pick<ItemInfo, 'width' | 'height' | 'url'>) => void;
};

export type State = {
  url: string;
  width: number | null;
  height: number | null;
  error: '';
};

export class UploadStep extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      url: '', // url of original image
      width: null, // calculated width of original image
      height: null, // calculated height of original image,
      error: '', // error message if necessary
    };
  }

  /*
   * uploadWidget
   * Renders upload widget, enforces cropping, and saves original image to cloud
   */
  uploadWidget = () => {
    // opens upload widget with enforced cropping to desired ratio
    const widget = (window as any).cloudinary.openUploadWidget(
      {
        cloudName: 'di4tdtrgn',
        uploadPreset: 'brixpix_cloud',
        cropping: true,
        croppingAspectRatio:
          this.props.info.sizeData.imperial.width /
          this.props.info.sizeData.imperial.height,
        showSkipCropButton: false,
        publicId: Date.now(),
        styles: cloudinaryStyles,
      },
      (error: any, result: any) => {
        // handle error, e.g. if photo too big
        if (error) {
          widget.close();
          this.setState({
            error: error.status,
          });
        }
        // save info on success
        else if (result.event === 'success') {
          this.setState({
            url: toHTTPS(result.info.url),
            height: result.info.height,
            width: result.info.width,
            error: '',
          });
          this.sendInfo();
        }
      }
    );
  };

  /*
   * sendInfo
   * updates parent component with image info
   * parameters: none
   * returns: none
   * note: triggers parent function (props.finish)
   */
  sendInfo = () => {
    this.props.finish({
      width: this.state.width ?? 0,
      height: this.state.height ?? 0,
      url: this.state.url,
    });
  };

  render() {
    // styling for image size
    let imageSizeStyle =
      this.props.info.shape === 'Landscape'
        ? { width: '90%' }
        : { height: '90%' };

    return (
      <div className="upload">
        {/* main header, varies if uploaded or not */}
        <h2 className="upload-header">
          {this.state.url ? 'Nice Choice' : 'Upload Your Picture'}
        </h2>
        {/* warnings on uploading */}
        {!this.state.url && (
          <div className="upload-subheader">
            {this.state.error && (
              <p className="error-message large-error">{this.state.error}</p>
            )}
            <p className="purple">
              Note: Images may need to be cropped a tiny bit extra for optimal
              digitization.
            </p>
            <p className="purple">
              <b>Please keep image sizes to under 10MB.</b>
            </p>
          </div>
        )}
        {/* advice on uploading */}
        {!this.state.url && (
          <div className="upload-recommendation-box">
            <b>
              <p className="large-text purple spacey-text upload-text">
                We want your BrixPix to be amazing. A few tips:
              </p>
            </b>
            <p className="large-text purple spacey-text upload-text">
              Well lit photos without heavy shadows are ideal.
            </p>
            <p className="large-text purple spacey-text upload-text">
              Crop pictures as close as possible.
            </p>
            <p className="large-text purple spacey-text upload-text">
              Complex detail might not work as well.
            </p>
          </div>
        )}
        {/* upload button */}
        <div className="upload-button-container">
          <ClassicButton
            value={this.state.url ? 'Re-upload' : 'Upload'}
            action={this.uploadWidget}
            color="gold"
          />
        </div>
        {/* uploaded image */}
        {this.state.url && (
          <div className="upload-image-container">
            <img
              style={imageSizeStyle}
              id="original-image"
              src={this.state.url}
              alt="Original"
            />
          </div>
        )}
      </div>
    );
  }
}
