import React from 'react';
import './FAQ.css';

export const FAQ = () => {
  return (
    <div className="page faq">
      <h2 className="purple">BrixPix FAQ</h2>
      <br />
      <p className="purple">
        Welcome. We hope the below will answer your most pressing questions.
        Should any remain unanswered, please reach out to us at{' '}
        <a href="mailto:help@brixpix.com">help@brixpix.com</a>. We will get back
        to you within 24 hours.{' '}
      </p>
      <br />
      <section>
        <p className="blue large-text">Q: How do I choose the best picture?</p>
        <br />
        <p className="purple">
          <span className="large-text">A: </span>The quality of your BrixPIx
          depends on the quality of the original photo. Below are some tips to
          help maximize the quality of your BrixPix:
        </p>
        <br />
        <ul className="faq-bullets">
          <li>
            <p className="purple">
              The closer the subject, the more detailed your art piece will be.
            </p>
          </li>
          <li>
            <p className="purple">
              Well-lit photos produced better results than those with poor
              lighting.
            </p>
          </li>
          <li>
            <p className="purple">Avoid photos with ‘busy’ backgrounds.</p>
          </li>
        </ul>
        <br />
        <p className="purple">
          If you have any questions regarding your photo or would help ensuring
          the quality of your BrixPix, please email us at{' '}
          <a href="mailto:hello@brixpix.com">hello@brixpix.com</a>.
        </p>
        <br />
      </section>
      <section>
        <p className="blue large-text">Q: Does my BrixPix come preassembled?</p>
        <br />
        <p className="purple">
          <span className="large-text">A: </span>No. BrixPix are designed to be
          assembled by the customer. Each BrixPix Kit comes with the parts and
          bl needed to assemble your BrixPix on your own. We want to encourage
          you to share the experience with your loved ones to create more
          memories.
        </p>
        <br />
      </section>
      <section>
        <p className="blue large-text">Q: What comes inside a BrixPix Kit?</p>
        <br />
        <p className="purple">
          <span className="large-text">A: </span>With the purchase of each
          BrixPix Kit, the customer will receive the base plates, base plate
          connectors, brix tool (for aligning and removing brix), construction
          bricks, and personalized blueprint needed to assemble your custom
          BrixPix art piece.
        </p>
        <br />
      </section>
      <section>
        <p className="blue large-text">Q: What does my blueprint look like?</p>
        <br />
        <p className="purple">
          <span className="large-text">A: </span>BrixPix blueprints are coded,
          with each color corresponding to a specific symbol. Your blueprint
          will appear as a ‘paint-by-numbers’ style guide that will denote each
          individual spot on your base plate with a respective symbol. As each
          brick is placed, the blueprint will inform you on which pieces are
          required next.
        </p>
        <br />
      </section>
      <section>
        <p className="blue large-text">
          Q: Can I order a custom-sized BrixPix?
        </p>
        <br />
        <p className="purple">
          <span className="large-text">A: </span>Yes! For custom requests,
          including sizes and colors, please email us at{' '}
          <a href="mailto:solutions@brixpix.com">solutions@brixpix.com</a>, and
          our team will be happy to provide you with a detailed quote.
        </p>
        <br />
      </section>
      <section>
        <p className="blue large-text">Q: What is your return policy?</p>
        <br />
        <p className="purple">
          <span className="large-text">A: </span>BrixPix are not eligible for
          returns. We can only process a return or exchange if there is a defect
          found in the kit you receive. For further assistance, please email{' '}
          <a href="mailto:solutions@brixpix.com">solutions@brixpix.com</a>.
        </p>
        <br />
      </section>
      <section>
        <p className="blue large-text">
          Q: How long will it take to get my BrixPix?
        </p>
        <br />
        <p className="purple">
          <span className="large-text">A: </span>Delivery times vary between
          locations. On average, within the US, your Brix Pix will be delivered
          between no more than 10 days after completing your order.
        </p>
        <br />
      </section>
      <section>
        <p className="blue large-text">
          Q: Where will my BrixPix kit ship from?
        </p>
        <br />
        <p className="purple">
          <span className="large-text">A: </span>Your BrixPix will be coming to
          you direct from Chicago, Illinois.{' '}
        </p>
        <br />
      </section>
      <section>
        <p className="blue large-text">
          Q: Why is my photo way more cropped than I cropped it?
        </p>
        <br />
        <p className="purple">
          <span className="large-text">A: </span>Our Brixelator works by
          combining pixels from your original image into clean, organized grids
          of Brix. Because we include 32 x 32 Brix in each BrixPix piece, we
          need to neatly consolidate your image's pixels into multiples of 32.
          Sometimes this involves trimming a little bit of extra off the edges.
          The vast majority of the time, this is no big deal, but if you have a
          particularly low-res image, or even a high-res image that you cropped
          a lot, making it much smaller, the few pixels that we trim off might
          end up looking like a significant crop. To avoid this issue, we highly
          recommend you use high-res images, to create the best possible
          product.
        </p>
        <br />
      </section>
    </div>
  );
};
