import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { verifyUserToken, verifyAdmin } from '../api';
import { User } from '../../../common/types';
import { getCurrentUser } from '../local-storage';

export type Props = {
  component: React.ElementType;
  path?: string;
  exact?: boolean;
};

export const AdminRoute = ({ component: Component, ...rest }: Props) => {
  const [user, setUser] = useState<User | null>(null);
  const [admin, setAdmin] = useState<boolean | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const token = getCurrentUser();
    verifyUserToken({ token: token })
      .then((res: any) => {
        res.data.user && setUser(res.data.user._id);
        if (res.data.user) {
          verifyAdmin(res.data.user._id)
            .then((res: any) => {
              setAdmin(res.data.isAdmin);
              setLoaded(true);
            })
            .catch((err: any) => console.log(err));
        } else {
          setLoaded(true);
        }
      })
      .catch((err: any) => console.log(err));
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        loaded ? (
          user ? (
            admin ? (
              <Component {...props} />
            ) : (
              <Redirect to="/" />
            )
          ) : (
            <Redirect to="/login" />
          )
        ) : (
          <></>
        )
      }
    />
  );
};
