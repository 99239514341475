/*
 * setCurrentUser
 * tracks currently logged in user
 */
export const setCurrentUser = (token: string) => {
  window.localStorage.setItem('auth-token', token);
};

/*
 * getCurrentUser
 * retrieves token for currently logged in user if there is one
 */
export const getCurrentUser = (): string => {
  return window.localStorage.getItem('auth-token') ?? '';
};

/*
 * removeCurrentUser
 * clears out the currently logged in user
 */
export const removeCurrentUser = () => {
  if (window.localStorage.getItem('auth-token')) {
    window.localStorage.removeItem('auth-token');
  }
};
