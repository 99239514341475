import React, { useEffect } from 'react';

import { setCurrentBlade } from '../../../local-storage';
import { MobileBladePage } from '../../../components';

import { OrderPage } from './OrderPage';

export const OrderMobile = () => {
  useEffect(() => {
    setCurrentBlade('order');
  }, []);

  return <MobileBladePage bladeLabel="Start Order" component={OrderPage} />;
};
