import {
  entryHallSmall,
  familyRoomSmall,
  diningRoomBlueSmall,
  stripeyRoomSmall,
  bedroomSmall,
} from '../../../media';

// array of background options for room testing
export const backgrounds = [
  diningRoomBlueSmall,
  entryHallSmall,
  familyRoomSmall,
  bedroomSmall,
  stripeyRoomSmall,
];
