import React from 'react';

import { blueProgress, goldProgress, greyProgress } from '../media';

import './ProgressMarker.css';

/*
 * ProgressMarker
 * Tracks steps completed in process
 */

export type Props = {
  value: string;
  step: number; // 1-indexed
  active?: boolean;
  disabled?: boolean;
  smallText?: boolean;
  small?: boolean;
};

export const ProgressMarker = ({
  value,
  step,
  active,
  disabled,
  smallText,
  small,
}: Props) => {
  // position the marker based on size and index
  const markerWidth = small ? 4.5 : 7;
  const stepStyle = {
    left: `${step * markerWidth - markerWidth}em`,
    zIndex: 5 - step,
  };

  // choose background based on active, disabled, or neutral
  var imageSrc = blueProgress;
  if (active) {
    imageSrc = goldProgress;
  } else if (disabled) {
    imageSrc = greyProgress;
  }

  return (
    <div
      className={`progress-marker-container ${small ? 'small' : ''}`}
      style={stepStyle}
    >
      <div className="progress-marker">
        {/* background image */}
        <img
          src={imageSrc}
          className="progress-marker-image"
          alt="progress marker"
        />
        {/* marker label */}
        <span
          className={`progress-marker-text ${smallText && 'small-marker-text'}`}
        >
          <p className="large-text">{value}</p>
        </span>
      </div>
    </div>
  );
};
