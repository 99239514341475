import _ from 'lodash';

import { Order, User } from '../../../../common/types';
import { prepItemForOrder, unpunctuatePhone } from '../../helpers';
import { CartItem, ReorderItem } from '../../types';
import { Address } from '../../../../common/types';

import { updateUser, getUserById, sendConfirmationEmail } from '../endpoints';

/*
 * addAddress
 * wrapper for update user to add a new address to a user
 */
export const addAddress = (
  user: User,
  addressName: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  zip: string
) => {
  // retrieve existing data from user so nothing overwritten
  var updatedUser = user;
  var updatedAddress = user.address;

  // create object for new address
  var newAddress = {
    name: addressName,
    streetAddress: address1,
    streetAddress2: address2,
    city: city,
    state: state,
    zip: parseInt(zip),
  };
  updatedAddress = []; //clear array of addresses
  updatedAddress.push(newAddress); // add new address to address array
  updatedUser.address = updatedAddress; // update address arr for user

  // update user in database
  updateUser(user.email, updatedUser)
    .then((res: any) => {
      window.location.reload();
    })
    .catch((err: any) => console.log(err));
};

/*
 * completeOrder
 * wrapper for update user for processing order at checkout
 */
export const completeOrder = (
  user: User,
  items: CartItem[],
  price: number,
  shippingPrice: number,
  tax: number,
  discount: number,
  address: Address,
  gift: boolean,
  shipping: number, // index of shipping option
  orderNum: number
) => {
  const newOrder: Order = {
    date: new Date(),
    price,
    shippingPrice,
    tax,
    discount,
    orderNumber: orderNum,
    completed: false,
    // TODO: create scenario for address as array, multiple addresses
    suborders: [
      {
        address,
        gift,
        shipping,
        items: items.map((item) => _.merge(item, { instructionsUrl: '' })),
        trackingNumber: '',
      },
    ],
  };

  if (user.orders == null || user.orders.length === 0) {
    user.orders = [newOrder];
  } else {
    user.orders.push(newOrder);
  }
  user.cart = [];

  // update user in database
  updateUser(user.email, user)
    .then(() => {
      sendConfirmationEmail({
        firstName: user.firstName,
        email: user.email,
        order: newOrder,
      });
    })
    .catch((err: any) => console.log(err));
};

/*
 * addToCart
 * wrapper for update user for adding item to cart when they're logged in
 */
export const addToCart = (
  id: string,
  items: ReorderItem[],
  redirect: string = '/cart'
) => {
  getUserById(id)
    .then(async (res: any) => {
      const user = res.data.data;
      const preppedItems = items.map((item) => prepItemForOrder(item));

      if (user.cart && Array.isArray(user.cart)) {
        user.cart.push.apply(user.cart, preppedItems);
      } else {
        user.cart = preppedItems.slice();
      }

      // update user in database
      try {
        await updateUser(id, user);
        window.location.href = redirect;
      } catch (e) {
        console.log(e);
      }
    })
    .catch((err: any) => console.log(err));
};

/*
 * updateCart
 * updates cart in database for given user
 */
export const updateCart = async (
  id: string,
  cart: CartItem[],
  redirect?: string
) => {
  const res = await getUserById(id);

  if (res) {
    const user = res.data.data;
    user.cart = cart;
    // update user in database
    updateUser(id, user)
      .then(() => {
        if (redirect) {
          window.location.href = redirect;
        } else if (redirect === undefined) {
          window.location.reload();
        }
      })
      .catch((err: any) => console.log(err));
  }
};

/*
 * editCoreUser
 * wrapper for update user to edit regular fields (not address or orders)
 */
export const editCoreUser = async (
  email: string,
  user: Partial<User>,
  redirect?: string
) => {
  const { firstName, lastName, phone, password } = user;

  const getRes = await getUserById(email);

  if (getRes) {
    const updatedUser = getRes.data.data;

    updatedUser.firstName = firstName || updatedUser.firstName;
    updatedUser.lastName = lastName || updatedUser.lastName;
    updatedUser.phone = phone
      ? parseInt(unpunctuatePhone(`${phone}`, `${phone}`)!)
      : updatedUser.phone;
    updatedUser.password = password || updatedUser.password;

    // update user in database
    const putRes = await updateUser(email, updatedUser);

    if (putRes) {
      if (redirect) {
        window.location.href = '/';
      } else if (redirect !== null) {
        alert("Updating account! If no red errors are shown, the account updated successfully.");
        //window.location.reload();
      } else {
        return true;
      }
    }
  }
};

/*
 * changeOrderCompletionStatus
 * updates whether a specific order is completed or not
 * note: reloads page upon success
 */
export const changeOrderCompletionStatus = (
  email: string,
  orderIndex: number,
  newStatus: boolean
) => {
  // retrieve existing data from user so nothing overwritten
  getUserById(email)
    .then((res: any) => {
      const user = res.data.data;
      const updatedOrder = user.orders[orderIndex];
      updatedOrder.completed = newStatus;
      user.orders[orderIndex] = updatedOrder;

      // update user in database
      updateUser(email, user)
        .then(() => {
          window.location.reload();
        })
        .catch((err: any) => console.log(err));
    })
    .catch((err: any) => console.log(err));
};

/*
 * setTrackingNumber
 * sets tracking number on specific suborder
 * note: reloads page upon success
 */
export const setTrackingNumber = (
  email: string,
  orderIndex: number,
  suborderIndex: number,
  trackingNum: string
) => {
  // retrieve existing data from user so nothing overwritten
  getUserById(email)
    .then((res: any) => {
      const user = res.data.data;
      const updatedOrder = user.orders[orderIndex];
      const updatedSuborder = updatedOrder.suborders[suborderIndex];
      updatedSuborder.trackingNumber = `${trackingNum}`;

      user.orders[orderIndex] = updatedOrder;

      // update user in database
      updateUser(email, user)
        .then(() => {
          window.location.reload();
        })
        .catch((err: any) => console.log(err));
    })
    .catch((err: any) => console.log(err));
};
