import React from 'react';
import { Link } from 'react-router-dom';
import useGAEventsTracker from '../hooks/useGAEventsTracker';

import { setCurrentBlade } from '../local-storage';

import { DividerLine } from './DividerLine';
import './SliderMenu.css';

import ReactGA from 'react-ga';
import Analytics from 'analytics'
import { AnalyticsProvider } from 'use-analytics'
import googleAnalyticsPlugin from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'BrixPix dev',
  plugins: [
    googleAnalyticsPlugin({
      trackingId: 'UA-190448776-2',
    })]
})

/*NOTE: onclick event doesnt work here*/

export type Props = {
  admin: boolean;
  loggedIn: boolean;
  cartNum: string;
  handleLogin: () => void;
  handleLogout: () => void;
  open: boolean;
  click: () => void;
};

export const SliderMenu = ({
  admin,
  loggedIn,
  cartNum,
  handleLogin,
  handleLogout,
  open,
  click,
}: Props) => {
  return (
    <>
      {open && (
        <div
          onClick={click}
          className={`slider-overlay ${open ? 'visible' : 'invisible'}`}
        />
      )}
      <div className={`slider-menu ${open ? 'open' : ''}`}>
        {/* homepage links */}
        <Link to="/" onClick={() => setCurrentBlade('home')}>
          <p className="slider-menu-item blue large-text">Home</p>
        </Link>
        <Link to="/how-to-brixpix" >
          <p className="slider-menu-item blue large-text">How to BrixPix</p>
        </Link>
        <Link to="/pricing" >
          <p className="slider-menu-item blue large-text">Pricing</p>
        </Link>
        <Link to="/gallery" >
          <p className="slider-menu-item blue large-text">Gallery</p>
        </Link>
        <Link to="/start-order" >
          <p className="slider-menu-item blue large-text">Get Started</p>
        </Link>

        {/* divider */}
        <DividerLine color="gold" />

        {/* generic links */}
        {admin && (
          <Link to="/admin">
            <p className="slider-menu-item rose large-text">Admin</p>
          </Link>
        )}
        {loggedIn && (
          <Link to="/account">
            <p className="slider-menu-item rose large-text">Account</p>
          </Link>
        )}
        <p
          onClick={loggedIn ? handleLogout : handleLogin}
          className="slider-menu-item rose large-text"
        >
          {loggedIn ? 'Logout' : 'Login'}
        </p>
        <Link to="/cart">
          <p className="slider-menu-item rose large-text">Cart ({cartNum})</p>
        </Link>
        <Link to="/contact">
          <p className="slider-menu-item rose large-text">Contact</p>
        </Link>
      </div>
    </>
  );
};
