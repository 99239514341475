import React, { useEffect, useState } from 'react';
// @ts-ignore
import { CircleLoading } from 'react-loadingg';
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch';
import styled from 'styled-components';

import { isMobile, isTablet, isSidewaysTablet, isSmallDesktop, isModerateDesktop, isMediumDesktop, isSufficientDesktop } from '../../../../helpers';
import { Size } from '../../../../../../common/types';
import {
  zoomReset,
  zoomIn as zoomInIcon,
  zoomOut as zoomOutIcon,
} from '../../../../media';

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 5px;
`;

const Control = styled.img`
  padding-bottom: 3px;
`;

/*
 * BrixilatedImage
 * displays new image or loading animation, depending on if loaded yet
 */

export type Props = {
  sizeData: Size;
  getPixelCoordinates: (x: number, y: number) => void;
  redrawImage: () => void;
  loading: boolean;
  initializeDimensions: (width: number, height: number) => void;
};

export const BrixilatedImage = ({
  sizeData,
  loading,
  getPixelCoordinates,
  redrawImage,
  initializeDimensions,
}: Props) => {
  const [scale, setScale] = useState(1);
  const [mobile, setMobile] = useState(isMobile());
  const [tablet, setTablet] = useState(isTablet());
  const [sidewaysTablet, setSidewaysTablet] = useState(isSidewaysTablet());
  const [smallDesktop, setSmallDesktop] = useState(isSmallDesktop());
  const [moderateDesktop, setModerateDesktop] = useState(isModerateDesktop());
  const [mediumDesktop, setMediumDesktop] = useState(isMediumDesktop());
  const [sufficientDesktop, setSufficientDesktop] = useState(isSufficientDesktop());


  //const PRIMARY_DIMENSION = mobile ? 400 : tablet ? 550 : 500; //last one originally 300!! 500 works
  //if mobile: 400x400. if tablet: 550x550. If desktop, 500x500
  const PRIMARY_DIMENSION = mobile ? 400 : tablet ? 550 : sidewaysTablet ? 400 : moderateDesktop ? 400 : mediumDesktop ? 450 : sufficientDesktop ? 500 : 550;
  //before percent changes: mobile ? 400 : tablet ? 550 : sidewaysTablet ? 400 : moderateDesktop ? 370 : mediumDesktop ? 400 : sufficientDesktop ? 450 : 500;

  const width =
    tablet || (sizeData.shape === 'Landscape')
      ? moderateDesktop
        ? PRIMARY_DIMENSION
        : PRIMARY_DIMENSION * 1.2
      : sizeData.shape === 'Portrait'
        ? (PRIMARY_DIMENSION / sizeData.imperial.height) * sizeData.imperial.width * 1.2
        : (PRIMARY_DIMENSION / sizeData.imperial.height) * sizeData.imperial.width
  const height =
    tablet || sizeData.shape === 'Landscape'
      ? moderateDesktop
        ? (PRIMARY_DIMENSION / sizeData.imperial.width) * sizeData.imperial.height
        : (PRIMARY_DIMENSION / sizeData.imperial.width) * sizeData.imperial.height * 1.2
      : sizeData.shape === 'Portrait'
        ? PRIMARY_DIMENSION * 1.2
        : PRIMARY_DIMENSION; //* 2 added by me
  //width = sizeData.shape === 'Portrait' ? (PRIMARY_DIMENSION / sizeData.imperial.height) * sizeData.imperial.width * 1.2 : (PRIMARY_DIMENSION / sizeData.imperial.height) * sizeData.imperial.width

  useEffect(() => {
    // checks for window size on mount
    const onResize = () => {
      setMobile(isMobile());
      setTablet(isTablet());
      setSidewaysTablet(isSidewaysTablet());
      setModerateDesktop(isModerateDesktop());
      setMediumDesktop(isMediumDesktop());
      setSufficientDesktop(isSufficientDesktop());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    // initialize dimensions of canvas
    const image = document.getElementById('smaller-canvas');
    const rect = image!.getBoundingClientRect();
    const { width, height } = rect; // scale = 1
    console.log("Is Tablet: " + isTablet());
    console.log("Is Mobile: " + isMobile());
    console.log("Is Sideways Tablet: " + isSidewaysTablet());
    console.log("Is Small Desktop: " + isModerateDesktop());
    console.log("Is Medium Desktop: " + isMediumDesktop());
    console.log("New Width: " + width + " New Height: " + height);
    initializeDimensions(width, height); //multiplying by 2 does nothing
    console.log("initialize dimensions called via hook!");
  }, [initializeDimensions]);

  useEffect(() => {
    // makes sure not blurry
    const canvas = document.getElementById('smaller-canvas');
    const context = (canvas! as HTMLCanvasElement).getContext('2d');

    const scale = window.devicePixelRatio;
    context!.scale(scale, scale);

    canvas!.style.width = width + 'px';
    canvas!.style.height = height + 'px';

    (canvas! as HTMLCanvasElement).width = Math.floor(width * scale);
    (canvas! as HTMLCanvasElement).height = Math.floor(height * scale);
  }, [height, width]);

  useEffect(() => {
    const getMousePosition = (event: MouseEvent) => {
      const image = document.getElementById('smaller-canvas');
      const rect = image!.getBoundingClientRect();
      const { top, right, bottom, left } = rect;

      const targetX = event.clientX;
      const targetY = event.clientY;

      // vertically flipped for some reason
      let x = targetX > left && targetX < right ? targetX - left : null;
      let y = targetY > top && targetY < bottom ? targetY - top : null;

      // if clicked within the image
      if (x != null && y != null) {
        console.log("Is Tablet: " + isTablet());
        console.log("Is Mobile: " + isMobile());
        console.log("Is Sideways Tablet: " + isSidewaysTablet());
        console.log("Is Small Desktop: " + isModerateDesktop());
        console.log("Is Medium Desktop: " + isMediumDesktop());
        getPixelCoordinates(x / scale, y / scale);
      }
    };

    window.addEventListener('mousedown', getMousePosition);

    return () => window.removeEventListener('mousedown', getMousePosition);
  }, [getPixelCoordinates, scale]);

  useEffect(() => {
    redrawImage();
  }, [mobile, tablet, sidewaysTablet, smallDesktop, moderateDesktop, mediumDesktop, sufficientDesktop, redrawImage]);

  const handleImageInteraction = (ref: ReactZoomPanPinchRef) => {
    setScale(ref.state.scale);
  };

  return (
    <div className="pixelated-container">
      {loading && <CircleLoading color="#36284C" />}
      <TransformWrapper
        onPanningStop={handleImageInteraction}
        onPinchingStop={handleImageInteraction}
        onWheelStop={handleImageInteraction}
        onZoomStop={handleImageInteraction}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <ImageContainer>
            <TransformComponent>
              <canvas
                id="smaller-canvas"
                className={`${loading ? 'hidden' : ''} sharp-canvas`}
                width={width}
                height={height}
              />
            </TransformComponent>
            {!loading && (
              <ControlsContainer>
                <Control
                  src={zoomInIcon}
                  alt="zoom in"
                  height="25"
                  width="25"
                  className="clickable"
                  onClick={() => zoomIn()}
                />
                <Control
                  src={zoomOutIcon}
                  alt="zoom out"
                  height="25"
                  width="25"
                  className="clickable"
                  onClick={() => zoomOut()}
                />
                <Control
                  src={zoomReset}
                  alt="reset zoom and transform"
                  height="25"
                  width="25"
                  className="clickable"
                  onClick={() => resetTransform()}
                />
              </ControlsContainer>
            )}
          </ImageContainer>
        )}
      </TransformWrapper>
    </div>
  );
};
