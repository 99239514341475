import React, { useState } from 'react';

import { ImageCarousel, RadioButton, RoomTester } from '../../../components';
import { SizeCode } from '../../../../../common/types';

import {
  galleryBackgrounds as backgrounds,
  galleryImages as images,
} from './galleryImages';

export type Props = {
  currImageIndex: number;
  shape: string;
  selectImage: (index: number) => void;
};

export const GalleryWithControls = ({
  currImageIndex,
  shape,
  selectImage,
}: Props) => {
  const [roomIndex, setRoomIndex] = useState(0);
  const currRoom = backgrounds[roomIndex];

  return (
    <>
      <div className="gallery-display">
        <RoomTester
          room={currRoom}
          source={images[currImageIndex].src}
          size={(shape + 'L') as SizeCode}
          wide
        />
      </div>
      <div className="gallery-controls-container">
        <div className="gallery-controls">
          <div className="gallery-carousel-container">
            <ImageCarousel images={images} select={selectImage} />
          </div>
          <div className="gallery-options gallery-options-header">
            <h5 className="white">Change Room:</h5>
          </div>
          <div className="gallery-options gallery-options-radio">
            <RadioButton
              tiny
              label="Bedroom"
              selected={roomIndex === 0}
              select={() => setRoomIndex(0)}
            />
            <RadioButton
              tiny
              label="Reading Room"
              selected={roomIndex === 1}
              select={() => setRoomIndex(1)}
            />
            <RadioButton
              tiny
              label="Dining Room"
              selected={roomIndex === 2}
              select={() => setRoomIndex(2)}
            />
            <RadioButton
              tiny
              label="Living Room"
              selected={roomIndex === 3}
              select={() => setRoomIndex(3)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
