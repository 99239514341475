import { getCurrentUser } from '../../local-storage';

import { sendErrorEmail, verifyUserToken } from '../endpoints';

export const verifyColorCounts = async (
  colorCounts: number[],
  horBlocks: number,
  vertBlocks: number,
  originalImage: string,
  pixelatedImage: string
) => {
  let colorSum = 0;
  colorCounts.forEach((color) => (colorSum += color));

  const totalPixels = horBlocks * vertBlocks * 1024;

  if (totalPixels !== colorSum) {
    const token = getCurrentUser();
    const res = await verifyUserToken({ token: token });
    const email = res && res.data.user ? res.data.user._id : undefined;
    sendErrorEmail({
      email: email,
      originalImage: originalImage,
      pixelatedImage: pixelatedImage,
    });
  }
};
