import React from 'react';

import { Checkbox } from '../../../components';
import { parsePrettyDate } from '../../../helpers';
import { UserHydratedOrder } from '../../../types';
import {
  changeOrderCompletionStatus,
  setTrackingNumber,
  sendTrackingEmail,
} from '../../../api';

import { BrixPixSection, ShippingInfo, TrackingEditor } from '.';

export type Props = {
  order: UserHydratedOrder;
  tablet: boolean;
};

export const OrderRecord = ({ order, tablet }: Props) => {
  const { firstName, lastName, email, phone, orderNumber: orderNum, price, shippingPrice } = order;

  const { date, month, year } = parsePrettyDate(order.date);
  const completed = order && order.completed ? true : false;

  // edits whether order is completed or not
  const handleComplete = () => {
    changeOrderCompletionStatus(email, order.index, !completed);
  };

  const handleTracking = (suborderIndex: number, trackingNum: string) => {
    sendTrackingEmail({
      firstName: firstName,
      email: email,
      order: order,
      trackingNumber: trackingNum,
    });
    setTrackingNumber(email, order.index, suborderIndex, trackingNum);
  };

  return (
    <>
      <div className="flex-cell left-align-col vertical-padded-cell horizontal-padded-cell">
        <p className="purple">
          {date} {month}, {year}
        </p>
      </div>
      <div className="flex-cell center-align-col vertical-padded-cell">
        <p className="purple">{orderNum}</p>
      </div>
      {!tablet && (
        <div className="flex-cell center-align-col vertical-padded-cell">
          {order &&
            order.suborders.map((suborder, index) => (
              <TrackingEditor
                key={index}
                index={index}
                trackingNum={suborder.trackingNumber}
                track={handleTracking}
              />
            ))}
        </div>
      )}
      <div className="flex-cell left-align-col vertical-padded-cell horizontal-padded-cell">
        <p className="purple">
          {firstName} {lastName}
        </p>
        <a href={`mailto:${email}`}>{email}</a>
        <a href={`tel:${phone}`}>{phone}</a>
      </div>
      {!tablet && (
        <div className="flex-cell left-align-col vertical-padded-cell horizontal-padded-cell">
          {order &&
            order.suborders.map((suborder, index) => (
              <ShippingInfo suborder={suborder} key={index} />
            ))}
        </div>
      )}
      {!tablet && (
        <div className="flex-cell center-align-col vertical-padded-cell">
          {order &&
            order.suborders.map((suborder, index) => (
              <BrixPixSection suborder={suborder} key={index} />
            ))}
        </div>
      )}
      {!tablet && (
        <div className="flex-cell left-align-col vertical-padded-cell horizontal-padded-cell">
          <p className="purple">${price}</p>
        </div>
      )}
      {!tablet && (
        <div className="flex-cell left-align-col vertical-padded-cell horizontal-padded-cell">
          <p className="purple">${shippingPrice}</p>
        </div>
      )}
      <div className="flex-cell center-align-col vertical-padded-cell">
        <Checkbox checked={completed} check={handleComplete} />
      </div>
    </>
  );
};
