import React, { useState, useEffect } from 'react';

import { ClassicButton } from '../../components';
import { isMobile } from '../../helpers';
import { getItemsInCart, updateOrders } from '../../api';
import { CartItem as CartItemType } from '../../types';

import { CartItem, ContinueShoppingSection } from './components';
import './ShoppingCart.css';
import useGAEventsTracker from '../../hooks/useGAEventsTracker';
import Analytics from 'analytics'
import { AnalyticsProvider } from 'use-analytics'
import googleAnalyticsPlugin from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'BrixPix dev',
  plugins: [
    googleAnalyticsPlugin({
      trackingId: 'UA-190448776-2',
    })]
})

/*
 * ShoppingCart
 * Displayed at /cart
 * Shopping Cart page displays current items in cart, quantities, and subtotal
 */

export const ShoppingCart = () => {
  //const GAEventsTracker = useGAEventsTracker("Checkout Step");
  const [items, setItems] = useState<CartItemType[] | null>(null); // array of items in cart
  const [totalPrice, setTotalPrice] = useState<string>('0'); // total price of all items
  const [mobile, setMobile] = useState<boolean>(isMobile());

  /* on mount, retrieves items in cart from local storage */
  useEffect(() => {
    getOrderInfo();
  }, []);

  /* checks for window size on mount */
  useEffect(() => {
    const onResize = () => {
      setMobile(isMobile());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  /*
   * deleteItem
   * deletes item at specific index and updates items appropriately
   * called when clicks delete or quantity = 0
   */
  const deleteItem = (index: number) => {
    //GAEventsTracker("Deleted Item");
    const tempItems = items;
    tempItems?.splice(index, 1);
    editOrderList(tempItems);
  };

  /*
   * editOrderList
   * update local storage and then update page
   * called when item removed or quantity updated
   */
  const editOrderList = (items: CartItemType[] | null) => {
    if (items != null) {
      updateOrders(items);
      getOrderInfo();
    }
  };

  /*
   * getOrderInfo
   * retrieve items from local storage and recalculate subtotal
   * called whenever local storage updates
   */
  const getOrderInfo = async () => {
    const tempItems = await getItemsInCart();
    setItems(tempItems);

    let sum = 0;
    tempItems &&
      tempItems.forEach((item) => {
        const quantity = item.quantity;
        sum += item.price * quantity;
      });
    setTotalPrice(sum.toFixed(2));
  };

  // causes updates to stored cart data
  const getOrderUpdates = (index: number, quantity: string) => {
    let tempItems = items;
    if (tempItems == null) {
      return;
    }
    tempItems[index].quantity = parseInt(quantity);
    editOrderList(tempItems);
  };

  /*
   * toPreCheckout
   * moves onto checkout step
   * note: redirects to /checkout
   */
  const toPreCheckout = () => {
    //Tracker("Checkout Button Pressed");
    if (items == null) {
      return;
    }
    updateOrders(items, '/checkout');
  };

  // initalizes item components for every item in cart
  const itemComponents = items
    ? items.map((item, index) => (
      <CartItem
        data={item}
        key={index}
        index={index}
        deleteItem={deleteItem}
        update={getOrderUpdates}
      />
    ))
    : null;

  const emptyCart = !items || (Array.isArray(items) && !items.length);

  return (
    <div className="cart-page page">
      <div className="shopping-cart">
        <h2 className="eggplant">Review your order</h2>
        <div className="cart-item-section">
          {/* header of item table */}
          <div className="cart-heading-container">
            <div className="cart-heading">
              <>
                <div className="underline" />
                <div className="underline left-align-col">
                  <p className="large-text">Brix Size</p>
                </div>
                <div className="underline">
                  <p className="large-text">Quantity</p>
                </div>
                <div className="underline center-align-col">
                  <p className="large-text">Amount</p>
                </div>
              </>
            </div>
          </div>
          {/* table of items */}
          <div className="cart-grid-container custom-scroll">
            <div className="cart-grid">{itemComponents}</div>
          </div>
          {/* total */}
          {!emptyCart && (
            <div className="cart-total-grid-container">
              <div className="cart-total-grid">
                <div className="flex-cell right-align-col">
                  <p className="purple large-text">Total:</p>
                </div>
                <div>
                  <p className="purple large-text">${totalPrice}</p>
                </div>
              </div>
            </div>
          )}
          {/* empty cart message */}
          {emptyCart && (
            <div className="empty-cart-message">
              <p className="large-text purple">
                Looks like you don't have anything in your cart yet. Let's
                change that!
              </p>
              <ClassicButton
                value="Start Order"
                color="blue"
                action={() => {
                  analytics.track("Start Order Pressed", {
                    category: "Order Page"
                  });
                  (window.location.href = '/order')
                }
                }
              />
            </div>
          )}
        </div>
      </div>
      <ContinueShoppingSection
        toCheckout={toPreCheckout}
        sum={totalPrice}
        mobile={mobile}
      />
    </div>
  );
};
