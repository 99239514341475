import React, { useState } from 'react';

import { LoginCheckoutBox, SignupCheckoutBox } from '.';

export const PreCheckout = () => {
  const [currBox, setCurrBox] = useState<'login' | 'signup'>('login');

  return (
    <div className="pre-checkout">
      <h2 className="eggplant">Checkout</h2>
      <div className="pre-checkout-options">
        {currBox === 'login' ? (
          <LoginCheckoutBox signup={() => setCurrBox('signup')} />
        ) : (
          <SignupCheckoutBox login={() => setCurrBox('login')} />
        )}
      </div>
    </div>
  );
};
