import React from 'react';

import {
  greyArrowRight,
  greyArrowLeft,
  purpleArrowRight,
  purpleArrowLeft,
} from '../media';

import './ImageSelector.css';

export type Props = {
  image: string;
  first: boolean;
  last: boolean;
  nextImage: () => void;
  prevImage: () => void;
};

export const ImageSelector = ({
  image,
  first,
  last,
  nextImage,
  prevImage,
}: Props) => {
  return (
    <div className="image-selector">
      <img
        src={first ? greyArrowLeft : purpleArrowLeft}
        className={`image-selector-arrow ${first ? '' : 'clickable'}`}
        onClick={first ? undefined : prevImage}
        alt="left arrow"
      />
      <img src={image} className="image-selector-image" alt="test" />
      <img
        src={last ? greyArrowRight : purpleArrowRight}
        className={`image-selector-arrow ${last ? '' : 'clickable'}`}
        onClick={last ? undefined : nextImage}
        alt="right arrow"
      />
    </div>
  );
};
