import { unpunctuatePhone } from '../../helpers';
import { setCurrentUser, removeCurrentUser } from '../../local-storage';

import { createUser, loginUser } from '../endpoints';
import { getItemsInCart, clearOrders } from './orders';
import { updateCart } from './userUpdates';

/*
 * register
 * sends new user data to database to create user
 * note: assumes data already validated, moves items from local
 *       storage cart to database if there were any
 */
export const register = async (
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  password: string,
  redirect: string = '/account'
) => {
  let user = {
    _id: email,
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: parseInt(unpunctuatePhone(phone, phone)!),
    favAddress: 0,
    address: [],
    password: password,
    orders: [],
  };

  const items = await getItemsInCart();
  clearOrders();
  const res = await createUser(user);

  if (res) {
    const token = res.data.token;
    setCurrentUser(token);

    if (items && Array.isArray(items)) {
      await updateCart(email, items, '/checkout');
    }

    if (redirect !== null) {
      window.location.href = redirect;
    }
  }
};

/*
 * login
 * sends user data to database to login user
 * note: assumes data already validated and username matches password, moves
 *       items from local storage cart to database if there were any
 */
export const login = async (
  email: string,
  password: string,
  redirect: string = '/account'
) => {
  const user = {
    email: email,
    password: password,
  };

  const items = await getItemsInCart();
  clearOrders();
  const res = await loginUser(user);

  if (res) {
    const token = res.data.token;
    setCurrentUser(token);

    if (items && Array.isArray(items)) {
      await updateCart(email, items, '/checkout');
    }

    if (redirect !== null) {
      window.location.href = redirect;
    }
  }
};

/*
 * logout
 * logs out currently logged in user
 */
export const logout = () => {
  removeCurrentUser();
};
