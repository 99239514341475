/*
 * averageArr
 * averages all the values in an array
 */
export const averageArr = (arr: number[]): number => {
  let sum = 0;

  for (let i = 0; i < arr.length; i++) {
    sum += arr[i];
  }

  return Math.floor(sum / arr.length);
};

/*
 * findMinIndex
 * finds index of the smallest value in an array
 */
export const findMinIndex = (arr: number[]): number => {
  let minIndex = 0;
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] < arr[minIndex]) {
      minIndex = i;
    }
  }

  return minIndex;
};
