import React from 'react';

import { ErrorMessage } from './ErrorMessage';

/*
 * TextInput
 * Displays input field, its label, and its error message (if applicable)
 */

export type Props = {
  name: string;
  value: string;
  setter: (value: string) => void;
  message: string;
  messageSetter: (value: string) => void;
  change: (
    setter: (value: string) => void,
    messageSetter: (message: string) => void,
    value: string
  ) => void;
  password?: boolean;
  placeholder?: string;
  noLabel?: boolean;
  readOnly?: boolean;
  short?: boolean;
};

export const TextInput = ({
  name,
  value,
  setter,
  message,
  messageSetter,
  change,
  password,
  placeholder,
  noLabel,
  readOnly,
  short,
}: Props) => {
  return (
    <div className="text-input-container">
      {/* label */}
      {!noLabel && <label className="align-left">{name}</label>}
      {/* text input */}
      <input
        type={password ? 'password' : 'text'}
        value={value}
        onChange={(e) => change(setter, messageSetter, e.target.value)}
        name={name}
        className="text-input"
        placeholder={placeholder}
        readOnly={readOnly}
      />
      {/* focus effect */}
      <span
        className={`text-input-focus-border ${
          noLabel ? 'no-label-focus' : ''
        } ${short ? 'short-focus' : ''}`}
      ></span>
      {/* error */}
      <ErrorMessage message={message} />
    </div>
  );
};
