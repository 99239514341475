import React from 'react';

import { ClassicButton } from '../../components';
import { alertPurple as alert } from '../../media';

import './ResetError.css';

export const ResetError = () => {
  return (
    <div className="page reset-error pixelated-background">
      <div className="reset-error-container">
        <div>
          <img src={alert} className="big-icon" alt="alert exclamation point" />
        </div>
        <div className="reset-error-text">
          <h2 className="purple reset-error-title">
            Looks like there was an error with your reset link!
          </h2>
          <br />
          <p className="purple">
            Chances are, it's expired. If you still want to reset your password,{' '}
            <b>
              <a href="/forgot-password">try again</a>
            </b>
            , and make sure to click the link within an hour!
          </p>
          <div className="reset-error-button-container">
            <ClassicButton
              action={() => (window.location.href = '/forgot-password')}
              color="blue"
              value="Reset Password"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
