import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';

import { Coupon } from '../../../../common/types';
import { CartItem } from '../../types';

import { CheckoutForm, promise } from './stripe';

export type Props = {
  expanded: boolean;
  startable: boolean;
  expandSelf: (stepNum: number) => void;
  finishCheckout: () => void;
  items: CartItem[];
  shippingIndex: number | null;
  zip: string;
  username: string | null;
  orderNum: string;
  stepNum: number;
  coupon: Coupon | null;
};

export const PaymentSection = ({
  expanded,
  startable,
  expandSelf,
  finishCheckout,
  items,
  shippingIndex,
  zip,
  username,
  orderNum,
  stepNum,
  coupon,
}: Props) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (expanded) {
      setReady(true);
    }
  }, [expanded]);

  return (
    <div>
      <p
        className={`purple large-text underline ${
          startable ? 'clickable' : ''
        }`}
        onClick={() =>
          startable ? expandSelf(stepNum === 3 ? -1 : 3) : undefined
        }
      >
        Step 3: Payment Method
      </p>
      <br />
      <div className={expanded ? 'visible-slider' : 'hidden-slider'}>
        <div className="payment-section">
          <p className="purple">
            Enter your credit or debit information below:{' '}
          </p>
          <Elements stripe={promise}>
            <CheckoutForm
              finishCheckout={finishCheckout}
              ready={ready}
              items={items}
              shippingIndex={shippingIndex}
              zip={zip}
              focused={expanded}
              username={username}
              orderNum={orderNum}
              coupon={coupon}
            />
          </Elements>
        </div>
      </div>
    </div>
  );
};
