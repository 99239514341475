import React, { useState, useEffect } from 'react';

import {
  Address,
  Coupon,
  ShippingOption,
  User,
} from '../../../../common/types';
import {
  completeOrder,
  getItemsInCart,
  getShippingOptions,
  getCouponById,
  getTax,
  getDiscount,
  //setServiceCode,
} from '../../api';
import { padNum, getRandomNum } from '../../helpers';
import { CartItem } from '../../types';

import { CheckoutSidebar } from './components';
import { PaymentSection } from './PaymentSection';
import { ReviewSection } from './ReviewSection';
import { ShippingSection } from './ShippingSection';
import './Checkout.css';

import Analytics from 'analytics'
import googleAnalyticsPlugin from '@analytics/google-analytics'


const analytics = Analytics({
  app: 'BrixPix dev',
  plugins: [googleAnalyticsPlugin({
    trackingId: 'UA-190448776-2',
  })]
})

analytics.page({
  url: 'https://brixpix.herokuapp.com/checkout'
})

function parseXmlToJson(xml) {
  const json = {};
  for (const res of xml.matchAll(/(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm)) {
    const key = res[1] || res[3];
    const value = res[2] && parseXmlToJson(res[2]);
    json[key] = ((value && Object.keys(value).length) ? value : res[2]) || null;

  }
  return json;
}

export type Props = {
  user: User | null;
};

export const Checkout = ({ user }: Props) => {

  //const { track } = useAnalytics();
  // which step is allowed to be reached (latest step so far) of checkout process, 1-3
  const [stepNum, setStepNum] = useState<number>(1);

  // currently selected step of process, 1-3
  const [currStepNum, setCurrStepNum] = useState<number>(1);

  const [items, setItems] = useState<CartItem[]>([]); // items currently in user's cart

  const [address, setAddress] = useState<Address | null>(null);

  const [gift, setGift] = useState(false); // bool if this order is a gift or not
  const [shippingOptions, setShippingOptions] = useState<
    ShippingOption[] | null
  >(null);

  // index 0-2 of shipping option out of above
  const [shippingIndex, setShippingIndex] = useState<number | null>(null);
  const [zip, setZip] = useState<string>(''); // 5-digit zip code
  const [orderNum, setOrderNum] = useState<string>(''); // 8-digit order number
  const [coupon, setCoupon] = useState<Coupon | null>(null);

  const [numItems, setNumItems] = useState<number>(0); // counts how many items in order
  const [subtotal, setSubtotal] = useState<number>(0); // subtotal of item prices
  const [shippingPrice, setShippingPrice] = useState<number>(0); // price of selected shipping
  const [tax, setTax] = useState<number>(0); // price of tax on items
  const [discount, setDiscount] = useState<number>(0); // price (positive, needs to be
  // subtracted) of coupon

  useEffect(() => {
    getOrderInfo();

    const orderNumber = padNum(getRandomNum(100000000), 8);
    setOrderNum(orderNumber);
  }, []);

  /*
   * initializes tax and shipping prices based on subtotal and items
   * called whenever subtotal updates
   */
  useEffect(() => {
    const initialize = async () => {
      const res = await getTax({ subtotal: subtotal });
      setTax(res.data.tax);

      if (zip) {
        updateShippingAddress(zip);
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtotal]);

  /*
   * initalizes discount based on active coupon if there is one
   * called whenever coupon updates
   */
  useEffect(() => {
    if (coupon == null) {
      return;
    }
    const calcDiscount = async () => {
      const res = await getDiscount({
        subtotal,
        coupon,
      });
      setDiscount(res.data.discount);
    };

    calcDiscount();
  }, [coupon, subtotal]);

  /*
   * getOrderInfo
   * initalizes items based on contents of cart
   */
  const getOrderInfo = () => {
    const initializeItems = async () => {
      const tempItems = await getItemsInCart();
      setItems(tempItems!);

      // calculates subtotal
      var tempSum = 0;
      tempItems &&
        tempItems.forEach((item) => (tempSum += item.price * item.quantity));
      setSubtotal(tempSum);

      var tempNumItems = 0;
      tempItems && tempItems.forEach((item) => (tempNumItems += item.quantity));
      setNumItems(tempNumItems);
    };

    initializeItems();
  };

  /*
   * changeStep
   * moves onto next step in checkout process
   */
  const changeStep = (nextIndex: number) => {
    if (nextIndex > stepNum) {
      setStepNum(nextIndex);
      setCurrStepNum(nextIndex);
    }
  };

  /*
   * updateShippingAddress
   * update current shipping options based on zip and items
   */
  const updateShippingAddress = async (zip: string) => {
    setZip(zip);
    analytics.page();
    analytics.track('Checkout Button Pressed', {
      category: "Checkout Step"
    })

    getShippingOptions({
      items,
      zip,
    })
      .then((res: any) => {
        setShippingOptions(res.data.options);
      })
      .catch((err: any) => console.log(err));
  };

  //   //END OG CODE...

  //   let optionsArr = [] as any;
  //   let trueOptionsArr = [] as any;
  //   let name1, name2, name3, time1, time2, time3, cost1, cost2, cost3;

  //   let tempcost1 = 0, tempcost2 = 0, tempcost3 = 0;

  //   let trueLength = items.length * 3;

  //   for (let j = 0; j < items.length; j++) {
  //     getShippingOptions(
  //       "03",
  //       items,
  //       zip,
  //       j
  //     )
  //       .then((res: any) => {
  //         // res.setHeader("Access-Control-Allow-Origin", "https://brixpix.herokuapp.com");
  //         console.log(res);
  //         for (let i = 0; i < items.length; i++) {
  //           console.log(items[i].quantity);
  //         }

  //         const myJSON = parseXmlToJson(res.data);
  //         tempcost1 += items[j].quantity * parseFloat((myJSON["RatingServiceSelectionResponse"]["TotalCharges"]["MonetaryValue"]).toString());


  //         console.log("1: " + tempcost1);

  //         optionsArr.push({ name: "Ground Shipping", time: "2 - 8 days", cost: tempcost1 });
  //         console.log("Length: " + optionsArr.length);

  //         if (optionsArr.length === 3) {
  //           setShippingOptions(optionsArr);
  //         }
  //         let correctGroundShipping = -1;
  //         let highestGroundShipping;
  //         let correctPriorityShipping = -1;
  //         let highestPriorityShipping;
  //         let correctPriorityExpress = -1;
  //         let highestPriorityExpress;
  //         if (optionsArr.length === trueLength) {
  //           for (let i = 0; i < optionsArr.length; i++) {
  //             if (optionsArr[i].name === "Ground Shipping") {
  //               if (correctGroundShipping < 0) {
  //                 correctGroundShipping = i;
  //                 highestGroundShipping = optionsArr[i].cost;
  //               }
  //               else {
  //                 if (optionsArr[i].cost > highestGroundShipping) {
  //                   correctGroundShipping = i;
  //                   highestGroundShipping = optionsArr[i].cost;
  //                 }
  //               }
  //             }
  //             if (optionsArr[i].name === "Priority Shipping") {
  //               if (correctPriorityShipping < 0) {
  //                 correctPriorityShipping = i;
  //                 highestPriorityShipping = optionsArr[i].cost;
  //               }
  //               else {
  //                 if (optionsArr[i].cost > highestPriorityShipping) {
  //                   correctPriorityShipping = i;
  //                   highestPriorityShipping = optionsArr[i].cost;
  //                 }
  //               }
  //             }
  //             if (optionsArr[i].name === "Priority Express") {
  //               if (correctPriorityExpress < 0) {
  //                 correctPriorityExpress = i;
  //                 highestPriorityExpress = optionsArr[i].cost;
  //               }
  //               else {
  //                 if (optionsArr[i].cost > highestPriorityExpress) {
  //                   correctPriorityExpress = i;
  //                   highestPriorityExpress = optionsArr[i].cost;
  //                 }
  //               }
  //             }
  //           }
  //           trueOptionsArr.push(optionsArr[correctGroundShipping]);
  //           trueOptionsArr.push(optionsArr[correctPriorityShipping]);
  //           trueOptionsArr.push(optionsArr[correctPriorityExpress]);
  //           console.log("done!!!");
  //           setShippingOptions(trueOptionsArr);
  //         }
  //       })
  //       .catch((err: any) => console.log(err));

  //     getShippingOptions(
  //       "02",
  //       items,
  //       zip,
  //       j
  //     )
  //       .then((res: any) => {
  //         // res.setHeader("Access-Control-Allow-Origin", "https://brixpix.herokuapp.com");
  //         for (let i = 0; i < items.length; i++) {
  //           console.log(items[i].shape);
  //           console.log(items[i].size);
  //         }

  //         const myJSON = parseXmlToJson(res.data);
  //         tempcost2 += items[j].quantity * parseFloat((myJSON["RatingServiceSelectionResponse"]["TotalCharges"]["MonetaryValue"]).toString());

  //         console.log("2: " + tempcost2);

  //         optionsArr.push({ name: "Priority Shipping", time: "1 - 3 days", cost: tempcost2 });
  //         console.log("Length: " + optionsArr.length);

  //         if (optionsArr.length === 3) {
  //           setShippingOptions(optionsArr);
  //         }
  //         let correctGroundShipping = -1;
  //         let highestGroundShipping;
  //         let correctPriorityShipping = -1;
  //         let highestPriorityShipping;
  //         let correctPriorityExpress = -1;
  //         let highestPriorityExpress;
  //         if (optionsArr.length === trueLength) {
  //           for (let i = 0; i < optionsArr.length; i++) {
  //             if (optionsArr[i].name === "Ground Shipping") {
  //               if (correctGroundShipping < 0) {
  //                 correctGroundShipping = i;
  //                 highestGroundShipping = optionsArr[i].cost;
  //               }
  //               else {
  //                 if (optionsArr[i].cost > highestGroundShipping) {
  //                   correctGroundShipping = i;
  //                   highestGroundShipping = optionsArr[i].cost;
  //                 }
  //               }
  //             }
  //             if (optionsArr[i].name === "Priority Shipping") {
  //               if (correctPriorityShipping < 0) {
  //                 correctPriorityShipping = i;
  //                 highestPriorityShipping = optionsArr[i].cost;
  //               }
  //               else {
  //                 if (optionsArr[i].cost > highestPriorityShipping) {
  //                   correctPriorityShipping = i;
  //                   highestPriorityShipping = optionsArr[i].cost;
  //                 }
  //               }
  //             }
  //             if (optionsArr[i].name === "Priority Express") {
  //               if (correctPriorityExpress < 0) {
  //                 correctPriorityExpress = i;
  //                 highestPriorityExpress = optionsArr[i].cost;
  //               }
  //               else {
  //                 if (optionsArr[i].cost > highestPriorityExpress) {
  //                   correctPriorityExpress = i;
  //                   highestPriorityExpress = optionsArr[i].cost;
  //                 }
  //               }
  //             }
  //           }
  //           trueOptionsArr.push(optionsArr[correctGroundShipping]);
  //           trueOptionsArr.push(optionsArr[correctPriorityShipping]);
  //           trueOptionsArr.push(optionsArr[correctPriorityExpress]);
  //           console.log("done!!!");
  //           setShippingOptions(trueOptionsArr);
  //         }
  //       })
  //       .catch((err: any) => console.log(err));

  //     getShippingOptions(
  //       "01",
  //       items,
  //       zip,
  //       j
  //     )
  //       .then((res: any) => {
  //         //res.setHeader("Access-Control-Allow-Origin", "https://brixpix.herokuapp.com");
  //         for (let i = 0; i < items.length; i++) {
  //           console.log(items[i].shape);
  //           console.log(items[i].size);
  //         }

  //         const myJSON = parseXmlToJson(res.data);
  //         tempcost3 += items[j].quantity * parseFloat((myJSON["RatingServiceSelectionResponse"]["TotalCharges"]["MonetaryValue"]).toString());


  //         console.log("3: " + tempcost3);

  //         optionsArr.push({ name: "Priority Express", time: "1 - 2 days", cost: tempcost3 });
  //         console.log("Length: " + optionsArr.length);

  //         if (optionsArr.length === 3) {
  //           setShippingOptions(optionsArr);
  //         }
  //         console.log("Length when done: " + optionsArr.length);
  //         let correctGroundShipping = -1;
  //         let highestGroundShipping;
  //         let correctPriorityShipping = -1;
  //         let highestPriorityShipping;
  //         let correctPriorityExpress = -1;
  //         let highestPriorityExpress;
  //         if (optionsArr.length === trueLength) {
  //           for (let i = 0; i < optionsArr.length; i++) {
  //             if (optionsArr[i].name === "Ground Shipping") {
  //               if (correctGroundShipping < 0) {
  //                 correctGroundShipping = i;
  //                 highestGroundShipping = optionsArr[i].cost;
  //               }
  //               else {
  //                 if (optionsArr[i].cost > highestGroundShipping) {
  //                   correctGroundShipping = i;
  //                   highestGroundShipping = optionsArr[i].cost;
  //                 }
  //               }
  //             }
  //             if (optionsArr[i].name === "Priority Shipping") {
  //               if (correctPriorityShipping < 0) {
  //                 correctPriorityShipping = i;
  //                 highestPriorityShipping = optionsArr[i].cost;
  //               }
  //               else {
  //                 if (optionsArr[i].cost > highestPriorityShipping) {
  //                   correctPriorityShipping = i;
  //                   highestPriorityShipping = optionsArr[i].cost;
  //                 }
  //               }
  //             }
  //             if (optionsArr[i].name === "Priority Express") {
  //               if (correctPriorityExpress < 0) {
  //                 correctPriorityExpress = i;
  //                 highestPriorityExpress = optionsArr[i].cost;
  //               }
  //               else {
  //                 if (optionsArr[i].cost > highestPriorityExpress) {
  //                   correctPriorityExpress = i;
  //                   highestPriorityExpress = optionsArr[i].cost;
  //                 }
  //               }
  //             }
  //           }
  //           trueOptionsArr.push(optionsArr[correctGroundShipping]);
  //           trueOptionsArr.push(optionsArr[correctPriorityShipping]);
  //           trueOptionsArr.push(optionsArr[correctPriorityExpress]);
  //           console.log("done!!!");
  //           setShippingOptions(trueOptionsArr);
  //         }
  //       })
  //       .catch((err: any) => console.log(err));
  //   }
  // };


  const updateShippingPrice = (index: number) => {
    if (shippingOptions) {
      setShippingIndex(index);
      setShippingPrice(shippingOptions ? shippingOptions[index].cost : 0);
    }
  };

  const getReviewInfo = (address: Address, gift: boolean, shipping: number) => {
    setAddress(address);
    setGift(gift);
    setShippingIndex(shipping);

    changeStep(3);
  };

  const applyCoupon = (code: string) => {
    getCouponById(code)
      .then((res: any) => setCoupon(res.data.data))
      .catch((err: any) => console.log(err));
  };

  const finishCheckout = async () => {
    if (user == null || address == null || shippingIndex == null) {
      return;
    }
    // TODO:  make address an array if there's more than one...and more than one order num
    completeOrder(
      user,
      items,
      subtotal,
      shippingPrice,
      tax,
      discount,
      address,
      gift,
      shippingIndex,
      parseInt(orderNum)
    );
    setCurrStepNum(4);
    setStepNum(4);
  };

  return (
    <div className="checkout">
      <div className="checkout-content">
        <h1>Checkout</h1>
        <br />
        <div className="checkout-inner">
          <ShippingSection
            user={user}
            expanded={stepNum === 1}
            changeStep={changeStep}
            expandSelf={setStepNum}
            stepNum={stepNum}
          />
          <ReviewSection
            user={user}
            expanded={stepNum === 2}
            changeStep={getReviewInfo}
            stepNum={stepNum}
            expandSelf={setStepNum}
            startable={currStepNum >= 2}
            updateAddress={updateShippingAddress}
            updateShipping={updateShippingPrice}
            shippingOptions={shippingOptions}
          />
          <PaymentSection
            expanded={stepNum === 3}
            expandSelf={setStepNum}
            stepNum={stepNum}
            startable={currStepNum >= 2}
            finishCheckout={finishCheckout}
            items={items}
            shippingIndex={shippingIndex}
            zip={zip}
            username={user?.email ?? ''}
            orderNum={orderNum}
            coupon={coupon}
          />
          {currStepNum === 4 && (
            <div className="checkout-success-section">
              <h4 className="blue">Your order has been placed!</h4>
              <p className="purple">
                You'll receive an email confirmation for Order {orderNum}{' '}
                shortly.
              </p>
            </div>
          )}
        </div>
      </div>
      <CheckoutSidebar
        subtotal={subtotal}
        shippingPrice={shippingPrice}
        numItems={numItems}
        applyCoupon={applyCoupon}
        tax={tax}
        discount={discount}
      />
    </div>
  );
};
