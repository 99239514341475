import React, { useState } from 'react';
import ReactSlider from 'react-slider';

import {
  frameLandscapeLarge,
  framePortraitLarge,
  frameSquare,
  sliderHandle,
} from '../media';

import './SlidingImage.css';

/*
 * SlidingImage
 * Displays interactive, overlapping original and pixelated images
 */

export type Props = {
  original: string;
  pixelated: string;
  orientation: 'portrait' | 'landscape' | 'square';
  size?: 'small' | 'extra-small' | 'extra-extra-small'; // for square only
};

export const SlidingImage = ({
  original,
  pixelated,
  orientation,
  size,
}: Props) => {
  const [percentLeft, setPercentLeft] = useState<number>(50); // position of slider, 0-100

  // calculates size and shape
  let totalWidth;
  let sizeClass = '';
  if (orientation === 'portrait') {
    if (size === 'extra-small') {
      totalWidth = 9;
      sizeClass = 'extra-small-slider';
    } else if (size === 'small') {
      totalWidth = 12;
      sizeClass = 'small-slider';
    } else {
      totalWidth = 15;
    }
  } else if (orientation === 'landscape') {
    if (size === 'extra-small') {
      totalWidth = 12;
      sizeClass = 'extra-small-slider';
    } else if (size === 'small') {
      totalWidth = 16;
      sizeClass = 'small-slider';
    } else {
      totalWidth = 20;
    }
  } else {
    if (size === 'extra-extra-small') {
      totalWidth = 9;
      sizeClass = 'extra-extra-small-slider';
    } else if (size === 'extra-small') {
      totalWidth = 12;
      sizeClass = 'extra-small-slider';
    } else if (size === 'small') {
      totalWidth = 16;
      sizeClass = 'small-slider';
    } else {
      totalWidth = 20;
    }
  }

  // dictates style of where handle and slider are based on slide
  const originalStyle = { width: (totalWidth * percentLeft) / 100 + 'em' };
  const handleStyle = {
    transform: `translateX(${(totalWidth * (percentLeft - 50)) / 100}em)`,
  };

  // dictates portrait or landscape styling
  const orientationClass = orientation + '-slider';

  // picks appropriate frame
  let frame;
  if (orientation === 'landscape') {
    frame = frameLandscapeLarge;
  } else if (orientation === 'portrait') {
    frame = framePortraitLarge;
  } else {
    frame = frameSquare;
  }

  return (
    <div className={`sliding-image ${orientationClass} ${sizeClass}`}>
      {/* frame */}
      <img
        src={frame}
        className={`sliding-image-frame ${orientationClass} ${sizeClass}`}
        alt="frame"
      />
      {/* original image */}
      <div
        style={originalStyle}
        className={`resizing-wrapper ${orientationClass} ${sizeClass}`}
      >
        <img
          src={original}
          className={`image-under-slider original-slider-image ${orientationClass} ${sizeClass}`}
          alt="non-pixelated"
        />
      </div>
      {/* pixelated image */}
      <img
        src={pixelated}
        className={`image-under-slider pixelated-slider-image ${orientationClass} ${sizeClass}`}
        alt="pixelated"
      />
      {/* vertical slider bar */}
      <div
        className={`image-slider-container ${orientationClass} ${sizeClass}`}
      >
        <ReactSlider
          className="image-slider"
          trackClassName="image-slider-track"
          thumbClassName={`image-slider-thumb ${orientationClass} ${sizeClass}`}
          defaultValue={50}
          value={percentLeft}
          renderThumb={(props, state) => <div {...props}>{state.value}</div>}
          minDistance={10}
          onChange={(value) => setPercentLeft(value)}
          min={0}
          max={100}
        />
        <img
          src={sliderHandle}
          className={`slider-handle ${orientationClass} ${sizeClass}`}
          style={handleStyle}
          alt="slider handle"
        />
      </div>
    </div>
  );
};
