import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { getItemsInCart } from '../api';

export type Props = {
  component: React.ElementType;
  path?: string;
  exact?: boolean;
};

export const ShoppingRoute = ({ component: Component, ...rest }: Props) => {
  const [emptyCart, setEmptyCart] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const checkCartEmpty = async () => {
      const orders = await getItemsInCart();
      if (orders && Array.isArray(orders) && orders.length !== 0) {
        setEmptyCart(false);
      }
      setLoaded(true);
    };

    checkCartEmpty();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        loaded ? (
          emptyCart ? (
            <Redirect to="/cart" />
          ) : (
            <Component {...props} />
          )
        ) : (
          <></>
        )
      }
    />
  );
};
