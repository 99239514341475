import React from 'react';

import { PriceArea } from '../../../components';

import { CouponArea } from '.';

export type Props = {
  subtotal: number;
  shippingPrice: number;
  tax: number;
  numItems: number;
  applyCoupon: (code: string) => void;
  discount: number;
};

export const CheckoutSidebar = ({
  subtotal,
  shippingPrice,
  tax,
  numItems,
  applyCoupon,
  discount,
}: Props) => {
  const total = (subtotal + shippingPrice + tax - discount).toFixed(2);

  return (
    <aside className="checkout-side-section">
      <PriceArea
        numItems={numItems}
        subtotal={subtotal}
        tax={tax}
        shippingPrice={shippingPrice}
        discount={discount}
        total={parseInt(total)}
      />
      <CouponArea applyCoupon={applyCoupon} />
    </aside>
  );
};
