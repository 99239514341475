import React from 'react';

/*
 * ErrorMessage
 * Displays message for problem with input
 */

export type Props = {
  message: string;
};

export const ErrorMessage = ({ message }: Props) => {
  return <div className="error-message">{message}</div>;
};
