import React from 'react';
// @ts-ignore
import { CircleLoading } from 'react-loadingg';

export const BrixilatingMessage = () => {
  return (
    <div className="loading-section">
      <h2 className="purple">One moment while we prepare your order...</h2>
      <br />
      <p className="purple large-text">
        Please don't refresh or leave the page.
      </p>
      <br />
      <br />
      <div>
        <CircleLoading color="#5F4158" />
      </div>
    </div>
  );
};
