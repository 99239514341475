export const isMobile = (): boolean => {
  return window.matchMedia('(max-width: 600px)').matches;
};

export const isTablet = (): boolean => {
  return window.matchMedia('(max-width: 768px)').matches;
};

export const isSidewaysTablet = (): boolean => {
  return window.matchMedia('(max-width: 880px)').matches;
};

export const isSmallDesktop = (): boolean => {
  return window.matchMedia('(max-width: 1000px)').matches;
};

export const isModerateDesktop = (): boolean => {
  return window.matchMedia('(max-width: 1100px)').matches;
};

export const isMediumDesktop = (): boolean => {
  return window.matchMedia('(max-width: 1200px)').matches;
};

export const isSufficientDesktop = (): boolean => {
  return window.matchMedia('(max-width: 1340px)').matches;
};
