import React, { useState, useEffect } from 'react';

import {
  register,
  validateName,
  validateEmail,
  validatePhone,
  validatePasswords,
} from '../../../api';
import { ClassicButton, TextInput } from '../../../components';
import {
  changeField,
  punctuatePhone,
  unpunctuatePhone,
  removePhonePunctuation,
} from '../../../helpers';
import {
  envelope as envelopeIcon,
  phone as phoneIcon,
  eye as eyeIcon,
} from '../../../media';

export type Props = {
  login: () => void;
};

export const SignupCheckoutBox = ({ login }: Props) => {
  // hold state of form fields
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // hold error message (if applicable) for form fields
  const [firstNameMessage, setFirstNameMessage] = useState('');
  const [lastNameMessage, setLastNameMessage] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [phoneMessage, setPhoneMessage] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState('');

  // tracks if phone number currently punctated/needs to be punctuated
  const [punctuated, setPunctuated] = useState(false);

  // tracks visibility of password fields
  const [showPass, setShowPass] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  // punctuates phone every time it's updated
  useEffect(() => {
    if (!punctuated && phone.length >= 4) {
      setPunctuated(true);
      setPhone(punctuatePhone(phone));
    }
  }, [phone, punctuated]);

  // updates phone punctuation and formatting based on user typing
  const typePhone = (value: string) => {
    if (value.length <= 14) {
      setPunctuated(false);

      let tempPhone = unpunctuatePhone(phone, value);
      if (tempPhone !== null) {
        setPhone(tempPhone);
      }
    }
  };

  /*
   * checkForm
   * validates form inputs when user submits
   * displays error messages if errors
   * otherwise creates user
   */
  const checkForm: React.MouseEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();
    var problem = false;

    validateName({ name: firstName, type: 'first' })
      .then((res: any) => {
        problem = problem || res.data.problem;
        setFirstNameMessage(res.data.message);

        validateName({ name: lastName, type: 'last' })
          .then((res: any) => {
            problem = problem || res.data.problem;
            setLastNameMessage(res.data.message);

            validateEmail({ email: email, shouldExist: false })
              .then((res: any) => {
                problem = problem || res.data.problem;
                setEmailMessage(res.data.message);

                validatePhone({ phone: removePhonePunctuation(phone) })
                  .then((res: any) => {
                    problem = problem || res.data.problem;
                    setPhoneMessage(res.data.message);

                    validatePasswords({
                      password: password,
                      confirmation: confirmPassword,
                    })
                      .then((res: any) => {
                        problem = problem || res.data.problem;
                        setPasswordMessage(res.data.message[0]);
                        setConfirmPasswordMessage(res.data.message[1]);

                        if (!problem) {
                          register(
                            email,
                            firstName,
                            lastName,
                            phone,
                            password,
                            undefined
                          );
                        }
                      })
                      .catch((err: any) => console.log(err));
                  })
                  .catch((err: any) => console.log(err));
              })
              .catch((err: any) => console.log(err));
          })
          .catch((err: any) => console.log(err));
      })
      .catch((err: any) => console.log(err));
  };

  return (
    <div className="checkout-option-box">
      <p className="large-text blue underline">Create an account</p>
      <div className="checkout-option-content">
        <p className="large-text dark-grey">
          Your account allows for faster checkout and to view tracking and order
          history.
        </p>
        <form className="user-checkout-form custom-scroll">
          <div className="input-line multi-field">
            <TextInput
              name="First Name"
              value={firstName}
              change={changeField}
              setter={setFirstName}
              messageSetter={setFirstNameMessage}
              message={firstNameMessage}
            />
            <TextInput
              name="Last Name"
              value={lastName}
              change={changeField}
              setter={setLastName}
              messageSetter={setLastNameMessage}
              message={lastNameMessage}
            />
          </div>
          <div className="input-line">
            <TextInput
              name="Email"
              value={email}
              change={changeField}
              setter={setEmail}
              messageSetter={setEmailMessage}
              message={emailMessage}
            />
            <div className="input-icon-container">
              <img className="input-icon" src={envelopeIcon} alt="envelope" />
            </div>
          </div>
          <div className="input-line">
            <TextInput
              name="Phone"
              value={phone}
              change={changeField}
              setter={typePhone}
              messageSetter={setPhoneMessage}
              message={phoneMessage}
            />
            <div className="input-icon-container">
              <img className="input-icon" src={phoneIcon} alt="phone" />
            </div>
          </div>
          <div className="input-line">
            <TextInput
              name="Password"
              value={password}
              change={changeField}
              setter={setPassword}
              messageSetter={setPasswordMessage}
              password={!showPass}
              message={passwordMessage}
            />
            <div className="input-icon-container">
              <img
                className="input-icon clickable"
                src={eyeIcon}
                onClick={() => setShowPass(!showPass)}
                alt="eye"
              />
            </div>
          </div>
          <p className="form-note small blue">
            Your password must be at least 8 characters long and include at
            least one letter, number, and special character.
          </p>
          <div className="input-line">
            <TextInput
              name="Confirm Password"
              value={confirmPassword}
              change={changeField}
              setter={setConfirmPassword}
              messageSetter={setConfirmPasswordMessage}
              password={!showConfirm}
              message={confirmPasswordMessage}
            />
            <div className="input-icon-container">
              <img
                className="input-icon clickable"
                src={eyeIcon}
                onClick={() => setShowConfirm(!showConfirm)}
                alt="eye"
              />
            </div>
          </div>
        </form>
        <div className="checkout-login-note">
          <p className="purple">
            Already have an account?{' '}
            <b className="rose clickable" onClick={login}>
              Sign in here.
            </b>
          </p>
        </div>
        <ClassicButton color="eggplant" value="Checkout" action={checkForm} />
      </div>
    </div>
  );
};
