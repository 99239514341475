import React from 'react';

import './PrivacyPolicy.css';

export const PrivacyPolicy = () => {
  return (
    <div className="page privacy-policy">
      <h2 className="purple">Privacy Policy</h2>
      <br />
      <section>
        <p className="purple">
          Protecting your private information is our priority. This Statement of
          Privacy applies to www.brixpix.com and BrixPix, Inc and governs data
          collection and usage. For the purposes of this Privacy Policy, unless
          otherwise noted, all references to BrixPix, Inc include
          www.brixpix.com and BrixPix. The BrixPix website is a ecommerce site.
          By using the BrixPix website, you consent to the data practices
          described in this statement.{' '}
        </p>
        <br />
      </section>
      <section>
        <h4 className="blue">Collection of Your Personal Information</h4>
        <br />
        <p className="purple">
          In order to better provide you with products and services offered on
          our Site, BrixPix may collect personally identifiable information,
          such as your:{' '}
        </p>
        <br />
        <ul className="privacy-policy-bullets">
          <li>
            <p className="purple">First and Last Name</p>
          </li>
          <li>
            <p className="purple">Mailing Address</p>
          </li>
          <li>
            <p className="purple">E-mail Address</p>
          </li>
          <li>
            <p className="purple">Phone Number</p>
          </li>
        </ul>
        <br />
        <p className="purple">
          If you purchase BrixPix's products and services, we collect billing
          and credit card information. This information is used to complete the
          purchase transaction.{' '}
        </p>
        <br />
        <p>
          We do not collect any personal information about you unless you
          voluntarily provide it to us. However, you may be required to provide
          certain personal information to us when you elect to use certain
          products or services available on the Site. These may include: (a)
          registering for an account on our Site; (b) entering a sweepstakes or
          contest sponsored by us or one of our partners; (c) signing up for
          special offers from selected third parties; (d) sending us an email
          message; (e) submitting your credit card or other payment information
          when ordering and purchasing products and services on our Site. To
          wit, we will use your information for, but not limited to,
          communicating with you in relation to services and/or products you
          have requested from us. We also may gather additional personal or
          non-personal information in the future.
        </p>
        <br />
      </section>
      <section>
        <h4 className="blue">Use of Your Personal Information</h4>
        <br />
        <p className="purple">
          BrixPix collects and uses your personal information to operate its
          website(s) and deliver the services you have requested.{' '}
        </p>
        <br />
        <p className="purple">
          BrixPix may also use your personally identifiable information to
          inform you of other products or services available from BrixPix and
          its affiliates.{' '}
        </p>
        <br />
      </section>
      <section>
        <h4 className="blue">Sharing Information with Third Parties</h4>
        <br />
        <p className="purple">
          BrixPix does not sell, rent or lease its customer lists to third
          parties.{' '}
        </p>
        <br />
        <p className="purple">
          BrixPix may share data with trusted partners to help perform
          statistical analysis, send you email or postal mail, provide customer
          support, or arrange for deliveries. All such third parties are
          prohibited from using your personal information except to provide
          these services to BrixPix, and they are required to maintain the
          confidentiality of your information.{' '}
        </p>
        <br />
        <p className="purple">
          BrixPix may disclose your personal information, without notice, if
          required to do so by law or in the good faith belief that such action
          is necessary to: (a) conform to the edicts of the law or comply with
          legal process served on BrixPix or the site; (b) protect and defend
          the rights or property of BrixPix; and/or (c) act under exigent
          circumstances to protect the personal safety of users of BrixPix, or
          the public.{' '}
        </p>
        <br />
      </section>
      <section>
        <h4 className="blue">Tracking User Behavior</h4>
        <br />
        <p className="purple">
          BrixPix may keep track of the websites and pages our users visit
          within BrixPix, in order to determine what BrixPix services are the
          most popular. This data is used to deliver customized content and
          advertising within BrixPix to customers whose behavior indicates that
          they are interested in a particular subject area.{' '}
        </p>
        <br />
      </section>
      <section>
        <h4 className="blue">Automatically Collected Information</h4>
        <br />
        <p className="purple">
          Information about your computer hardware and software may be
          automatically collected by BrixPix. This information can include: your
          IP address, browser type, domain names, access times and referring
          website addresses. This information is used for the operation of the
          service, to maintain quality of the service, and to provide general
          statistics regarding use of the BrixPix website.{' '}
        </p>
        <br />
      </section>
      <section>
        <h4 className="blue">Use of Cookies</h4>
        <br />
        <p className="purple">
          The BrixPix website may use "cookies" to help you personalize your
          online experience. A cookie is a text file that is placed on your hard
          disk by a web page server. Cookies cannot be used to run programs or
          deliver viruses to your computer. Cookies are uniquely assigned to
          you, and can only be read by a web server in the domain that issued
          the cookie to you.{' '}
        </p>
        <br />
        <p className="purple">
          One of the primary purposes of cookies is to provide a convenience
          feature to save you time. The purpose of a cookie is to tell the Web
          server that you have returned to a specific page. For example, if you
          personalize BrixPix pages, or register with BrixPix site or services,
          a cookie helps BrixPix to recall your specific information on
          subsequent visits. This simplifies the process of recording your
          personal information, such as billing addresses, shipping addresses,
          and so on. When you return to the same BrixPix website, the
          information you previously provided can be retrieved, so you can
          easily use the BrixPix features that you customized.{' '}
        </p>
        <br />
        <p className="purple">
          You have the ability to accept or decline cookies. Most Web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. If you choose to decline
          cookies, you may not be able to fully experience the interactive
          features of the BrixPix services or websites you visit.{' '}
        </p>
        <br />
      </section>
      <section>
        <h4 className="blue">Security of Your Personal Information</h4>
        <br />
        <p className="purple">
          BrixPix secures your personal information from unauthorized access,
          use, or disclosure. BrixPix uses the following methods for this
          purpose:{' '}
        </p>
        <br />
        <ul className="privacy-policy-bullets">
          <li>
            <p className="purple">SSL Protocol</p>
          </li>
        </ul>
        <br />
        <p className="purple">
          When personal information (such as a credit card number) is
          transmitted to other websites, it is protected through the use of
          encryption, such as the Secure Sockets Layer (SSL) protocol.{' '}
        </p>
        <br />
        <p className="purple">
          We strive to take appropriate security measures to protect against
          unauthorized access to or alteration of your personal information.
          Unfortunately, no data transmission over the Internet or any wireless
          network can be guaranteed to be 100% secure. As a result, while we
          strive to protect your personal information, you acknowledge that: (a)
          there are security and privacy limitations inherent to the Internet
          which are beyond our control; and (b) security, integrity, and privacy
          of any and all information and data exchanged between you and us
          through this Site cannot be guaranteed.{' '}
        </p>
        <br />
      </section>
      <section>
        <h4 className="blue">Right to Deletion</h4>
        <br />
        <p className="purple">
          Subject to certain exceptions set out below, on receipt of a
          verifiable request from you, we will:{' '}
        </p>
        <br />
        <ul className="privacy-policy-bullets">
          <li>
            <p className="purple">
              Delete your personal information from our records; and{' '}
            </p>
          </li>
          <li>
            <p className="purple">
              Direct any service providers to delete your personal information
              from their records.{' '}
            </p>
          </li>
        </ul>
        <br />
        <p className="purple">
          Please note that we may not be able to comply with requests to delete
          your personal information if it is necessary to:{' '}
        </p>
        <br />
        <ul className="privacy-policy-bullets">
          <li>
            <p className="purple">
              Complete the transaction for which the personal information was
              collected, fulfill the terms of a written warranty or product
              recall conducted in accordance with federal law, provide a good or
              service requested by you, or reasonably anticipated within the
              context of our ongoing business relationship with you, or
              otherwise perform a contract between you and us;{' '}
            </p>
          </li>
          <li>
            <p className="purple">
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity; or prosecute those responsible
              for that activity;{' '}
            </p>
          </li>
          <li>
            <p className="purple">
              Debug to identify and repair errors that impair existing intended
              functionality;{' '}
            </p>
          </li>
          <li>
            <p className="purple">
              Exercise free speech, ensure the right of another consumer to
              exercise his or her right of free speech, or exercise another
              right provided for by law;{' '}
            </p>
          </li>
          <li>
            <p className="purple">
              Comply with the California Electronic Communications Privacy Act;{' '}
            </p>
          </li>
          <li>
            <p className="purple">
              Engage in public or peer-reviewed scientific, historical, or
              statistical research in the public interest that adheres to all
              other applicable ethics and privacy laws, when our deletion of the
              information is likely to render impossible or seriously impair the
              achievement of such research, provided we have obtained your
              informed consent;{' '}
            </p>
          </li>
          <li>
            <p className="purple">
              Enable solely internal uses that are reasonably aligned with your
              expectations based on your relationship with us;{' '}
            </p>
          </li>
          <li>
            <p className="purple">
              Comply with an existing legal obligation; or{' '}
            </p>
          </li>
          <li>
            <p className="purple">
              Otherwise use your personal information, internally, in a lawful
              manner that is compatible with the context in which you provided
              the information.{' '}
            </p>
          </li>
        </ul>
        <br />
      </section>
      <section>
        <h4 className="blue">Children Under Thirteen</h4>
        <br />
        <p className="purple">
          BrixPix does not knowingly collect personally identifiable information
          from children under the age of thirteen. If you are under the age of
          thirteen, you must ask your parent or guardian for permission to use
          this website.{' '}
        </p>
        <br />
      </section>
      <section>
        <h4 className="blue">E-mail Communications</h4>
        <br />
        <p className="purple">
          From time to time, BrixPix may contact you via email for the purpose
          of providing announcements, promotional offers, alerts, confirmations,
          surveys, and/or other general communication.{' '}
        </p>
        <br />
        <p className="purple">
          If you would like to stop receiving marketing or promotional
          communications via email from BrixPix, you may opt out of such
          communications by emailing us at unsub@brixpix.com.{' '}
        </p>
        <br />
      </section>
      <section>
        <h4 className="blue">External Data Storage Sites</h4>
        <br />
        <p className="purple">
          We may store your data on servers provided by third party hosting
          vendors with whom we have contracted.{' '}
        </p>
        <br />
      </section>
      <section>
        <h4 className="blue">Changes to This Statement</h4>
        <br />
        <p className="purple">
          BrixPix reserves the right to change this Privacy Policy from time to
          time. We will notify you about significant changes in the way we treat
          personal information by sending a notice to the primary email address
          specified in your account, by placing a prominent notice on our site,
          and/or by updating any privacy information on this page. Your
          continued use of the Site and/or Services available through this Site
          after such modifications will constitute your: (a) acknowledgment of
          the modified Privacy Policy; and (b) agreement to abide and be bound
          by that Policy.{' '}
        </p>
        <br />
      </section>
      <section>
        <h4 className="blue">Contact Us</h4>
        <br />
        <p className="purple">
          BrixPix welcomes your questions or comments regarding the Terms:{' '}
        </p>
        <br />
        <p className="purple">BrixPix, Inc.</p>
        <p className="purple">1425 Sheridan Rd</p>
        <p className="purple">Highland Park, Illinois 60035</p>
        <br />
        <p className="purple">Email address:</p>
        <a href="mailto:Legal@brixpix.com">
          <p>Legal@brixpix.com</p>
        </a>
        <br />
        <p className="Telephone number:"></p>
        <a href="tel:619-200-2659">
          <p>6192002659</p>
        </a>
        <br />
        <p className="purple">Effective as of December 01, 2020</p>
      </section>
    </div>
  );
};
