import { dataURItoBlob, toHTTPS } from './url';

const uploadURL = 'https://api.cloudinary.com/v1_1/di4tdtrgn/image/upload';
const uploadPreset = 'brixpix_cloud';

export const fetchImage = async (
  image: HTMLImageElement,
  url: string,
  callback: () => void
) => {
  // prepares to load original image onto canvas for transformation
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.open('GET', url, true);

  xhr.onload = () => {
    if (xhr.status === 200) {
      const blob = xhr.response; // receives blob data from original image request
      const reader = new FileReader(); // reads blob as dataURL

      reader.onloadend = () => {
        const base64data = reader.result; // uses file reader to convert dataURL to base64 data

        image.onload = () => {
          callback(); // call callback function
        };

        // set image to encoded base64 data
        image.src = base64data as string;
      };
      reader.readAsDataURL(blob);
    }
  };
  xhr.send();
};

export const resetImage = (image: HTMLImageElement, callback: () => void) => {
  let loaded = false; // makes sure can't trigger callback more than once
  const src = image.src;
  image.onload = () => {
    if (!loaded) {
      loaded = true;
      callback();
    }
  };
  image.src = src;
};

/*
 * drawImage
 * draws specified image onto specified canvas
 */
export const drawImage = (
  canvas: HTMLCanvasElement,
  image: HTMLImageElement,
  width: number,
  height: number,
  brightness: number,
  contrast: number,
  saturation: number
) => {
  const context = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  context!.filter = `brightness(${brightness}%) contrast(${contrast}%) saturate(${saturation}%)`;

  context!.drawImage(image, 0, 0, canvas.width, canvas.height);
};

/*
 * adjustFilter
 * adjustes brightness, contrast, and saturation to specified vals
 */
export const adjustFilter = (
  canvas: HTMLCanvasElement,
  brightness: number,
  contrast: number,
  saturation: number
) => {
  const context = canvas.getContext('2d');
  context!.filter = `brightness(${brightness}%) contrast(${contrast}%) saturate(${saturation}%)`;
}

export const updateCanvas = (
  canvas: HTMLCanvasElement,
  imageData: ImageData
) => {
  const context = canvas.getContext('2d');

  // sets canvas to imageData dimensions to avoid weird cropping issues
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  context!.clearRect(0, 0, canvas.width, canvas.height);
  context!.putImageData(imageData, 0, 0); // place image onto canvas
};

/*
 * postImage
 * uploads canvas imageData to cloudinary
 */
export const postImage = (
  canvas: HTMLCanvasElement,
  callback: (url: string) => void
) => {
  // prepare to upload image to cloudinary
  const xhr = new XMLHttpRequest();
  xhr.open('POST', uploadURL, true);
  xhr.responseType = 'json';

  // triggers when image done uploading to Cloudinary
  xhr.onload = () => {
    if (xhr.status === 200) {
      const res = xhr.response;
      const url = toHTTPS(res.url);

      callback(url);
    }
  };
  const dataURI = canvas.toDataURL(); // convert canvas to dataURI
  const blob = dataURItoBlob(dataURI); // convert dataURI to blob
  const file = new File(
    [blob],
    Date.now() + '.png', // convert blob to file
    { type: 'image/png' }
  );
  const uploadData = new FormData(); // convert file to form data component
  uploadData.append('upload_preset', uploadPreset);
  uploadData.append('file', file);

  xhr.send(uploadData); // submit form along with image data to Cloudinary upload API
};
