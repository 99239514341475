import React, { useState, useEffect } from 'react';

import { getSizeData } from '../../../api';

/*
 * ItemDetail
 * Displays info for a single item from a previous order
 */

export type Props = {
  item: {
    width: number;
    height: number;
    instructionsUrl: string;
    pixImageUrl: string;
    quantity: number;
  };
  name: string;
  tracking: string;
};

export const ItemDetail = ({ item, name, tracking }: Props) => {
  const [size, setSize] = useState<string>('');
  const [price, setPrice] = useState<number>(0);

  useEffect(() => {
    const initialize = async () => {
      const res = await getSizeData({
        keys: ['width', 'height'],
        values: [item.width, item.height],
      });
      const sizeData = res.data.data[0];
      setSize(sizeData.size);
      setPrice(sizeData.price);
    };
    if (item) {
      initialize();
    }
  }, [item]);

  // links to instructions PDF if possible
  const instructions = item.instructionsUrl ? (
    <a
      href={item.instructionsUrl}
      download="brixpix-instructions.pdf"
      target="_blank"
      rel="noopener noreferrer"
    >
      <p>Reprint Blueprint.</p>
    </a>
  ) : null;

  // fills in tracking number if possible
  const trackingNum = tracking ? tracking : 'Not shipped yet';

  return (
    <>
      <div className="center-align-col padded-cell flex-cell vertical-centered-cell">
        <img
          src={item.pixImageUrl}
          className="item-thumbnail"
          alt="pixelated thumbnail"
        />
      </div>
      <div className="left-align-col flex-cell vertical-centered-cell">
        <p className="purple large-text item-detail-size">{size} BrixPix</p>
        {instructions}
        <div className="tracking-cell">
          <p className="dark-grey left">{name}</p>
          <p className="dark-grey left">{trackingNum}</p>
        </div>
      </div>
      <div className="center-align-col padded-cell flex-cell vertical-centered-cell">
        <p className="purple large-text">{item.quantity}</p>
      </div>
      <div className="center-align-col padded-cell flex-cell vertical-centered-cell">
        <p className="purple large-text">${price * item.quantity}</p>
      </div>
    </>
  );
};
