import React from 'react';
import styled from 'styled-components';

import { colorPalette } from '../../../../pixelation/Colors';
import { undo as undoIcon, redo as redoIcon, eyedropper as pickerIcon, hand as handIcon, zoomIn as zoomInIcon, } from '../../../../media';

export type Props = {
  selectedColor: string | null;
  onSelectColor: (color: string | null) => void;
  undo: () => void;
  redo: () => void;
  //picker: () => void;
  canUndo: boolean;
  canRedo: boolean;
};

const AdvancedEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;

const ColorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  margin: 20px auto;
`;

const ColorThumbnail = styled.div<{
  backgroundColor: string;
  selected: boolean;
}>`
  height: 20px;
  width: 20px;
  margin: 10px;
  box-sizing: border-box;
  cursor: pointer;

  background-color: ${({ backgroundColor }) => backgroundColor};
  ${({ selected }) =>
    selected &&
    `border: 2px solid #000;
    box-shadow: 2px 2px 5px 2px #6D8CC3;`}

  :hover {
    box-shadow: 2px 2px 5px 2px #6d8cc3;
  }
`;

const InstructionsContainer = styled.div`
  padding: 10px 0 20px 0;
  align-items: left;
  width: 500px;
  border: 10px solid-black;
`;

const AboveContainer = styled.div`
  padding: 10px 0 0px 0;
  align-items: left;
  width: 500px;
  border: 10px solid-black;
`;

const UndoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px auto;
`;

const UndoControlContainer = styled.div`
  display; flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
`;

const UndoControl = styled.img`
  margin: 0 10px;
`;

export const AdvancedEditor = ({
  selectedColor,
  onSelectColor,
  undo,
  redo,
  canUndo,
  canRedo,
}: Props) => {
  return (
    <AdvancedEditorContainer>
      <AboveContainer>
        <p className="purple">To customize a pixel color, select that color above, then click a
          pixel on your BrixPix</p>
      </AboveContainer>
      <ColorContainer>
        {colorPalette.map((color, index) => (
          <ColorThumbnail
            backgroundColor={color}
            selected={selectedColor === color}
            onClick={() =>
              onSelectColor(color === selectedColor ? null : color)
            }
            key={index}
          />
        ))}
      </ColorContainer>
      <UndoContainer>
        <UndoControlContainer>
          <UndoControl
            src={undoIcon}
            alt="undo arrow"
            height="35"
            width="35"
            className={canUndo ? 'clickable' : 'not-clickable'}
            onClick={canUndo ? undo : () => { }}
          />
          <p className="purple">Undo</p>
        </UndoControlContainer>
        <UndoControlContainer>
          <UndoControl
            src={redoIcon}
            alt="redo arrow"
            height="35"
            width="35"
            className={canRedo ? 'clickable' : 'not-clickable'}
            onClick={canRedo ? redo : () => { }}
          />
          <p className="purple">Redo</p>
        </UndoControlContainer>
        <UndoControlContainer>
          <UndoControl
            src={handIcon}
            alt="hand icon"
            height="35"
            width="35"
            //className={canRedo ? 'clickable' : 'not-clickable'}
            onClick={() =>
              onSelectColor(null)
            }
          />
          <p className="purple">Enable Pan Mode</p>
        </UndoControlContainer>
      </UndoContainer>
      <InstructionsContainer>
        {/*add icon for zoom, then zoom p, then icon for pan, then pan p */}
        <div style={{ display: "inline-block" }}>
          <img src={zoomInIcon}
            alt="hand icon"
            height="15"
            width="15"
            style={{ display: "inline", marginRight: "3px" }}
          />
          <p className="purple" style={{ display: "inline" }}>
            To zoom, click the appropriate icons by your BrixPix, or use the
            typical zoom gestures on your mouse/trackpad
          </p>
        </div>
        <div style={{ display: "inline-block" }}>
          <img src={handIcon}
            alt="hand icon"
            height="15"
            width="15"
            style={{ display: "inline", marginRight: "3px" }} />
          <p className="purple" style={{ display: "inline" }}>
            To move, use the typical drag-and-drop gestures on your mouse/trackpad.
            Deselect the color you chose by clicking it again to make moving easy!
          </p>
        </div>
      </InstructionsContainer>
    </AdvancedEditorContainer>
  );
};
