import React from 'react';

import './Thumbnail.css';

/*
 * Thumbnail
 * Tiny image that does something on click
 */

export type Props = {
  image: string;
  position?: React.CSSProperties;
  action: () => void;
};

export const Thumbnail = ({ image, position, action }: Props) => {
  return (
    <img
      className="gallery-thumbnail clickable"
      src={image}
      style={position}
      onClick={action}
      alt="thumbnail"
    />
  );
};
