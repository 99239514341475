import React from 'react';

import { bedroom } from '../../../media';
import { ImageSelector } from '../../../components';

export type Props = {
  image: string;
  first: boolean;
  last: boolean;
  nextImage: () => void;
  prevImage: () => void;
};

export const GalleryNoControls = ({
  image,
  first,
  last,
  nextImage,
  prevImage,
}: Props) => {
  return (
    <div className="gallery-no-controls">
      <div
        style={{ backgroundImage: `url(${bedroom})` }}
        className="gallery-background"
      />
      <ImageSelector
        image={image}
        first={first}
        last={last}
        nextImage={nextImage}
        prevImage={prevImage}
      />
    </div>
  );
};
