import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { SidewaysButton } from '../../../components';
import { doThis, notThis } from '../../../media';
import { isTablet } from '../../../helpers';

import Analytics from 'analytics'
import { AnalyticsProvider } from 'use-analytics'
import googleAnalyticsPlugin from '@analytics/google-analytics'

import './OrderPage.css';

const analytics = Analytics({
  app: 'BrixPix dev',
  plugins: [
    googleAnalyticsPlugin({
      trackingId: 'UA-190448776-2',
    })]
})

export const OrderPage = () => {
  const [tablet, setTablet] = useState<boolean>(isTablet());

  /* checks for window size on mount */
  useEffect(() => {
    const onResize = () => {
      setTablet(isTablet());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div className={`main-slider-page order-page`}>
      {!tablet && (window.location as any).path !== '/' && <Redirect to="/" />}
      <div className="order-page-content">
        <h2>Start Your BrixPix</h2>
        <br />
        <div className="order-inner-content custom-scroll">
          <p className="purple large-text">
            With our fast and intuitive ordering process, creating your own
            BrixPix kit is a piece of cake.
          </p>
          <br />
          <p className="purple large-text">
            For best results, select a high-quality, well-lit photo. Close-ups
            work best!
          </p>
          <div className="order-start-container">
            <SidewaysButton
              action={() => {
                analytics.track("Start Here Pressed", {
                  category: "Order Page"
                });
                window.location.href = '/order';
              }
              }
              value="Start Here"
              color="blue"
              right
            />
          </div>
        </div>
      </div>
      <div className="do-this-section">
        <div className="do-this-container">
          <img src={doThis} className="do-this-pic" alt="zoomed in family" />
          <h4 className="blue">Do this</h4>
        </div>
        <div className="do-this-container">
          <img src={notThis} className="do-this-pic" alt="zoomed out family" />
          <h4 className="blue">Not this</h4>
        </div>
      </div>
    </div>
  );
};
