import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { logo as mainLogo } from '../media';

import { Hamburger } from './Hamburger';
import { SliderMenu } from './SliderMenu';

export type Props = {
  admin: boolean;
  loggedIn: boolean;
  cartNum: string;
  handleLogin: () => void;
  handleLogout: () => void;
  redirecter: (redirect: string) => void;
};

export const TabletHeader = (props: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Link to="/" className="header-item">
        <img src={mainLogo} className="header-logo" alt="brixpix logo" />
      </Link>
      <Hamburger open={open} click={() => setOpen(!open)} />
      <SliderMenu {...props} open={open} click={() => setOpen(!open)} />
    </>
  );
};
