import React from 'react';

import { SelectableTab } from '../../../components';
import { capitalizeFirstChar } from '../../../helpers';

import { AdminDisplay } from '../types';

export type Props<T extends AdminDisplay> = {
  currView: T;
  changeView: (display: T) => void;
  viewOptions: string[];
};

export const ViewSelector = <T extends AdminDisplay>({
  currView,
  changeView,
  viewOptions,
}: Props<T>) => {
  return (
    <div className="view-selector">
      {viewOptions &&
        viewOptions.map((view: string, index: number) => (
          <SelectableTab
            label={capitalizeFirstChar(view)}
            active={currView === view}
            action={() => changeView(view as T)}
            key={index}
          />
        ))}
    </div>
  );
};
