export const sizeImagesUnpatterned = {
  SS: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1610819497/brixpix-assets/dogs/dog-S-S_ch093b.png',
  SM: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1610819497/brixpix-assets/dogs/dog-S-M_x8dxk8.png',
  SL: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1610819497/brixpix-assets/dogs/dog-S-L_osdlhc.png',
  PS: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1610819497/brixpix-assets/dogs/dog-P-S_hv6get.png',
  PM: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1610819497/brixpix-assets/dogs/dog-P-M_pnxxsx.png',
  PL: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1610819497/brixpix-assets/dogs/dog-P-L_h9at0o.png',
  LS: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1610819497/brixpix-assets/dogs/dog-L-S_y6q4ce.png',
  LM: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1610819497/brixpix-assets/dogs/dog-L-M_hrj8uj.png',
  LL: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1610819497/brixpix-assets/dogs/dog-L-L_mm7wje.png',
  // these are just placeholders, not accurate
  SC: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1610819497/brixpix-assets/dogs/dog-S-L_osdlhc.png',
  PC: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1610819497/brixpix-assets/dogs/dog-P-L_h9at0o.png',
  LC: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1610819497/brixpix-assets/dogs/dog-L-L_mm7wje.png',
};

export const sizeImages = {
  SS: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618618659/brixpix-assets/dogs/S-Small-1_w3lsbq.png',
  SM: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618618660/brixpix-assets/dogs/S-Medium-1_do0jsh.png',
  SL: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618618659/brixpix-assets/dogs/S-Large-1_rtdcqs.png',
  PS: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618618659/brixpix-assets/dogs/P-Small-1_bbfbhb.png',
  PM: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618618659/brixpix-assets/dogs/P-Medium-1_uimmhf.png',
  PL: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618618659/brixpix-assets/dogs/P-Large-1_ldaptx.png',
  LS: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618618660/brixpix-assets/dogs/L-Small-1_x1jlbn.png',
  LM: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618618660/brixpix-assets/dogs/L-Medium-1_jzpobs.png',
  LL: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618618659/brixpix-assets/dogs/L-Large-1_dkh1ib.png',
  // these are just placeholders, not accurate
  SC: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618618659/brixpix-assets/dogs/S-Large-1_rtdcqs.png',
  PC: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618618659/brixpix-assets/dogs/P-Large-1_ldaptx.png',
  LC: 'https://res.cloudinary.com/di4tdtrgn/image/upload/v1618618659/brixpix-assets/dogs/L-Large-1_dkh1ib.png',
};
