import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isTablet } from '../helpers';

export type Props = {
  component: React.ElementType;
  redirect: string;
  path?: string;
  exact?: boolean;
};

/* displays if tablet or smaller or redirects to redirect */
export const MobileRoute = ({
  component: Component,
  redirect,
  ...rest
}: Props) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isTablet() ? <Component {...props} /> : <Redirect to={redirect} />
      }
    />
  );
};
