import React from 'react';

import './Hamburger.css';

export type Props = {
  open: boolean;
  click: () => void;
};

export const Hamburger = ({ open, click }: Props) => {
  const openClass = open ? 'open' : '';

  return (
    <div className="hamburger" onClick={click}>
      <div className={`hamburger-line ${openClass}`} />
      <div className={`hamburger-line ${openClass}`} />
      <div className={`hamburger-line ${openClass}`} />
    </div>
  );
};
