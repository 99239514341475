import React from 'react';

import './SelectableTab.css';

/*
 * SelectableTab
 * single tab that works like a radio button and looks like a button
 * parameters:
 *      label: required, string, explains what this tab does
 *      active: optional, bool, true if this tab is currently selected
 *      action: required, function, what happens when this tab is clicked
 */

export type Props = {
  label: string;
  active?: boolean;
  action: () => void;
};

export const SelectableTab = ({ label, active, action }: Props) => {
  return (
    <div
      className={`selectable-tab ${active ? 'active-tab' : ''}`}
      onClick={action}
    >
      {label}
    </div>
  );
};
