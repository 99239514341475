import React from 'react';

import { ClassicButton } from '../../../components';
import Analytics from 'analytics'
import { AnalyticsProvider } from 'use-analytics'
import googleAnalyticsPlugin from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'BrixPix dev',
  plugins: [
    googleAnalyticsPlugin({
      trackingId: 'UA-190448776-2',
    })]
})

export const NoOrders = () => {
  return (
    <div className="no-orders">
      <p className="purple">Looks like you don't have any orders yet!</p>
      <div className="order-again-button">
        <ClassicButton
          color="eggplant"
          action={() => {
            analytics.track("Let's Change That Pressed", {
              category: "Order History"
            });
            (window.location.href = '/order')
          }
          }
          value="Let's Change That"
        />
      </div>
    </div>
  );
};
