import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { getItemsInCart, logout, verifyUserToken, verifyAdmin } from '../api';
import { isTablet } from '../helpers';
import { getCurrentUser } from '../local-storage';

import { DesktopHeader } from './DesktopHeader';
import { TabletHeader } from './TabletHeader';
import './Header.css';

import Analytics from 'analytics'
import { AnalyticsProvider } from 'use-analytics'
import googleAnalyticsPlugin from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'BrixPix dev',
  plugins: [
    googleAnalyticsPlugin({
      trackingId: 'UA-190448776-2',
    })]
})

/*
 * Header
 * Static header that displays on every page
 */

export const Header = () => {
  // tracks if there's currently a user logged in
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [admin, setAdmin] = useState<boolean>(false);
  const [cartNum, setCartNum] = useState<string>('');
  const [tablet, setTablet] = useState<boolean>(isTablet());
  const [redirect, setRedirect] = useState<string | null>(null);

  /* checks if logged in on mount */
  useEffect(() => {
    updateLoginOut();
    getCartNumber();

    const onResize = () => {
      setTablet(isTablet());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  /*
   * updateLoginOut
   * checks for valid token, sets state appropriately
   * no parameters
   */
  const updateLoginOut = async () => {
    const token = getCurrentUser();
    const res = await verifyUserToken({ token: token });

    res.data.user && setLoggedIn(true);

    if (res.data.user) {
      verifyAdmin(res.data.user._id)
        .then((adminRes) => {
          setAdmin(adminRes.data.isAdmin);
        })
        .catch((err) => console.log(err));
    }
  };

  /* retrieves number of items in cart to display */
  const getCartNumber = async () => {
    const orders = await getItemsInCart();
    let numOrders = 0;
    if (orders) {
      orders.forEach((order) => (numOrders += order.quantity));
    }
    setCartNum(`${numOrders}`);
  };

  /*
   * handleLogout
   * triggers if logout button is clicked
   * only appears if user currently logged in
   * logs out current user and redirects to homepage
   * no parameters
   */
  const handleLogout = () => {
    logout();
    window.location.href = '/';
  };

  /*
   * handleLogin
   * triggers if login button is clicked
   * only appears if user NOT currently logged in
   * redirects to login page so user has opportunity to login
   * no parameters
   */
  const handleLogin = () => setRedirect('/login');

  const redirecter = (route: string) => {
    analytics.page();
    analytics.track(route, {
      category: "Navbar"
    });
    setRedirect(route);
  }

  const properties = {
    admin,
    loggedIn,
    cartNum,
    handleLogin,
    handleLogout,
    redirecter,
  };

  return (
    <header>
      <div className="header-wrapper">
        {redirect && <Redirect to={redirect} />}
        {tablet ? (
          <TabletHeader {...properties} />
        ) : (
          <DesktopHeader {...properties} />
        )}
      </div>
    </header>
  );
};
