import { prepItemForOrder } from '../helpers';
import { CartItem, ItemInfo } from '../types';

/*
 * addToAnonymousOrder
 * adds to cart where cart is in local storage
 */
export const addToAnonymousOrder = (info: ItemInfo) => {
  const item = prepItemForOrder(info);

  const cart = window.localStorage.getItem('order-info');
  let orders: CartItem[] | null = cart != null ? JSON.parse(cart) : null;
  if (orders != null && typeof orders === 'object' && Array.isArray(orders)) {
    orders.push(item);
  }
  // create brand new order
  else {
    orders = [item];
  }
  window.localStorage.setItem('order-info', JSON.stringify(orders));
  window.location.href = '/cart';
};

/*
 * getAllAnonymousOrders
 * retrieves all items in cart if no user currently logged in
 * note: returns cart in reverse chronological order
 */
export const getAllAnonymousOrders = (): CartItem[] | null => {
  const cart = window.localStorage.getItem('order-info');
  const orders = cart != null ? JSON.parse(cart) : null;
  return orders ? orders.reverse() : orders;
};

/*
 * updateAnonymousOrders
 * updates items in cart in local storage
 */
export const updateAnonymousOrders = (
  orders: CartItem[],
  redirect?: string
) => {
  window.localStorage.removeItem('order-info');

  if (Array.isArray(orders)) {
    window.localStorage.setItem('order-info', JSON.stringify(orders));

    if (redirect) {
      window.location.href = redirect;
    }
  }
};

/*
 * clearAnonymousOrders
 * empties cart for anonymous user
 */
export const clearAnonymousOrders = () => {
  window.localStorage.removeItem('order-info');
};
