import React, { useState, useEffect } from 'react';

import { MiniRoomGallery } from '../../../components';
import { isMobile } from '../../../helpers';
import { SizeCode } from '../../../../../common/types';

import { ItemInfo } from '../../../types';
import { backgrounds } from './reviewTestBackgrounds';
import './ReviewStep.css';

/*
 * ReviewStep
 * Final step of order process, to experiment with rooms and view finished product
 */

export type Props = {
  info: Pick<ItemInfo, 'shape' | 'sizeData' | 'newURL'>;
};

export const ReviewStep = ({ info }: Props) => {
  const [size, setSize] = useState<SizeCode>('SL'); // size code - shape then size
  const [mobile, setMobile] = useState<boolean>(isMobile());

  /* checks for window size on mount */
  useEffect(() => {
    const onResize = () => {
      setMobile(isMobile());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  /* initalizes size code based on props */
  useEffect(() => {
    setSize((info.shape[0] + info.sizeData.size[0]) as SizeCode);
  }, [info]);

  return (
    <div className="review-step">
      <h2 className="review-header">Check out your BrixPix</h2>
      {/* main content below heading */}
      <div className="review-content-container">
        {/* pixelated image, left column */}
        <div className="review-image-container">
          <div className="review-image-wrapper">
            <img
              src={info.newURL}
              className={`
                                review-image 
                                ${
                                  info.shape === 'Landscape'
                                    ? 'landscape'
                                    : 'portrait'
                                }-review-image
                            `}
              alt="pixelated"
            />
          </div>
          <b>
            <p className="large-text purple">Look at what you created!</p>
          </b>
        </div>
        {/* review info, right column */}
        <div className="review-preview-section">
          {/* room display */}
          <MiniRoomGallery
            rooms={backgrounds}
            source={info.newURL}
            size={size}
            flow={mobile ? 'down' : 'right'}
            small={mobile}
          />
          <div className="review-preview-content">
            <p className="large-text purple">
              Select a room to see how your BrixPix looks in different spaces.
            </p>
            <br />
            {/* pricing and info text */}
            <div>
              <b>
                <p className="purple large-text underline">Your Selection:</p>
              </b>
              <div className="brix-review-info-container">
                <p className="purple large-text inline-block left">
                  {info.sizeData.size} BrixPix
                </p>
                <p className="purple large-text inline-block right">
                  ${info.sizeData.price}
                </p>
              </div>
              <br />
              <div className="brix-review-size-container">
                <p className="small-text left">
                  {info.sizeData.imperial.width} x{' '}
                  {info.sizeData.imperial.height} inches /{' '}
                  {info.sizeData.metric.width} cm x{' '}
                  {info.sizeData.metric.height} cm
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
