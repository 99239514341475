import React, { useState } from 'react';

import {
  validateCouponCode,
  validateCouponType,
  validateNumber,
} from '../../../api';
import { Checkbox, SelectInput, TextInput } from '../../../components';
import { changeField } from '../../../helpers';
import { Coupon, CouponType } from '../../../../../common/types';
import { times as x, checkMark as check } from '../../../media';

export type Props = {
  hide: () => void;
  createCoupon: (coupon: Omit<Coupon, '_id'>) => void;
};

const couponTypes = ['Percent', 'Dollars'];

export const NewCoupon = ({ hide, createCoupon }: Props) => {
  const [code, setCode] = useState<string>('');
  const [type, setType] = useState<CouponType>('Percent');
  const [quantity, setQuantity] = useState<string>('');
  const [active, setActive] = useState<boolean>(false);

  const [codeMessage, setCodeMessage] = useState<string>('');
  const [typeMessage, setTypeMessage] = useState<string>('');
  const [quantityMessage, setQuantityMessage] = useState<string>('');

  const makeCoupon = () => {
    let problem = false;
    validateCouponCode({
      code: code,
      shouldExist: false,
    })
      .then((res: any) => {
        problem = problem || res.data.problem;
        setCodeMessage(res.data.message);

        validateCouponType({
          type: type,
        })
          .then((res: any) => {
            problem = problem || res.data.problem;
            setTypeMessage(res.data.message);

            validateNumber({
              name: 'quantity',
              number: quantity,
            })
              .then((res: any) => {
                problem = problem || res.data.problem;
                setQuantityMessage(res.data.message);

                if (!problem) {
                  const coupon = {
                    code: code,
                    type: type,
                    quantity: parseInt(quantity),
                    active: active,
                  };
                  createCoupon(coupon);
                }
              })
              .catch((err: any) => console.log(err));
          })
          .catch((err: any) => console.log(err));
      })
      .catch((err: any) => console.log(err));
  };

  return (
    <>
      <>
        <div className="flex-cell left-align-col vertical-padded-cell horizontal-padded-cell">
          <TextInput
            name="Code"
            value={code}
            setter={setCode}
            message={codeMessage}
            messageSetter={setCodeMessage}
            change={changeField}
            placeholder="20PERCENTGOALS"
          />
          <p className="dark-grey small align-left">
            This should be unique and hard to guess
          </p>
        </div>
        <div className="flex-cell center-align-col vertical-padded-cell">
          <SelectInput
            name="Type"
            options={couponTypes}
            change={changeField}
            value={type}
            setter={(value) => setType(value as CouponType)}
            message={typeMessage}
            messageSetter={setTypeMessage}
          />
        </div>
        <div className="flex-cell center-align-col vertical-padded-cell">
          <TextInput
            name="Quantity"
            value={quantity}
            setter={setQuantity}
            message={quantityMessage}
            messageSetter={setQuantityMessage}
            change={changeField}
            placeholder="50"
          />
          <p className="dark-grey small align-left">
            Please enter just the number, e.g. for 10% off, type 10, or $20 off,
            type 20
          </p>
        </div>
        <div className="flex-cell center-align-col vertical-padded-cell">
          <Checkbox checked={active} check={() => setActive(!active)} />
        </div>
      </>
      <>
        <div />
        <div />
        <div />
        <div className="flex-cell center-align-col vertical-padded-cell">
          <div className="finish-icon-section">
            <img
              onClick={hide}
              src={x}
              className="big-finish-icon admin-finish-icon"
              alt="x"
            />
            <img
              onClick={makeCoupon}
              src={check}
              className="big-finish-icon admin-finish-icon"
              alt="check"
            />
          </div>
        </div>
      </>
    </>
  );
};
