import React from 'react';

/*
 * RadioButton
 * Standard radio button form input
 */

export type Props = {
  label: string;
  select: (option: any) => void;
  selected: boolean;
  tiny?: boolean;
  small?: boolean;
  medium?: boolean;
  inline?: boolean;
  sublabel?: string;
};

export const RadioButton = ({
  label,
  select,
  selected,
  tiny,
  small,
  medium,
  inline,
  sublabel,
}: Props) => {
  // set class for label based on props
  let labelClass = '';
  if (tiny) {
    labelClass += 'white smallish';
  }
  if (small) {
    labelClass += 'dark-grey';
  }
  if (inline || medium) {
    labelClass += 'large-text';
  }
  if (!(small || tiny || inline || medium)) {
    labelClass = 'extra-large-text purple';
  }

  return (
    <div
      className={`radio-button-container ${
        inline ? 'inline-radio-button' : ''
      } ${tiny ? 'tiny-radio-button' : ''} ${
        small ? 'small-radio-button' : ''
      }`}
      onClick={select}
    >
      {/* radio button */}
      <div className="radio-content-container">
        <div
          className={`radio-button ${sublabel ? 'cramped-radio-button' : ''}`}
        >
          <div className="outer-radio-button" />
          {selected && <div className="inner-radio-button" />}
        </div>
        <p className={`radio-button-label ${labelClass}`}>{label}</p>
      </div>
      {/* label */}
      {sublabel && (
        <p
          className={`
                    dark-grey 
                    ${small || medium ? '' : 'large-text'} 
                    ${medium ? 'indented-sublabel' : ''}
                    radio-button-sublabel
                `}
        >
          {sublabel}
        </p>
      )}
    </div>
  );
};
