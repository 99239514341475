import React from 'react'
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

/*
 * RouteChangeTracker
 * allows google analytics to track data about page visits even though
 * everything is on one page
 */
const RouteChangeTracker = ({ history }) => {
    console.log(history);
    history.listen((location, action) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    });

    return <></>;
};

export default withRouter(RouteChangeTracker);