// index: absolute index in color map
// width: width in inches of brix
const inchesPerBlock = 10;
const brixPerBlock = 32;

export const getXIndex = (index: number, width: number) => {
  const widthInBrix = (width / inchesPerBlock) * brixPerBlock;
  return index % widthInBrix;
};

export const getYIndex = (index: number, width: number) => {
  const widthInBrix = (width / inchesPerBlock) * brixPerBlock;
  return Math.floor(index / widthInBrix);
};

export const getIndexArr = (index: number, width: number) => {
  return [getXIndex(index, width), getYIndex(index, width)];
};

export const getBrixSize = (canvasWidth: number, width: number) => {
  const widthInBrix = (width / inchesPerBlock) * brixPerBlock;
  return canvasWidth / widthInBrix;
};
