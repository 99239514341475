import React, { useState } from 'react';

import { ClassicButton, SelectInput, TextInput } from '../../../components';
import { changeField, states } from '../../../helpers';
import { User } from '../../../../../common/types';
import {
  addAddress,
  validateAddress,
  validateState,
  validateZip,
} from '../../../api';

export type Props = {
  user: User | null;
  visible: boolean;
  cancelable: boolean;
  toggleNew: () => void;
};

export const AddressForm = ({
  user,
  visible,
  cancelable,
  toggleNew,
}: Props) => {
  const [name, setName] = useState<string>('');
  const [streetAdd, setStreetAdd] = useState<string>('');
  const [streetAdd2, setStreetAdd2] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zip, setZip] = useState<string>('');

  // messages for errors
  const [nameMessage, setNameMessage] = useState<string>('');
  const [streetAddMessage, setStreetAddMessage] = useState<string>('');
  const [streetAddMessage2, setStreetAddMessage2] = useState<string>('');
  const [cityMessage, setCityMessage] = useState<string>('');
  const [stateMessage, setStateMessage] = useState<string>('');
  const [zipMessage, setZipMessage] = useState<string>('');

  const checkForm: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();

    var problem = false;

    validateAddress({ name: name, type: 'addressee' })
      .then((res: any) => {
        problem = problem || res.data.problem;
        setNameMessage(res.data.message);

        validateAddress({ name: streetAdd, type: 'street address' })
          .then((res: any) => {
            problem = problem || res.data.problem;
            setStreetAddMessage(res.data.message);

            validateAddress({ name: city, type: 'city' })
              .then((res: any) => {
                problem = problem || res.data.problem;
                setCityMessage(res.data.message);

                validateState({ state: state })
                  .then((res: any) => {
                    problem = problem || res.data.problem;
                    setStateMessage(res.data.message);

                    validateZip({ zip: zip })
                      .then((res: any) => {
                        problem = problem || res.data.problem;
                        setZipMessage(res.data.message);

                        if (!problem && user != null) {
                          addAddress(
                            user,
                            name,
                            streetAdd,
                            streetAdd2,
                            city,
                            state,
                            zip
                          );
                        }
                      })
                      .catch((err: any) => console.log(err));
                  })
                  .catch((err: any) => console.log(err));
              })
              .catch((err: any) => console.log(err));
          })
          .catch((err: any) => console.log(err));
      })
      .catch((err: any) => console.log(err));
  };

  const stateOptions = states.slice();
  stateOptions.unshift('Select');

  return (
    <>
      {visible && (
        <form className="new-address-section">
          <TextInput
            name="Name"
            value={name}
            setter={setName}
            message={nameMessage}
            messageSetter={setNameMessage}
            change={changeField}
            short
          />
          <TextInput
            name="Street Address"
            value={streetAdd}
            setter={setStreetAdd}
            message={streetAddMessage}
            messageSetter={setStreetAddMessage}
            change={changeField}
            short
          />
          <TextInput
            name="Street Address 2"
            value={streetAdd2}
            setter={setStreetAdd2}
            message={streetAddMessage2}
            messageSetter={setStreetAddMessage2}
            change={changeField}
            short
          />
          <div className="input-line">
            <TextInput
              name="City"
              value={city}
              setter={setCity}
              message={cityMessage}
              messageSetter={setCityMessage}
              change={changeField}
            />
            <SelectInput
              options={stateOptions}
              name="State"
              value={state}
              setter={setState}
              message={stateMessage}
              messageSetter={setStateMessage}
              change={changeField}
            />
            <TextInput
              name="Zip Code"
              value={zip}
              setter={setZip}
              message={zipMessage}
              messageSetter={setZipMessage}
              change={changeField}
            />
          </div>
          <div className="address-form-footer">
            {cancelable && (
              <p onClick={toggleNew} className="rose clickable">
                Cancel
              </p>
            )}
            <div className="address-form-footer-button-container">
              <ClassicButton
                color="blue"
                value="Save Address"
                action={checkForm}
              />
            </div>
          </div>
        </form>
      )}
    </>
  );
};
