import React, { useEffect } from 'react';

import { MobileBladePage } from '../../../components';
import { setCurrentBlade } from '../../../local-storage';

import { HomePage } from './HomePage';

export const HomeMobile = () => {
  useEffect(() => {
    setCurrentBlade('home');
  }, []);

  return <MobileBladePage bladeLabel="Home" component={HomePage} />;
};
