import React, { useState } from 'react';

import {
  validateName,
  validateEmail,
  validateMessage,
  sendHelpEmail,
} from '../../api';
import { ClassicButton, TextArea, TextInput } from '../../components';
import { changeField } from '../../helpers';
import { envelope as envelopeIcon } from '../../media';

export type Props = {
  finish: () => void;
};

export const ContactForm = ({ finish }: Props) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const [firstNameMessage, setFirstNameMessage] = useState<string>('');
  const [lastNameMessage, setLastNameMessage] = useState<string>('');
  const [emailMessage, setEmailMessage] = useState<string>('');
  const [subjectMessage, setSubjectMessage] = useState<string>('');
  const [messageMessage, setMessageMessage] = useState<string>('');

  const checkForm = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const firstRes = await validateName({
      name: firstName,
      type: 'first',
    });
    setFirstNameMessage(firstRes.data.message);

    const lastRes = await validateName({
      name: lastName,
      type: 'last',
    });
    setLastNameMessage(lastRes.data.message);

    const emailRes = await validateEmail({
      email: email,
    });
    setEmailMessage(emailRes.data.message);

    const subjectRes = await validateMessage({
      message: subject,
      type: 'subject',
    });
    setSubjectMessage(subjectRes.data.message);

    const messageRes = await validateMessage({
      message: message,
      type: 'message',
    });
    setMessageMessage(messageRes.data.message);

    if (
      !firstRes.data.problem &&
      !lastRes.data.problem &&
      !emailRes.data.problem &&
      !subjectRes.data.problem &&
      !messageRes.data.problem
    ) {
      sendHelpEmail({
        firstName: firstName,
        lastName: lastName,
        email: email,
        subject: subject,
        message: message,
      });
      finish();
    }
  };

  return (
    <form onSubmit={checkForm} className="contact-form custom-scroll">
      <div className="input-line">
        <TextInput
          name="First Name"
          value={firstName}
          setter={setFirstName}
          message={firstNameMessage}
          messageSetter={setFirstNameMessage}
          change={changeField}
        />
        <TextInput
          name="Last Name"
          value={lastName}
          setter={setLastName}
          message={lastNameMessage}
          messageSetter={setLastNameMessage}
          change={changeField}
        />
      </div>
      <div className="input-line">
        <TextInput
          name="Email"
          value={email}
          setter={setEmail}
          message={emailMessage}
          messageSetter={setEmailMessage}
          change={changeField}
        />
        <div className="input-icon-container">
          <img className="input-icon" src={envelopeIcon} alt="envelope" />
        </div>
      </div>
      <div className="input-line">
        <TextInput
          name="Subject"
          value={subject}
          setter={setSubject}
          message={subjectMessage}
          messageSetter={setSubjectMessage}
          change={changeField}
        />
      </div>
      <TextArea
        name="Message"
        value={message}
        setter={setMessage}
        message={messageMessage}
        messageSetter={setMessageMessage}
        change={changeField}
      />
      <div className="contact-button-container">
        <ClassicButton value="Submit Inquiry" color="blue" submit />
      </div>
    </form>
  );
};
