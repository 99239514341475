import React, { useState } from 'react';

import { SizeCode } from '../../../common/types';

import { RoomTester } from './RoomTester';
import './MiniRoomGallery.css';

/*
 * MiniRoomGallery
 * Displays gallery with thumbnails of rooms to play with
 */

export type Props = {
  rooms: string[];
  size: SizeCode;
  source: string;
  flow: 'right' | 'down';
  small?: boolean;
};

export const MiniRoomGallery = ({
  rooms,
  size,
  source,
  flow = 'right',
  small,
}: Props) => {
  // index of room in arr currently displayed
  const [currRoom, setCurrRoom] = useState<number>(0);

  // initalizes image thumbnails
  const imageThumbnails =
    rooms &&
    rooms.map((room, index) => (
      <img
        src={room}
        className="mini-room-gallery-thumbnail clickable"
        onClick={() => setCurrRoom(index)}
        alt="test brixpix"
        key={index}
      />
    ));

  return (
    <div className={`mini-room-gallery flow-${flow}`}>
      {/* room tester for room and given image */}
      <RoomTester
        size={size}
        source={source}
        room={rooms[currRoom]}
        small={small}
      />
      {/* thumbnail options */}
      <div className={`mini-room-gallery-thumbnails flow-${flow}`}>
        {imageThumbnails}
      </div>
    </div>
  );
};
