import React from 'react';
import _ from 'lodash';

import { ClassicButton } from '../../../components';
import { DateHydratedOrder } from '../../../types';

import { PastOrder } from './PastOrder';

export type Props = {
  orders: DateHydratedOrder[];
  view: (index: number) => void;
};

export const OrderHistoryTable = ({ orders, view }: Props) => {
  return (
    <>
      <div className="order-history-content">
        <>
          <div>
            <p className="purple large-text underline flex-cell center-align-col order-history-header">
              <span className="invisible">blank</span>
            </p>
          </div>
          <div>
            <p className="purple large-text underline flex-cell horizontal-padded-cell order-history-header">
              Date
            </p>
          </div>
          <div>
            <p className="purple large-text underline flex-cell center-align-col order-history-header">
              Order #
            </p>
          </div>
        </>
      </div>
      <div className="order-history-content-container custom-scroll">
        <div className="order-history-content">
          {!_.isEmpty(orders) &&
            orders.map((order, index) => (
              <PastOrder
                date={order.date!}
                month={order.month!}
                year={order.year!}
                orderNum={order.orderNum!}
                key={index}
                click={() => view(index)}
                clickable
                image={order.suborders![0]?.items[0].pixImageUrl ?? ''}
              />
            ))}
        </div>
      </div>
      <div className="order-again-button">
        <ClassicButton
          color="eggplant"
          action={() => (window.location.href = '/order')}
          value="Start Another Order"
        />
      </div>
    </>
  );
};
