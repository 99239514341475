import _ from 'lodash';

import { averageArr, findMinIndex, getDecimal, getHex } from '../helpers';
import { Hex, RGB } from '../types';

import { colorPalette, standardColors, fillOneBigPixel } from '.';

export const PIX_ACROSS_BLOCK = 32;
export const INCHES_PER_BLOCK = 10;

/*
 * Pixelater
 * Pixelates image, counts up pixel colors
 * imageData: original imageData object from canvas
 * horBlocks: how many 10" x 10" blocks across to split image into
 * vertBlocks: how many 10" x 10" blocks down to split image into
 */

export type RGBArray = RGB[];
export type HexCounter = {
  count: number;
  hex: string;
  color: string;
};
export type HexArray = HexCounter[];

export class Pixelater {
  imageData: ImageData;
  data: Uint8ClampedArray;
  width: number;
  height: number;
  horBlocks: number;
  vertBlocks: number;
  pixFromRight: number;
  pixFromLeft: number;
  pixFromTop: number;
  pixFromBottom: number;
  newHeight: number;
  newWidth: number;
  pixToCombine: number;
  colorCounts: number[];
  colorMap: number[];
  rgbArr: RGBArray;
  hexArr: HexArray;

  constructor(imageData: ImageData, horBlocks: number, vertBlocks: number) {
    this.imageData = imageData; // original imageData object
    this.data = imageData.data; // original pixel array

    this.width = imageData.width; // original width in pixels
    this.height = imageData.height; // original height in pixels

    this.horBlocks = horBlocks; // horizontal blocks across image
    this.vertBlocks = vertBlocks; // vertical blocks down image

    // pix that need to be eliminated from sides so condenses evenly
    const pixFromSide = this.width % (PIX_ACROSS_BLOCK * horBlocks);
    this.pixFromRight = Math.floor(pixFromSide / 2); // pix to delete from right
    this.pixFromLeft = Math.ceil(pixFromSide / 2); // pix to delete from left

    // pix that need to be eliminated from top/bottom so condenses evenly
    const pixFromVert = this.height % (PIX_ACROSS_BLOCK * vertBlocks);
    this.pixFromTop = Math.floor(pixFromVert / 2); // pix to delete from top
    this.pixFromBottom = Math.ceil(pixFromVert / 2); // pix to delete from bottom

    this.newHeight = this.height - this.pixFromTop - this.pixFromBottom; // cropped height in pixels
    this.newWidth = this.width - this.pixFromLeft - this.pixFromRight; // cropped width in pixels

    const pixPerBlockSide = (this.width - pixFromSide) / this.horBlocks; // pix across/down each block
    this.pixToCombine = pixPerBlockSide / PIX_ACROSS_BLOCK; // how many pix across/down become one bix pix

    this.colorCounts = new Array(colorPalette.length); // counts how many of each color in color palette
    // are used in pixelated image

    for (let i = 0; i < this.colorCounts.length; i++) {
      // initializes color counts to 0 since they all start
      this.colorCounts[i] = 0; // having never been used
    }

    this.colorMap = []; // array of indices of color palette colors, one element for each enlarged pixel

    this.rgbArr = []; // tracks objects of {red, green, blue} -- dual purpose
    // in client algorithm, holds RGB equivalents of color palette
    // in palette algorithm, holds RGB values of every enlarged pixel in image

    this.hexArr = []; // holds hex strings of every enlarged pixel in image

    // initializes color palette
    this.initColors(colorPalette, this.rgbArr);
  }

  /*
   * pixelate
   * Should be called to trigger entire pixelation process
   * For client code
   * Drives entire process of shrinking, pixelating, and matching to color palette
   * Returns updated imageData object of potentially new dimensions
   */
  pixelate = (): ImageData | null => {
    // returns null if not enough pixels to begin with
    if (
      this.width < this.horBlocks * PIX_ACROSS_BLOCK ||
      this.height < this.vertBlocks * PIX_ACROSS_BLOCK
    ) {
      return null;
    }

    this.shrink();
    this.condense();
    this.convertToColorPalette();
    return this.imageData;
  };

  /*
   * pixelateWithoutPalette
   * Should be called to trigger entire pixelation process
   * For color palette code
   * Drives entire process of shrinking, pixelating, and counting up pixelated colors to output
   * Returns updated imageData which may be smaller
   */
  pixelateWithoutPalette = (): ImageData | null => {
    // returns null if not enough pixels to begin with
    if (
      this.width < this.horBlocks * PIX_ACROSS_BLOCK ||
      this.height < this.vertBlocks * PIX_ACROSS_BLOCK
    ) {
      return null;
    }

    this.shrink();
    this.condense();
    this.findCommonColors();
    this.standardizeColors();
    this.findColorNames();
    return this.imageData;
  };

  /*
   * initColors
   * Converts array of hex strings into array of RGB objects
   * fromArr: array of hex strings to pull from
   * toArr: array to put RGB objects into
   */
  initColors = (fromArr: Hex[], toArr: RGBArray) => {
    let rgb, hex;

    for (let i = 0; i < fromArr.length; i++) {
      hex = fromArr[i];

      rgb = getDecimal(hex);
      toArr.push(rgb);
    }
  };

  /*
   * shrink
   * Copies all non-cropped pixels over to new, smaller imageData object
   * Gets rid of extraneous pixels so pixels will condense evenly into larger pixels
   */
  shrink() {
    // if don't need to crop anything, return to save time
    if (
      this.pixFromTop === 0 &&
      this.pixFromBottom === 0 &&
      this.pixFromLeft === 0 &&
      this.pixFromRight === 0
    ) {
      return;
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = this.newWidth;
    canvas.height = this.newHeight;

    const newImageData = context!.createImageData(
      this.newWidth,
      this.newHeight
    );

    let index = 0;
    let newIndex = 0;

    // for all non-cropped pixels vertically
    for (let i = this.pixFromTop; i < this.height - this.pixFromBottom; i++) {
      // for all non-cropped pixels horizontally
      for (let j = this.pixFromLeft; j < this.width - this.pixFromRight; j++) {
        // grab correct image based on row and column
        index = i * this.width * 4 + j * 4;

        newImageData.data[newIndex] = this.data[index];
        newImageData.data[newIndex + 1] = this.data[index + 1];
        newImageData.data[newIndex + 2] = this.data[index + 2];
        newImageData.data[newIndex + 3] = this.data[index + 3];

        newIndex += 4;
      }
    }

    this.imageData = newImageData;
    this.data = newImageData.data;
  }

  /*
   * condense
   * Condense pixels into larger pixels with the pixels around them
   * Forms a square of pixels based on how many pixels need to combine to create the correct number
   */
  condense() {
    // 3 arrays to track RGB values in the block of pixels to be condensed
    const reds: number[] = [];
    const greens: number[] = [];
    const blues: number[] = [];

    let red, green, blue;
    let index = 0;

    // big pixels across and down the entire image
    const bigPixAcross = this.newWidth / this.pixToCombine;
    const bigPixDown = this.newHeight / this.pixToCombine;

    let startOfBigPix = 0;
    let startOfBigPixRow = 0;

    // for every big pixel vertically
    for (let bigRow = 0; bigRow < bigPixDown; bigRow++) {
      // for every big pixel horizontally
      for (let bigCol = 0; bigCol < bigPixAcross; bigCol++) {
        // mark the index at the top left corner of the big pixel
        startOfBigPix = index;

        // for every small pixel in the big pixel, vertically
        for (let i = 0; i < this.pixToCombine; i++) {
          // mark the index at the left of the pixel row within a big pixel
          startOfBigPixRow = index;

          // for every small pixel in the big pixel, horizontally
          for (let j = 0; j < this.pixToCombine; j++) {
            reds.push(this.data[index]);
            greens.push(this.data[index + 1]);
            blues.push(this.data[index + 2]);

            index += 4;
          }
          // move to next row in big pixel
          index = startOfBigPixRow + this.newWidth * 4;
        }
        // for all the pixels within the big pixel, average their RGB values
        red = averageArr(reds);
        green = averageArr(greens);
        blue = averageArr(blues);

        reds.splice(0);
        greens.splice(0);
        blues.splice(0);

        fillOneBigPixel(
          this.data,
          startOfBigPix,
          this.pixToCombine,
          this.newWidth,
          red,
          green,
          blue
        );

        // move to next big pixel to right
        index = startOfBigPix + this.pixToCombine * 4;
      }
      // move to start of first big pixel of next row
      index = index + this.newWidth * (this.pixToCombine - 1) * 4;
    }
  }

  /*
   * convertToColorPalette
   * Converts already condensed pixels and matches them to closest color within color palette
   */
  convertToColorPalette() {
    let index = 0;
    let distances: number[] = [];
    let replacementIndex: number;

    // big pixels across and down the entire image
    const bigPixAcross = this.newWidth / this.pixToCombine;
    const bigPixDown = this.newHeight / this.pixToCombine;

    let startOfBigPix = 0;
    let startOfBigPixRow = 0;

    // for every big pixel vertically
    for (let bigRow = 0; bigRow < bigPixDown; bigRow++) {
      // for every big pixel horizontally
      for (let bigCol = 0; bigCol < bigPixAcross; bigCol++) {
        // mark the index at the top left corner of the big pixel
        startOfBigPix = index;

        // for every small pixel in the big pixel, vertically
        for (let i = 0; i < this.pixToCombine; i++) {
          // mark the index at the left of the pixel row within a big pixel
          startOfBigPixRow = index;

          // for every small pixel in the big pixel, horizontally
          for (let j = 0; j < this.pixToCombine; j++) {
            // only calculate distance and update color map once per big pix
            if (index === startOfBigPix) {
              for (let color = 0; color < this.rgbArr.length; color++) {
                distances.push(
                  this.calcDistance(
                    this.data[index],
                    this.data[index + 1],
                    this.data[index + 2],
                    this.rgbArr[color].red,
                    this.rgbArr[color].green,
                    this.rgbArr[color].blue
                  )
                );
              }
              replacementIndex = findMinIndex(distances); // grab the color it's closest to

              distances.splice(0); // reset distances arr

              this.colorMap.push(replacementIndex);
              this.colorCounts[replacementIndex] += 1;
            }

            this.data[index] = this.rgbArr[replacementIndex!].red;
            this.data[index + 1] = this.rgbArr[replacementIndex!].green;
            this.data[index + 2] = this.rgbArr[replacementIndex!].blue;

            index += 4;
          }
          // move to next row in big pixel
          index = startOfBigPixRow + this.newWidth * 4;
        }

        // move to start of next big pixel across
        index = startOfBigPix + this.pixToCombine * 4;
      }
      // move to start of first big pixel of next row
      index = index + this.newWidth * (this.pixToCombine - 1) * 4;
    }
  }

  /*
   * findCommonColors
   * Tracks original RGB values of every big pixel in pixelated image
   */
  findCommonColors() {
    const pixInBigPixRow = this.newWidth * this.pixToCombine;
    let rgb;

    for (let pix = 0; pix < this.data.length; pix += 4) {
      // once per big pixel
      if (
        (pix / 4) % pixInBigPixRow < this.newWidth &&
        (pix / 4) % this.pixToCombine === 0
      ) {
        rgb = {
          red: this.data[pix],
          green: this.data[pix + 1],
          blue: this.data[pix + 2],
        };
        this.rgbArr.push(rgb); // add RGB values of that big pix to arr
      }
    }
  }

  /*
   * standardizeColors
   * Populate this.hexArr by converting RGB to hex and counting how many times colors occur
   */
  standardizeColors() {
    let hex: string;
    let i: number;

    for (let rgb of this.rgbArr) {
      hex = getHex(rgb.red, rgb.green, rgb.blue);
      // eslint-disable-next-line
      i = _.findIndex(this.hexArr, (hexObj: HexCounter) => hexObj.hex === hex);

      // if color already in array, increment its count
      if (i > -1) {
        this.hexArr[i].count++;
      }
      // if first time adding this color, add object template with count 1
      else {
        this.hexArr.push({
          count: 1,
          hex: hex,
          color: '',
        });
      }
    }
  }

  /*
   * findColorNames
   * Based on RGB values, converts them to hex and finds the nearest "standard" color (e.g. red)s
   */
  findColorNames() {
    let standardRGBArr: RGBArray = [];
    this.initColors(
      standardColors.map(({ hex }) => hex),
      standardRGBArr
    );

    let distances: number[] = [];
    let replacementIndex;

    for (let i = 0; i < this.rgbArr.length; i++) {
      for (let standardRGB of standardRGBArr) {
        distances.push(
          this.calcDistance(
            this.rgbArr[i].red,
            this.rgbArr[i].green,
            this.rgbArr[i].blue,
            standardRGB.red,
            standardRGB.green,
            standardRGB.blue
          )
        );
      }

      // finds closest standard color
      replacementIndex = findMinIndex(distances);
      this.hexArr[i].color = standardColors[replacementIndex].color;

      distances.splice(0);
    }
  }

  /*
   * calcDistance
   * Helper function to calculate the RGB distance between 2 colors
   * currRed, currGreen, currBlue: RGB values for color 1
   * refRed, refGreen, refBlue: RGB values for color 2
   */
  calcDistance = (
    currRed: number,
    currGreen: number,
    currBlue: number,
    refRed: number,
    refGreen: number,
    refBlue: number
  ) => {
    // finds difference between sets of RGB values
    const rawRedDist = currRed - refRed;
    const rawGreenDist = currGreen - refGreen;
    const rawBlueDist = currBlue - refBlue;

    // squares distances so they're all positive
    const redDist = rawRedDist * rawRedDist;
    const greenDist = rawGreenDist * rawGreenDist;
    const blueDist = rawBlueDist * rawBlueDist;

    // returns their sum
    return redDist + greenDist + blueDist;
  };

  /*
   * getColorCounts
   * Getter for color palette colors and how many of each are used
   */
  getColorCounts() {
    return this.colorCounts.slice();
  }

  /*
   * getColorMap
   * Getter for array of big pixels and their colors
   */
  getColorMap() {
    return this.colorMap.slice();
  }

  /*
   * getColorData
   * Getter for data of which hex values are in pixelated image and how many times
   */
  getColorData() {
    return this.hexArr.slice();
  }
}
