import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Vimeo from '@u-wave/react-vimeo';

import { isTablet, isSidewaysTablet, isSmallDesktop } from '../../../helpers';

import { AboutBottom } from './AboutBottom';
import './AboutPage.css';

export const AboutPage = () => {
  const [desktop, setDesktop] = useState<boolean>(isSmallDesktop());
  const [sidewaysTablet, setSidewaysTablet] = useState<boolean>(
    isSidewaysTablet()
  );
  const [tablet, setTablet] = useState<boolean>(isTablet());

  /* checks for window size on mount */
  useEffect(() => {
    const onResize = () => {
      setDesktop(isSmallDesktop());
      setSidewaysTablet(isSidewaysTablet());
      setTablet(isTablet());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <>
      {!tablet && (window.location as any).path !== '/' && <Redirect to="/" />}
      <div className="main-slider-page about-page">
        <div className="about-page-inner">
          <div className="about-left">
            <div className="about-slider-container">
              <Vimeo
                video="504492254"
                width={300}
                style={{ margin: desktop ? '5px' : '15% 1.5em 0 0' }}
              />
            </div>
          </div>
          <div className="about-copy-container custom-scroll">
            <div>
              <h2>How to BrixPix</h2>
              <br />
              {!sidewaysTablet && <AboutBottom />}
            </div>
          </div>
        </div>
        {sidewaysTablet && <AboutBottom />}
      </div>
    </>
  );
};
