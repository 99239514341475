import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { isTablet, isSmallDesktop } from '../../../helpers';

import { galleryImages as images } from './galleryImages';
import { GalleryNoControls } from './GalleryNoControls';
import { GalleryWithControls } from './GalleryWithControls';
import './GalleryPage.css';

export const GalleryPage = () => {
  const [tablet, setTablet] = useState<boolean>(isTablet());
  const [desktop, setDesktop] = useState<boolean>(isSmallDesktop());

  const [currImageIndex, setCurrImageIndex] = useState<number>(0);
  const [shape, setShape] = useState<string>('L');

  const selectImage = (index: number) => {
    setShape(images[index].shape);
    setCurrImageIndex(index);
  };

  /* checks for window size on mount */
  useEffect(() => {
    const onResize = () => {
      setTablet(isTablet());
      setDesktop(isSmallDesktop());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div className={`main-slider-page gallery-page`}>
      {!tablet && (window.location as any).path !== '/' && <Redirect to="/" />}
      {desktop ? (
        <GalleryNoControls
          image={images[currImageIndex].src}
          first={currImageIndex === 0}
          last={currImageIndex === images.length - 1}
          nextImage={() => selectImage(currImageIndex + 1)}
          prevImage={() => selectImage(currImageIndex - 1)}
        />
      ) : (
        <GalleryWithControls
          currImageIndex={currImageIndex}
          shape={shape}
          selectImage={selectImage}
        />
      )}
    </div>
  );
};
