import React from 'react';

import { ErrorMessage } from './ErrorMessage';

/*
 * SelectInput
 * Standard select input component
 */

export type Props = {
  name: string;
  options: string[];
  change: (
    setter: (value: string) => void,
    messageSetter: (message: string) => void,
    value: string
  ) => void;
  value: string;
  setter: (value: string) => void;
  message: string;
  messageSetter: (message: string) => void;
};

export const SelectInput = ({
  name,
  options,
  change,
  value,
  setter,
  message,
  messageSetter,
}: Props) => {
  // dynamically creates options based on props
  const optionComponents =
    options &&
    options.map((option, index) => (
      <option value={option} key={index}>
        {option}
      </option>
    ));

  return (
    <div className="select-container">
      {/* label */}
      <label>{name}</label>
      {/* select */}
      <div className="select-wrapper">
        <select
          value={value}
          name={name}
          className="select-input"
          onChange={(e) => change(setter, messageSetter, e.target.value)}
        >
          {optionComponents}
        </select>
      </div>
      {/* error */}
      <ErrorMessage message={message} />
    </div>
  );
};
