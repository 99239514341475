import { RGB, Hex } from '../types';
/*
 * getDecimal
 * Converts a hex string to its corresponding RGB values
 * hex: base 16 string to convert
 * Returns RGB object
 */
export const getDecimal = (hex: Hex): RGB => {
  let r1, r2, r, g1, g2, g, b1, b2, b, rgb;

  // grabs individual number equivalents
  r1 = getDecimalDig(hex.charAt(1));
  r2 = getDecimalDig(hex.charAt(2));
  g1 = getDecimalDig(hex.charAt(3));
  g2 = getDecimalDig(hex.charAt(4));
  b1 = getDecimalDig(hex.charAt(5));
  b2 = getDecimalDig(hex.charAt(6));

  // converts base 16 to base 10
  r = 16 * r1 + r2;
  g = 16 * g1 + g2;
  b = 16 * b1 + b2;

  rgb = {
    red: r,
    green: g,
    blue: b,
  };

  return rgb;
};

/*
 * getDecimalDig
 * Converts a single hex char 0-f to its equivalent JS number 0-15
 * Assumes valid hex char, uppercase or lowercase
 * hexChar: one-char hex string
 */
export const getDecimalDig = (hexChar: string): number => {
  if (/[A-Z]/.test(hexChar)) {
    hexChar = hexChar.toLowerCase();
  }
  switch (hexChar) {
    case 'a':
      return 10;
    case 'b':
      return 11;
    case 'c':
      return 12;
    case 'd':
      return 13;
    case 'e':
      return 14;
    case 'f':
      return 15;
    default:
      return parseInt(hexChar);
  }
};

/*
 * getHex
 * Creates and returns 6 digit hex string ('#' is a 7th digit, e.g. #ffffff)
 */
export const getHex = (red: number, green: number, blue: number): string => {
  let redHex, greenHex, blueHex;

  redHex = getHexDyad(red);
  greenHex = getHexDyad(green);
  blueHex = getHexDyad(blue);

  return '#' + redHex + greenHex + blueHex;
};

/*
 * getHexDyad
 * Converts a decimal number into 2 hex digits
 * Assumes values 0-255
 * dec: base 10 number to be converted
 */
export const getHexDyad = (dec: number): string => {
  const dig1 = Math.floor(dec / 16);
  const dig2 = dec % 16;

  return getHexDig(dig1) + getHexDig(dig2);
};

/*
 * getHexDig
 * Converts decimal number to equivalent hex character
 * Assumes values 0-15
 * dig: base 10 number to convert
 */
export const getHexDig = (dig: number): string => {
  switch (dig) {
    case 10:
      return 'a';
    case 11:
      return 'b';
    case 12:
      return 'c';
    case 13:
      return 'd';
    case 14:
      return 'e';
    case 15:
      return 'f';
    default:
      return `${dig}`;
  }
};
