import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import ReactGA from 'react-ga';

import { Header, Footer } from './components';
import { AuthContextProvider } from './context';
import { removeCurrentBlade } from './local-storage';
import { onBladePage, RouteChangeTracker } from './helpers';
import {
  Account,
  Admin,
  Cart,
  Checkout,
  Contact,
  FAQ,
  ForgotPassword,
  GalleryMobile,
  GetStartedMobile,
  HowToBrixPixMobile,
  Landing,
  Login,
  NotFound,
  Order,
  PricingMobile,
  PrivacyPolicy,
  ResetError,
  ResetPassword,
  Signup,
  TermsConditions,
} from './pages';
import {
  AdminRoute,
  MobileRoute,
  PrivateRoute,
  PublicRoute,
  ShoppingRoute,
} from './routes';

import './styles/Form.css';
import './styles/Error.css';
import './styles/General.css';
import './styles/Slider.css';
import usePageTracking from './helpers/usePageTracking';

// google analytics
//const TRACKING_ID = 'UA-190448776-2';
//ReactGA.initialize(TRACKING_ID);
//ReactGA.initialize('UA-190448776-2', { standardImplementation: true });
//ReactGA.pageview(window.location.pathname + window.location.search);

/*
 * App
 * Top-level component
 * Contains routes for entire web app
 * Imports general stylesheets
 */

const App = () => {
  //usePageTracking();
  useEffect(() => {
    //console.log("ssf");
    //const TRACKING_ID = 'UA-190448776-2';
    //ReactGA.initialize(TRACKING_ID)
    //ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (!onBladePage()) {
      removeCurrentBlade();
    }
  }, []);


  return (
    <AuthContextProvider>
      <Router>
        <div className="App">
          <Header />
          <Switch>
            <PublicRoute path="/" exact component={Landing} />
            <MobileRoute
              redirect="/"
              path="/how-to-brixpix"
              exact
              component={HowToBrixPixMobile}
            />
            <MobileRoute
              redirect="/"
              path="/pricing"
              exact
              component={PricingMobile}
            />
            <MobileRoute
              redirect="/"
              path="/gallery"
              exact
              component={GalleryMobile}
            />
            <MobileRoute
              redirect="/"
              path="/start-order"
              exact
              component={GetStartedMobile}
            />
            <PublicRoute restricted path="/signup" exact component={Signup} />
            <PublicRoute restricted path="/login" exact component={Login} />
            <PublicRoute
              restricted
              path="/forgot-password"
              component={ForgotPassword}
            />
            <PublicRoute
              restricted
              path="/reset/:token"
              component={ResetPassword}
            />
            <PublicRoute
              restricted
              path="/reset-error"
              component={ResetError}
            />
            <PublicRoute path="/order" exact component={Order} />
            <PublicRoute path="/cart" exact component={Cart} />
            <PublicRoute path="/contact" exact component={Contact} />
            <PrivateRoute path="/account" exact component={Account} />
            <PublicRoute
              path="/terms-and-conditions"
              exact
              component={TermsConditions}
            />
            <PublicRoute
              path="/privacy-policy"
              exact
              component={PrivacyPolicy}
            />
            <PublicRoute path="/faq" exact component={FAQ} />
            <ShoppingRoute path="/checkout" exact component={Checkout} />
            <AdminRoute path="/admin" exact component={Admin} />
            <PublicRoute component={NotFound} />
            <RouteChangeTracker />
          </Switch>
          <Footer />
        </div>
      </Router>
    </AuthContextProvider>
  );
};

export default App;