import React from 'react';

import { getSidewaysDisplacement } from '../helpers';

import './Blade.css';

/*
 * Blade
 * Stationary or sliding blade in UI layer
 */

export type BladePosition = 'left' | 'right';
export type BladePagePosition = BladePosition | 'center';

export type Props = {
  index: number; // small numbers further left
  position: BladePosition;
  color: 'purple' | 'blue';
  value: string; // undefined behavior if label more than 4 words
  frozen?: boolean;
  updateBlades?: (position: 'left' | 'right', index: number) => void;
  totalBlades?: number; // must be included if NOT frozen
};

export const Blade = ({
  index,
  position,
  color,
  value,
  frozen,
  updateBlades,
  totalBlades,
}: Props) => {
  const bladeWidth = '3em';
  const leftDist = index;
  const rightDist = totalBlades != null ? totalBlades - index : 0;

  const bladeStyle =
    position === 'left'
      ? { transform: `translateX(calc(${leftDist} * ${bladeWidth}))` }
      : {
          transform: `translateX(calc(100vw - calc(${bladeWidth}) * ${rightDist}))`,
        };

  const displacement = getSidewaysDisplacement(value, color === 'blue');
  const labelStyle = {
    transform: `rotate(-90deg) translate(-50%, ${displacement}em)`,
  };

  return (
    <div
      className={`blade blade${index + 1} ${color}-blade ${position} ${
        frozen ? 'neutral-clickable' : 'clickable'
      }`}
      onClick={() =>
        !frozen && updateBlades != null && updateBlades(position, index)
      }
      style={bladeStyle}
    >
      {/* blade label */}
      <div className="blade-label-container">
        <p className={`blade-label`} style={labelStyle}>
          {value}
        </p>
      </div>
    </div>
  );
};
