import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { getSizeData } from '../../../api';
import { SlidingImage } from '../../../components';
import { hercString, hercStringPix } from '../../../media';
import { isTablet, isSmallDesktop, isMediumDesktop } from '../../../helpers';
import { Size } from '../../../../../common/types';

import { PricingBottom } from './PricingBottom';
import './PricingPage.css';

/*
 * PricingPage
 * Displays pricing info on main page
 */

export const PricingPage = () => {
  // hold info sorted by shape
  const [squareOptions, setSquareOptions] = useState<Size[] | null>(null);
  const [portraitOptions, setPortraitOptions] = useState<Size[] | null>(null);
  const [landscapeOptions, setLandscapeOptions] = useState<Size[] | null>(null);

  const [tablet, setTablet] = useState<boolean>(isTablet());
  const [desktop, setDesktop] = useState<boolean>(isMediumDesktop());
  const [smallDesktop, setSmallDesktop] = useState<boolean>(isSmallDesktop());

  /* checks for window size on mount */
  useEffect(() => {
    const onResize = () => {
      setDesktop(isMediumDesktop());
      setSmallDesktop(isSmallDesktop());
      setTablet(isTablet());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  /* on mount, initialize prices */
  useEffect(() => {
    const initializeSizes = async () => {
      const squares = await getSizeData({
        keys: ['shape'],
        values: ['Square'],
      });
      setSquareOptions(squares.data.data);

      const portraits = await getSizeData({
        keys: ['shape'],
        values: ['Portrait'],
      });
      setPortraitOptions(portraits.data.data);

      const landscapes = await getSizeData({
        keys: ['shape'],
        values: ['Landscape'],
      });
      setLandscapeOptions(landscapes.data.data);
    };

    initializeSizes();
  }, []);

  /*
   * getPricingRows
   * creates components for pricing for each size
   */
  const getPricingRows = (options: Size[] | null): JSX.Element[] | null => {
    return (
      options &&
      options.map((option, index) => (
        <React.Fragment key={index}>
          <p className="purple left-align-col indented-col">{option.size}</p>
          <p>
            {option.imperial.width} x {option.imperial.height}
          </p>
          <p>{option.price.toFixed(2)}</p>
        </React.Fragment>
      ))
    );
  };

  return (
    <div className={`main-slider-page pricing-page`}>
      {!tablet && (window.location as any).path !== '/' && <Redirect to="/" />}
      <div className="pricing-inner">
        <div className="pricing-section custom-scroll">
          <h1 className="purple">Pricing</h1>
          <p className="purple">
            No one likes being surprised at check-out, so in an effort to be
            up-front with our customers, you can find our prices-by-size below.
          </p>
          <br />
          <div className="pricing-table-header">
            <>
              <div />
              <p className="blue">
                <b>Size (inches)</b>
              </p>
              <p className="blue">
                <b>Price (USD)</b>
              </p>
            </>
          </div>
          <div className="pricing-table-container">
            <div className="pricing-table">
              <>
                <p className="purple left-align-col">
                  <b>Square</b>
                </p>
                <div />
                <div />
              </>
              {getPricingRows(squareOptions)}
              <>
                <p className="purple left-align-col">
                  <b>Portrait</b>
                </p>
                <div />
                <div />
              </>
              {getPricingRows(portraitOptions)}
              <>
                <p className="purple left-align-col">
                  <b>Landscape</b>
                </p>
                <div />
                <div />
              </>
              {getPricingRows(landscapeOptions)}
            </div>
          </div>
          <br />
          {!smallDesktop && <PricingBottom />}
        </div>
        <div className="pricing-image">
          <SlidingImage
            original={hercString}
            pixelated={hercStringPix}
            orientation="square"
            size={desktop ? 'extra-extra-small' : 'extra-small'}
          />
          {smallDesktop && (
            <div className="pricing-right">
              <PricingBottom />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
