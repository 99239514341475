import { Orders } from './Orders';
import { Coupons } from './Coupons';

export const info = [
  {
    component: Orders,
    label: 'Orders',
  },
  {
    component: Coupons,
    label: 'Coupons',
  },
];
