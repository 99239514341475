import React from 'react';

import './PriceArea.css';

/*
 * PriceArea
 * displays all pricing info for an order, broken down
 */

export type Props = {
  subtotal: number;
  shippingPrice: number;
  tax: number;
  numItems: number;
  discount: number;
  total: number;
};

export const PriceArea = ({
  subtotal,
  shippingPrice,
  tax,
  numItems,
  discount,
  total,
}: Props) => {
  const orderTotal =
    typeof total === 'number' ? total.toFixed(2) : parseFloat(total).toFixed(2);
  const taxDisplay = tax ? tax.toFixed(2) : '0.00';
  return (
    <div className="price-sidebar">
      <h4 className="purple">Order Summary</h4>
      <br />
      <div className="sidebar-pricing-table">
        <>
          <p className="purple left-align-col sidebar-pricing-cell">
            Items ({numItems}):
          </p>
          <p className="purple right-align-col sidebar-pricing-cell">
            ${subtotal.toFixed(2)}
          </p>
        </>
        <>
          <p className="purple left-align-col sidebar-pricing-cell">
            Shipping:
          </p>
          <p className="purple right-align-col sidebar-pricing-cell">
            ${shippingPrice.toFixed(2)}
          </p>
        </>
        <>
          <p
            className={`purple left-align-col sidebar-pricing-cell ${
              discount === 0 ? 'underline' : ''
            }`}
          >
            Tax:
          </p>
          <p
            className={`purple right-align-col sidebar-pricing-cell ${
              discount === 0 ? 'underline' : ''
            }`}
          >
            ${taxDisplay}
          </p>
        </>
        <>
          {discount !== 0 && (
            <p className="rose left-align-col sidebar-pricing-cell underline">
              Discount:
            </p>
          )}
          {discount !== 0 && (
            <p className="rose right-align-col sidebar-pricing-cell underline">
              -${discount.toFixed(2)}
            </p>
          )}
        </>
        <>
          <p className="blue large-text left-align-col sidebar-pricing-special">
            Order total:
          </p>
          <p className="blue large-text right-align-col sidebar-pricing-special">
            ${orderTotal}
          </p>
        </>
      </div>
    </div>
  );
};
