/*
 * padNum
 * fills number with extra characters to set width
 */
export const padNum = (num: number, width: number, filler?: string): string => {
  filler = filler || '0';
  const numAsString = `${num}`;
  return numAsString.length >= width
    ? numAsString
    : new Array(width - numAsString.length + 1).join(filler) + numAsString;
};

/*
 * getRandomNum
 * calculates random integer between 0 and max
 */
export const getRandomNum = (max: number): number => {
  return Math.floor(Math.random() * Math.floor(max));
};
