import React from 'react';

import { monthToString } from '../../helpers';
import { PriceArea } from '../../components';
import { Order } from '../../../../common/types';

import { ItemDetail, PastOrder } from './components';
import './OrderDetails.css';

/*
 * OrderDetails
 * Displays details about one order from user's order history
 */

export type Props = {
  order: Order | undefined;
  suborderIndex: number;
};

export const OrderDetails = ({ order, suborderIndex }: Props) => {
  const superdate = new Date(order?.date ?? 0); // convert string to date
  const suborder = order?.suborders[suborderIndex]; // grab right suborder
  let numItems = 0;
  suborder?.items.forEach((item) => (numItems += item.quantity));

  // split date into parts
  const date = superdate.getDate();
  const month = monthToString(superdate.getMonth() + 1);
  const year = superdate.getFullYear();

  // retrieve order number if available
  const orderNum = order?.orderNumber
    ? 'order.orderNumber'
    : 'No order # found';

  // initializes item components based on all of order's items
  const items =
    suborder &&
    suborder.items &&
    suborder.items.map((item, index) => (
      <ItemDetail
        item={item}
        name={suborder.address.name}
        tracking={suborder.trackingNumber}
        key={index}
      />
    ));

  return (
    <div className="account-subpage order-details">
      <div className="order-details-body">
        <div className="order-details-main">
          <h3 className="purple account-header">Order Details</h3>
          <br />
          <div className="order-details-content">
            <>
              <div>
                <p className="purple large-text underline order-detail-table-header">
                  Date
                </p>
              </div>
              <div>
                <p className="purple large-text underline order-detail-table-header center-align-col">
                  Order #
                </p>
              </div>
            </>
            <PastOrder
              date={date}
              month={month}
              year={year}
              orderNum={orderNum}
            />
          </div>
          <br />
          <div className="order-detail-table">
            <>
              <div className="underline" />
              <div>
                <p className="large-text underline purple left-align-col order-detail-table-header">
                  Brix Size
                </p>
              </div>
              <div>
                <p className="large-text underline purple center-align-col order-detail-table-header">
                  Quantity
                </p>
              </div>
              <div>
                <p className="large-text underline purple center-align-col order-detail-table-header">
                  Amount
                </p>
              </div>
            </>
          </div>
          <div className="order-detail-table-container custom-scroll">
            <div className="order-detail-table">{items}</div>
          </div>
        </div>
        {order != null && (
          <aside className="order-details-aside">
            <PriceArea
              subtotal={order.price}
              tax={order.tax}
              shippingPrice={order.shippingPrice}
              discount={order.discount}
              numItems={numItems}
              total={
                order.price + order.tax + order.shippingPrice - order.discount
              }
            />
          </aside>
        )}
      </div>
    </div>
  );
};
