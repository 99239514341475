import React from 'react';

import { states, abbrevs } from '../../../helpers';
import { Address } from '../../../../../common/types';

export type Props = {
  address: Address;
};

export const AddressOption = ({ address }: Props) => {
  const stateCode = address ? abbrevs[states.indexOf(address.state)] : null;

  return (
    <div className="address-container">
      <div className="address-option">
        <p>{address.name}</p>
        <p>{address.streetAddress}</p>
        <p>{address.streetAddress2}</p>
        <p>
          {address.city}, {stateCode} {address.zip}
        </p>
      </div>
    </div>
  );
};
