export const onBladePage = () => {
  const path = window.location.pathname;
  return (
    path === '/' ||
    path === '/what-is-brixpix' ||
    path === '/pricing' ||
    path === '/gallery' ||
    path === '/start-order'
  );
};
