import React, { useEffect } from 'react';

import { MobileBladePage } from '../../../components';
import { setCurrentBlade } from '../../../local-storage';

import { AboutPage } from './AboutPage';

export const AboutMobile = () => {
  useEffect(() => {
    setCurrentBlade('about');
  }, []);

  return (
    <MobileBladePage bladeLabel="What is a BrixPix?" component={AboutPage} />
  );
};
