/*
 * punctuatePhone
 * adds parentheses, spaces, and hyphen to phone number to make it pretty
 * note:  only adds punctuation appropriate for how much has been typed so far,
 *        assumes there is currently no punctuation (so must unpunctuate first)
 */
export const punctuatePhone = (phone: string): string => {
  if (phone.length >= 7) {
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
  } else if (phone.length >= 4 && phone.length < 7) {
    return `(${phone.slice(0, 3)}) ${phone.slice(3)}`;
  }
  return '';
};

/*
 * unpunctuatePhone
 * cleans phone number of all punctuation and spaces
 * note: makes sure only numbers are typeable,
 *       makes sure deleting can't mess up punctuation
 */
export const unpunctuatePhone = (
  phone: string,
  newPhone: string
): string | null => {
  // finds difference between curr and prev phone
  let newLetterIndex = findDiffChar(phone, newPhone);

  if (newLetterIndex >= 0 && isNaN(parseInt(newPhone[newLetterIndex]))) {
    return null; // if typed new invalid char (not a number)
  }

  return removePhonePunctuation(newPhone);
};

/*
 * removePhonePunctuation
 * removes all non-number characters: (, ), -, space
 */
export const removePhonePunctuation = (phone: string): string => {
  phone = phone.replace('(', '');
  phone = phone.replace(')', '');
  phone = phone.replace('-', '');
  phone = phone.replace(' ', '');
  return phone;
};

/*
 * findDiffChar
 * finds index of differing character between 2 strings
 * returns: -1 if no char added/old string too long, -2 if new string too long,
 *          -3 if strings identical, otherwise returns index in new word of new character
 * note: assumes only one differing char between oldWord and newWord
 */
export const findDiffChar = (oldWord: string, newWord: string): number => {
  if (newWord.length < oldWord.length) {
    return -1;
  } else if (newWord.length > oldWord.length + 1) {
    return -2;
  } else {
    for (let i = 0; i < newWord.length; i++) {
      if (i >= oldWord.length || oldWord[i] !== newWord[i]) {
        return i;
      }
    }
    return -3;
  }
};
