import React, { useState, useEffect } from 'react';

import { isMobile } from '../../../helpers';
import { CartItem as CartItemType } from '../../../types';

const inchesToCm = 2.54;

/*
 * CartItem
 * Displays a single item in the cart
 * Includes details like quantity, size, price, thumbnail
 */

export type Props = {
  data: CartItemType;
  update: (index: number, value: string) => void;
  index: number;
  deleteItem: (index: number) => void;
};

export const CartItem = ({ data, update, index, deleteItem }: Props) => {
  // tracks quantity of item
  // retrieves from props or initializes to 0
  const [quantity, setQuantity] = useState<string>(
    data.quantity ? `${data.quantity}` : '1'
  );

  const [mobile, setMobile] = useState<boolean>(isMobile());

  /* checks for window size on mount */
  useEffect(() => {
    const onResize = () => {
      setMobile(isMobile());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const handleQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value;
    setQuantity(value);

    if (Number.isInteger(parseInt(value))) {
      update(index, value);
    }
  };

  /* deleteItems item if quantity goes to 0 */
  useEffect(() => {
    if (quantity === '0') {
      deleteItem(index);
    }
  }, [quantity, deleteItem, index]);

  const { price, size, width, height } = data;

  const metricWidth = (width * inchesToCm).toFixed(1);
  const metricHeight = (height * inchesToCm).toFixed(1);

  const url = data.pixImageUrl;

  return (
    <>
      {/* thumbnail */}
      <div className="grid-cell flex-cell center-align-col">
        <img
          src={url}
          className="cart-display-photo"
          alt="Pixelated thumbnail"
        />
      </div>
      {/* size info */}
      <div className="left-align-col grid-cell">
        <div className="brix-text-container">
          <p className="purple large-text brix-size">{size} BrixPix</p>
          {!mobile && (
            <p className="dark-grey">
              {width} x {height} inches / {metricWidth} cm x {metricHeight} cm
            </p>
          )}
        </div>
      </div>
      {/* quantity info */}
      <div className="grid-cell center-align-col">
        <input
          type="text"
          value={quantity}
          name="quantity"
          onChange={handleQuantity}
          className="quantity-input"
        />
        <p className="rose clickable" onClick={() => deleteItem(index)}>
          Delete
        </p>
      </div>
      {/* price */}
      <div className="grid-cell center-align-col">
        <p className="large-text purple">${price * parseInt(quantity)}</p>
      </div>
    </>
  );
};
