import React from 'react';

import { ClassicButton } from '../../../components';
import Analytics from 'analytics'
import { AnalyticsProvider } from 'use-analytics'
import googleAnalyticsPlugin from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'BrixPix dev',
  plugins: [
    googleAnalyticsPlugin({
      trackingId: 'UA-190448776-2',
    })]
})

/*
 * AccountButtonArea
 * Displays buttons to navigate page/site at button of account page
 */

export type Props = {
  backButton?: boolean;
  backAction?: () => void;
};

export const AccountButtonArea = ({ backButton, backAction }: Props) => {
  return (
    <div className="account-button-area">
      {/* optional back button */}
      {backButton && (
        <div className="account-button back-button-container">
          <ClassicButton
            color="eggplant"
            value="Go back to Order History"
            action={backAction ?? (() => { })}
          />
        </div>
      )}
      {/* help button */}
      <div className="account-button help-button-container">
        <ClassicButton
          color="blue"
          value="Need a little help?"
          action={() => {
            analytics.track("Need a little help? Pressed", {
              category: "Order History"
            });
            (window.location.href = '/contact')
          }

          }
        />
      </div>
    </div>
  );
};
