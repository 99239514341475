import React, { useState, useEffect } from 'react';

import { ProgressMarker } from '../../components';
import { isMobile } from '../../helpers';

/*
 * StepHeader
 * indicates step progress in process
 */

export type Props = {
  stepNum: number;
};

export const StepHeader = ({ stepNum }: Props) => {
  const [mobile, setMobile] = useState<boolean>(isMobile());

  /* checks for window size on mount */
  useEffect(() => {
    const onResize = () => {
      setMobile(isMobile());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div className={`step-header ${mobile ? 'small' : ''}`}>
      <ProgressMarker
        value="Shape"
        active={stepNum === 1}
        step={1}
        small={mobile}
      />
      <ProgressMarker
        value="Size"
        active={stepNum === 2}
        disabled={stepNum < 2}
        step={2}
        smallText
        small={mobile}
      />
      <ProgressMarker
        value="Upload"
        active={stepNum === 3}
        disabled={stepNum < 3}
        step={3}
        small={mobile}
      />
      <ProgressMarker
        value="Edit"
        active={stepNum === 4}
        disabled={stepNum < 4}
        step={4}
        smallText
        small={mobile}
      />
      <ProgressMarker
        value="Review"
        active={stepNum === 5}
        disabled={stepNum < 5}
        step={5}
        small={mobile}
      />
    </div>
  );
};
