import React from 'react';

import { Address } from '../../../../../common/types';

import { AddressOption } from '.';

export type Props = {
  addresses: Address[];
  wide: boolean;
  toggleNew: () => void;
  //editAddress: () => void;
};

//TODO: add a delete address button

export const SavedAddresses = ({ addresses, wide, toggleNew }: Props) => {
  const addressComponents =
    addresses &&
    addresses.map((address, index) => (
      <AddressOption key={index} address={address} />
    ));

  return (
    <div className={`saved-address-section ${wide ? 'full-width' : ''}`}>
      {addresses.length !== 0 && (
        <>
          <div className="saved-addresses">{addressComponents}</div>
          <p onClick={toggleNew} className="rose clickable address-link">
            Edit address
          </p>
          {/*<p onClick={editAddress} className="rose clickable address-link">
            Edit Address
    </p>*/}
        </>
      )}
    </div>
  );
};
