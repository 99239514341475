import React from 'react';

import './ResponsivePage.css';

export type Props = {
  component: (props: any) => JSX.Element;
  info: {
    component: () => JSX.Element;
    label: string;
    mobile?: () => JSX.Element;
    key?: string;
    url?: string;
  }[];
  defaultBlade: number;
};

export const ResponsivePage = ({ component: Component, ...props }: Props) => {
  return (
    <div className="responsive-page">
      <Component {...props} />
    </div>
  );
};
