import React, { useState, useEffect } from 'react';

import { facebook, instagram, pinterest, twitter } from '../media';
import { isMobile } from '../helpers';

import './Footer.css';

/*
 * Footer
 * Static at bottom of every page with quick links and socials
 */

export const Footer = () => {
  const [mobile, setMobile] = useState<boolean>(isMobile());

  /* checks for window size on mount */
  useEffect(() => {
    const onResize = () => {
      setMobile(isMobile());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <footer>
      {/* quick links */}
      <div className="quick-links-area">
        <div className="quick-link-container">
          <a href="/faq">
            <p className="purple small">FAQ</p>
          </a>
        </div>
        <div className="quick-link-container">
          <div className="tiny-bullet purple-background" />
        </div>
        <div className="quick-link-container">
          <a href="/terms-and-conditions">
            <p className="purple small">Terms and Conditions</p>
          </a>
        </div>
        <div className="quick-link-container">
          <div className="tiny-bullet purple-background" />
        </div>
        <div className="quick-link-container">
          <a href="/privacy-policy">
            <p className="purple small">Privacy Policy</p>
          </a>
        </div>
      </div>
      {/* chicago note */}
      <div className="footer-shipping-note flex-cell center-align-cell vertical-centered-cell">
        <p className="blue small">Packaged in and shipped from Chicago</p>
      </div>
      {/* chrome note */}
      <div className="footer-shipping-note flex-cell center-align-cell vertical-centered-cell">
        <p className="purple small">
          We highly recommend you use <b>Google Chrome</b> for the best possible
          experience
        </p>
      </div>
      {/* order note on mobile */}
      {mobile && (
        <div className="footer-shipping-note flex-cell center-align-cell vertical-centered-cell">
          <p className="purple small">
            Unfortunately, our Brixelator is currently incompatible with phones
            and tablets, so use a computer to place your order!
          </p>
        </div>
      )}
      {/* social icons */}
      <div className="social-area">
        <div className="social-icon-box">
          <a
            href="https://www.facebook.com/brixpixit"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <img src={facebook} className="social-icon" alt="facebook" />
          </a>
        </div>
        <div className="social-icon-box">
          <a
            href="https://www.instagram.com/brixpixit/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <img src={instagram} className="social-icon" alt="instagram" />
          </a>
        </div>
        <div className="social-icon-box">
          <a
            href="https://www.pinterest.com/BrixPixIt"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <img src={pinterest} className="social-icon" alt="pinterest" />
          </a>
        </div>
        <div className="social-icon-box">
          <a
            href="https://twitter.com/BrixPixIt"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <img src={twitter} className="social-icon" alt="twitter" />
          </a>
        </div>
      </div>
    </footer>
  );
};
