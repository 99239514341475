import React from 'react';
import ReactDOM from 'react-dom';
import Analytics from 'analytics'
import { AnalyticsProvider } from 'use-analytics'
import googleAnalyticsPlugin from '@analytics/google-analytics'

import App from './App';
import * as serviceWorker from './serviceWorker';

const analytics = Analytics({
  app: 'BrixPix dev',
  plugins: [
    googleAnalyticsPlugin({
      trackingId: 'UA-190448776-2',
    })]
})

analytics.page();

ReactDOM.render(
  <AnalyticsProvider instance={analytics}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </AnalyticsProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
