import React from 'react';

import { isLast } from '../../../helpers';

import './AccountMenu.css';

export type Props = {
  name: string;
  selectPage: (index: number) => void;
  info: { text: string }[];
};

export const AccountMenuDesktop = ({ name, selectPage, info }: Props) => {
  return (
    <>
      <aside className="account-sidebar">
        <div>
          <h4 className="blue account-welcome">Welcome, {name}</h4>
        </div>
        <div className="account-desktop-menu">
          {info &&
            info.map(({ text }, index) => (
              <p
                onClick={() => selectPage(index)}
                className={`
                purple 
                ${isLast(info, index) ? '' : 'underline'} 
                clickable 
                account-desktop-menu-item
              `}
                key={index}
              >
                {text}
              </p>
            ))}
        </div>
      </aside>
    </>
  );
};
