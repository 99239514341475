import { getSizeData } from '../api';
import { getDescription } from './shapes';
import { Item, Order } from '../../../common/types';
import { CartItem, ReorderItem } from '../types';

/*
 * getOrderNum
 * retrieves order number if possible
 */
export const getOrderNum = (order: Order): string => {
  if (!order) {
    return '';
  } else if (order.orderNumber) {
    return `${order.orderNumber}`;
  } else {
    return 'No order # found';
  }
};

/*
 * prepItem
 * takes item from cart and converts to item ready for completed order in database
 */
export const prepItemForOrder = (item: ReorderItem): CartItem => {
  return {
    quantity: item.quantity ? item.quantity : 1,
    width: item.sizeData.imperial.width,
    height: item.sizeData.imperial.height,
    shape: item.shape,
    size: item.sizeData.size,
    price: item.sizeData.price,
    origImageUrl: item.url,
    pixImageUrl: item.newURL,
    colorCounts: item.colorCounts,
    colorMap: JSON.stringify(item.colorMap),
  };
};

// omits height and width (in pixels) since they're irrelevant once pixelated
export const prepItemForCart = async (
  item: Item,
  quantity: number
): Promise<ReorderItem> => {
  const res = await getSizeData({
    keys: ['width', 'height'],
    values: [item.width, item.height],
  });
  const sizeData = res.data.data[0];

  return {
    quantity: quantity,
    sizeData: sizeData,
    shape: sizeData.shape,
    shapeDescription: getDescription(sizeData.shape)!,
    url: item.origImageUrl,
    newURL: item.pixImageUrl,
    colorCounts: item.colorCounts,
    colorMap: JSON.parse(item.colorMap),
  };
};
