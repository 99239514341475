import _ from 'lodash';

import { Coupon, User } from '../../../../common/types';
import { sortByDate } from '../../helpers';
import {
  getCurrentUser,
  addToAnonymousOrder,
  getAllAnonymousOrders,
  updateAnonymousOrders,
  clearAnonymousOrders,
} from '../../local-storage';
import { CartItem, ItemInfo, UserHydratedOrder } from '../../types';

import {
  getUsers,
  getUserById,
  updateCoupon,
  verifyUserToken,
} from '../endpoints';
import { addToCart, updateCart } from './userUpdates';

/*
 * addToOrder
 * adds new item to cart
 * note: stores in local storage if no user is logged in, or in database if they are
 */
export const addToOrder = (
  orderInfo: Omit<ItemInfo, 'quantity'> & { quantity?: number }
) => {
  let info = {
    ...orderInfo,
    quantity: orderInfo.quantity ? orderInfo.quantity : 1,
  };

  // checks if user logged in
  const token = getCurrentUser();

  verifyUserToken({ token: token })
    .then((res: any) => {
      // calls appropriate function based on if user logged in or not
      res.data.user
        ? addToCart(res.data.user._id, [info])
        : addToAnonymousOrder(info);
    })
    .catch((err: any) => console.log(err));
};

/*
 * getItemsInCart
 * retrieves all orders in cart currently
 * note: uses local storage if no user logged in or database if there is
 * note: MUST be used with await
 */
export const getItemsInCart = async (): Promise<CartItem[] | null> => {
  // checks if user logged in
  const token = getCurrentUser();
  const res = await verifyUserToken({ token: token });

  // calls appropriate function based on if user logged in or not
  if (res.data.user) {
    const cart = await getCart(res.data.user._id);
    return cart;
  } else {
    return getAllAnonymousOrders();
  }
};

/*
 * updateOrders
 * updates items in cart
 * note: updates in database or local storage depending on if user is logged in
 */
export const updateOrders = async (orders: CartItem[], redirect?: string) => {
  // checks if user logged in
  const token = getCurrentUser();
  const res = await verifyUserToken({ token: token });

  // calls appropriate function based on if user logged in or not
  if (res.data.user) {
    updateCart(res.data.user._id, orders, redirect);
  } else {
    updateAnonymousOrders(orders, redirect);
  }
};

/*
 * clearOrders
 * empty cart in local storage or database if user logged in
 */
export const clearOrders = async () => {
  // checks if user logged in
  const token = getCurrentUser();
  const res = await verifyUserToken({ token: token });

  // calls appropriate function based on if user logged in or not
  if (res.data.user) {
    updateCart(res.data.user._id, []);
  } else {
    clearAnonymousOrders();
  }
};

/*
 * getCart
 * retrieve items from user's cart
 */
export const getCart = async (id: string): Promise<CartItem[] | null> => {
  const res = await getUserById(id);

  if (res) {
    const user = res.data.data;
    return user.cart ? user.cart.reverse() : [];
  }
  return null;
};

/*
 * getAllOrders
 * retrieves all orders ever placed from database
 */
export const getAllOrders = async (): Promise<UserHydratedOrder[] | null> => {
  const res = await getUsers();

  if (res.data) {
    const users = res.data.data;
    const orders: UserHydratedOrder[] = [];

    users.forEach((user: User) => {
      const { firstName, lastName, email, phone } = user;
      if (user.orders) {
        orders.push.apply(
          orders,
          user.orders.map((order, index) =>
            _.merge(order, {
              firstName,
              lastName,
              email,
              phone,
              index,
            })
          )
        );
      }
    });
    orders.sort(sortByDate).reverse();
    return orders;
  }
  return null;
};

/*
 * activateCoupon
 * updates whether a specific coupon is active or not
 * parameters:
 *      coupon: required, object, coupon from database to update
 *      newStatus: required, bool, true if active or otherwise false
 * returns: nothing
 * note: reloads page upon success
 */
export const activateCoupon = (coupon: Coupon, newStatus: boolean) => {
  const { code } = coupon;
  coupon.active = newStatus;

  updateCoupon(code, coupon)
    .then((res: any) => {
      window.location.reload();
    })
    .catch((err: any) => console.log(err));
};
