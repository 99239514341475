import React from 'react';

import './Checkbox.css';

/*
 * Checkbox
 * Standard checkbox form element
 */

export type Props = {
  label?: string;
  sublabel?: string;
  check: () => void;
  checked?: boolean;
};

export const Checkbox = ({ label, sublabel, check, checked }: Props) => {
  return (
    <div className="checkbox-container" onClick={check}>
      {/* box */}
      <div className="checkbox">
        <div className="outer-checkbox" />
        {checked && <div className="inner-checkbox" />}
      </div>
      {/* label */}
      {label && (
        <div
          className={`checkbox-label ${sublabel ? 'checkbox-main-label' : ''}`}
        >
          <p className="dark-grey">{label}</p>
          {sublabel && <p className="small grey">{sublabel}</p>}
        </div>
      )}
    </div>
  );
};
