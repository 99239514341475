import { Home, HomeMobile } from './Home';
import { HowToBrixPix, HowToBrixPixMobile } from './HowToBrixPix';
import { Pricing, PricingMobile } from './Pricing';
import { Gallery, GalleryMobile } from './Gallery';
import { GetStarted, GetStartedMobile } from './GetStarted';

export const mainPageInfo = [
  {
    component: Home,
    mobile: HomeMobile,
    label: 'Home',
    key: 'home',
    url: '/',
  },
  {
    component: HowToBrixPix,
    mobile: HowToBrixPixMobile,
    label: 'How to BrixPix',
    key: 'about',
    url: '/how-to-brixpix',
  },
  {
    component: Pricing,
    mobile: PricingMobile,
    label: 'Pricing',
    key: 'pricing',
    url: '/pricing',
  },
  {
    component: Gallery,
    mobile: GalleryMobile,
    label: 'Gallery',
    key: 'gallery',
    url: '/gallery',
  },
  {
    component: GetStarted,
    mobile: GetStartedMobile,
    label: 'Get Started',
    key: 'order',
    url: '/start-order',
  },
];
