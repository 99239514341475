import React, { useState } from 'react';

import { downArrow, upArrow } from '../../../media';

export type Props = {
  name: string;
  pageIndex: number;
  info: {
    text: string;
    image: string;
  }[];
  selectPage: (index: number) => void;
};

export const AccountMenuMobile = ({
  name,
  pageIndex,
  info,
  selectPage,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="account-top-menu-container">
      <div className="account-top-menu">
        <div
          onClick={() => setOpen(!open)}
          className="clickable account-icon-section"
        >
          <img
            src={info[pageIndex].image}
            className="account-menu-icon"
            alt="account page icon"
          />
          <img
            src={open ? upArrow : downArrow}
            className="account-menu-icon"
            alt="arrow"
          />
        </div>
        <h4 className="blue account-menu-welcome">Welcome, {name}</h4>
      </div>
      {open && (
        <div className="account-menu-dropdown">
          {info &&
            info.map(({ text, image }, index) => (
              <div
                onClick={() => selectPage(index)}
                className={`account-dropdown-option clickable ${
                  pageIndex === index ? 'focused' : ''
                }`}
                key={index}
              >
                <img
                  src={image}
                  className="account-menu-icon"
                  alt="account page icon"
                />
                <p>{text}</p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
