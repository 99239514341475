import React, { useState, useEffect } from 'react';

import { ClassicButton } from '../../components';
import { Address, User } from '../../../../common/types';

import { AddressForm, NoAddresses, SavedAddresses } from './components';

import Analytics from 'analytics'
import { AnalyticsProvider } from 'use-analytics'
import googleAnalyticsPlugin from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'BrixPix dev',
  plugins: [
    googleAnalyticsPlugin({
      trackingId: 'UA-190448776-2',
    })]
})

export type Props = {
  user: User | null;
  changeStep: (shippingNumber: number) => void;
  expandSelf: (stepNum: number) => void;
  stepNum: number;
  expanded: boolean;
};

export const ShippingSection = ({
  user,
  changeStep,
  expandSelf,
  stepNum,
  expanded,
}: Props) => {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [addressDisplay, setAddressDisplay] = useState<'none' | 'new'>('none');

  useEffect(() => {
    if (user) {
      if (user.address.length !== 0) {
        setAddresses(user.address);
        //so it looks like user.address is the component in mongo that holds addresses
      } else {
        setAddressDisplay('new');
      }
    }
  }, [user]);

  const toggleNew = () => {
    addressDisplay === 'new'
      ? setAddressDisplay('none')
      : setAddressDisplay('new');
  };

  const editAddress = () => {
    if (user?.address.length !== 0) {

    }
    //edits the user's address in the database
  }

  return (
    <div className="shipping-section">
      <p
        className="large-text purple underline clickable"
        onClick={() => expandSelf(stepNum === 1 ? -1 : 1)}
      >
        Step 1: Shipping Address
      </p>
      <br />
      <div className={expanded ? 'visible-slider' : 'hidden-slider'}>
        {/* During the shipping step, you will have the option to send different BrixPix to different addresses. */}
        <p className="purple">
          Add or edit your address to ship to here.
        </p>
        {addresses.length === 0 && <NoAddresses />}
        <div className="shipping-content">
          <SavedAddresses
            toggleNew={toggleNew}
            addresses={addresses}
            wide={addressDisplay === 'none'}
          />
          <AddressForm
            visible={addressDisplay === 'new'}
            toggleNew={toggleNew}
            cancelable={addresses.length !== 0}
            user={user}
          />
        </div>
        <div className="shipping-button-container">
          <div className="shipping-button">
            <ClassicButton
              value="Go to Shipping Details"
              color="eggplant"
              action={() => {
                analytics.track("Go to Shipping Details Pressed", {
                  category: "Checkout Step"
                });
                changeStep(2);
              }}
              disabled={addresses.length === 0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
