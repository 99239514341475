// v6.0 - 40
export const colorPalette = [
  '#418FDE',
  '#426DA9',
  '#67D2DF',
  '#001A70',
  '#9EA2A2',
  '#545859',
  '#2D2926',
  '#EFEBE7',
  '#ECD898',
  '#F3CFB3',
  '#EAA794',
  '#C6A992',
  '#CA9A8E',
  '#E8927C',
  '#FEAD77',
  '#E6BAA8',
  '#FFB990',
  '#FFB25B',
  '#BD9B60',
  '#FF6A14',
  '#E87722',
  '#563D82',
  '#215732',
  '#309B42',
  '#80E0A7',
  '#A7E6D7',
  '#708573',
  '#7A7256',
  '#F0E87B',
  '#FBE122',
  '#EFBAE1',
  '#C98BDB',
  '#A50034',
  '#C6579A',
  '#DF4661',
  '#C63527',
  '#864A33',
  '#653024',
  '#6D4F47',
  '#B46A55',
];

export const standardColors = [
  {
    color: 'red',
    hex: '#ff0000',
  },
  {
    color: 'green',
    hex: '#00ff00',
  },
  {
    color: 'blue',
    hex: '#0000ff',
  },
  {
    color: 'orange',
    hex: 'ff9900',
  },
  {
    color: 'purple',
    hex: '#9900ff',
  },
  {
    color: 'pink',
    hex: '#ff00ff',
  },
  {
    color: 'yellow',
    hex: '#ffff00',
  },
  {
    color: 'cyan',
    hex: '#00ffff',
  },
  {
    color: 'grey',
    hex: '#888888',
  },
  {
    color: 'brown',
    hex: '#422315',
  },
  {
    color: 'peach',
    hex: '#eeccaa',
  },
  {
    color: 'white',
    hex: '#ffffff',
  },
  {
    color: 'black',
    hex: '#000000',
  },
];
