import React, { useState } from 'react';

import { TextInput } from '../../../components';
import { changeField } from '../../../helpers';
import { edit, times as x, checkMark as check } from '../../../media';

export type Props = {
  trackingNum: string;
  track: (suborderIndex: number, trackingNum: string) => void;
  index: number;
};

export const TrackingEditor = ({ trackingNum, track, index }: Props) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [currTracking, setCurrTracking] = useState<string>('');
  const [currTrackingMessage, setCurrTrackingMessage] = useState<string>('');

  const tracking = trackingNum ? trackingNum : 'Not shipped yet';

  const setTracking = () => {
    if (currTracking === '') {
      setCurrTrackingMessage('Make sure to enter a tracking number');
    } else {
      track(index, currTracking);
    }
  };

  return (
    <div>
      {!editing && (
        <div className="tracking-editor">
          <p className="purple">{tracking}</p>
          <img
            className="admin-edit-icon"
            src={edit}
            onClick={() => setEditing(true)}
            alt="edit"
          />
        </div>
      )}
      {editing && (
        <div className="tracking-editor">
          <TextInput
            name="currTracking"
            value={currTracking}
            setter={setCurrTracking}
            message={currTrackingMessage}
            messageSetter={setCurrTrackingMessage}
            change={changeField}
            placeholder={trackingNum ? trackingNum : 'Tracking #'}
            noLabel
          />
          <img
            className="admin-finish-icon"
            src={check}
            onClick={setTracking}
            alt="green check"
          />
          <img
            className="admin-finish-icon"
            src={x}
            onClick={() => setEditing(false)}
            alt="red x"
          />
        </div>
      )}
    </div>
  );
};
