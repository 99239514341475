import React, { useState } from 'react';

import { select as arrow, minus } from '../../../media';
import { Suborder } from '../../../../../common/types';

export type Props = {
  suborder: Suborder;
};

export const BrixPixSection = ({ suborder }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const itemCount = suborder.items.length;

  return (
    <div>
      <div className="admin-expand" onClick={() => setExpanded(!expanded)}>
        <p className="inline-block purple">{itemCount}</p>
        {expanded ? (
          <img
            className="admin-expand-icon"
            src={minus}
            alt="click to minimize"
          />
        ) : (
          <img
            className="admin-expand-icon"
            src={arrow}
            alt="click to expand"
          />
        )}
      </div>
      <div className={`pdf-section ${expanded ? 'expanded' : 'collapsed'}`}>
        {suborder.items.map((item, index) => (
          <a
            key={index}
            href={item.instructionsUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Blueprint PDF</p>
          </a>
        ))}
      </div>
    </div>
  );
};
