import React from 'react';

/*
 * SliderLabel
 * Label on standard slider
 */

export type Props = {
  value: string;
};

export const SliderLabel = ({ value }: Props) => {
  return (
    <div className="slider-label-container">
      <p className="purple large-text">{value}</p>
    </div>
  );
};
