import React, { useState, useEffect } from 'react';

import { verifyUserToken, getUserById } from '../../api';
import { getCurrentUser } from '../../local-storage';
import { User } from '../../../../common/types';

import { PreCheckout } from './pre';
import { Checkout } from './Checkout';

export const CheckoutPage = () => {
  const [stepNum, setStepNum] = useState<number>(0);
  const [page, setPage] = useState<JSX.Element | null>(null);
  const [currUser, setCurrUser] = useState<User | null>(null); // entire user object

  useEffect(() => {
    const token = getCurrentUser();
    verifyUserToken({ token: token })
      .then((res: any) => {
        if (res.data.user) {
          getUserById(res.data.user._id)
            .then((res: any) => {
              setCurrUser(res.data.data);
              setStepNum(2);
            })
            .catch((err: any) => console.log(err));
        } else {
          setStepNum(1);
        }
      })
      .catch((err: any) => console.log(err));
  }, []);

  useEffect(() => {
    switch (stepNum) {
      case 1:
        setPage(<PreCheckout />);
        break;
      case 2:
        setPage(<Checkout user={currUser} />);
        break;
      default:
        break;
    }
  }, [stepNum, currUser]);

  return <div className="checkout-container page">{page}</div>;
};
