import React, { useState, useEffect } from 'react';

import { FadingImage, SidewaysButton } from '../../../components';
import { isTablet, isSmallDesktop } from '../../../helpers';
import { downLeftArrow, downRightArrow, longRightArrow } from '../../../media';

import { images, brixPieces } from './homePageImages';
import './HomePage.css';

import Analytics from 'analytics'
import { AnalyticsProvider } from 'use-analytics'
import googleAnalyticsPlugin from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'BrixPix dev',
  plugins: [
    googleAnalyticsPlugin({
      trackingId: 'UA-190448776-2',
    })]
})

/*
 * HomePage
 * responsive homepage, main blade on desktop
 */

export const HomePage = () => {
  // screen size trackers
  const [tablet, setTablet] = useState<boolean>(isTablet());
  const [smallDesktop, setSmallDesktop] = useState<boolean>(isSmallDesktop());

  const [initial, setInitial] = useState<number>(0);

  /* checks for window size on mount */
  useEffect(() => {
    setInitial(Math.floor(Math.random() * images.length));

    const onResize = () => {
      setTablet(isTablet());
      setSmallDesktop(isSmallDesktop());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div className="main-slider-page home-page">
      <div className="homepage-copy">
        <div className="homepage-top">
          <h2 className="homepage-text">
            Building Special Moments Into Timeless Memories
          </h2>
          <p className="large-text purple homepage-text">
            Remember the good times with friends and family as you transform
            your favorite photographs into one-of-a-kind works of pixel art.
          </p>
        </div>
        {/* large devices version */}
        <div className="home-image-section desktop-home-image-section only-desktop">
          <div className="home-fade-container left-section">
            <FadingImage
              images={images.map(({ original }) => original)}
              interval={15000}
              size={smallDesktop && !tablet ? 'extra-small' : 'small'}
              rotation={350}
              initial={initial}
            />
          </div>
          <img
            src={longRightArrow}
            className="home-fade-container home-arrow"
            alt="right arrow"
          />
          <div className="home-fade-container center-section">
            <img
              src={brixPieces}
              className="brix-pieces"
              alt="brix kit parts"
            />
          </div>
          <img
            src={longRightArrow}
            className="home-fade-container home-arrow"
            alt="right arrow"
          />
          <div className="home-fade-container right-section">
            <FadingImage
              images={images.map(({ pixelated }) => pixelated)}
              interval={15000}
              size={smallDesktop ? 'small' : 'medium'}
              rotation={10}
              initial={initial}
            />
          </div>
        </div>
        <div className="home-banner only-desktop">
          <h5 className="white bold align-center">1. Upload your photo</h5>
          <h5 className="white bold align-center">
            2. Receive complete build package
          </h5>
          <h5 className="white bold align-center">3. Build your BrixPix</h5>
        </div>
        {/* small devices version */}
        <div className="home-image-section mobile-home-image-section only-mobile">
          <div className="home-fade-container left-section">
            <FadingImage
              images={images.map(({ original }) => original)}
              interval={15000}
              size={smallDesktop && !tablet ? 'extra-small' : 'small'}
              initial={initial}
            />
          </div>
          <div className="home-fade-container right-section top-section">
            <h5 className="purple bold align-center">1. Upload your photo</h5>
          </div>
          <div className="home-fade-container left-section">
            <h5 className="purple bold align-center">
              2. Receive complete build package
            </h5>
          </div>
          <div className="home-fade-container center-section">
            <img
              src={brixPieces}
              className="brix-pieces"
              alt="brix kit parts"
            />
          </div>
          <div className="home-fade-container left-section">
            <FadingImage
              images={images.map(({ pixelated }) => pixelated)}
              interval={15000}
              size={smallDesktop ? 'small' : 'medium'}
              initial={initial}
            />
          </div>
          <div className="home-fade-container right-section bottom-section">
            <h5 className="purple bold align-center">3. Build your BrixPix</h5>
          </div>
          <img
            src={downRightArrow}
            className="down-right-arrow home-arrow"
            alt="arrow pointing down and to the right"
          />
          <img
            src={downLeftArrow}
            className="down-left-arrow home-arrow"
            alt="arrow pointing down and to the left"
          />
        </div>
      </div>
      <h3 className="blue homepage-end">
        Build Your Own One-of-a-Kind Work of Art
      </h3>
      <div className="start-button">
        <SidewaysButton
          action={() => {
            analytics.track("Start Here Pressed", {
              category: "Home Page"
            });
            window.location.href = '/order';
          }
          }
          value="Start Here"
          color="blue"
          right
        />
      </div>
    </div>
  );
};
