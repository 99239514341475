import { ShapeOption } from '../../../common/types';

/*
 * data on shape options, including desriptions
 * height and width represent visually appealing sample shapes
 * shapeIndices: square = 0, portrait = 1, landscape = 2
 */
const shapeOptions = [
  {
    name: 'Square',
    height: '4',
    width: '4',
    description: 'Versatile for all your needs',
  },
  {
    name: 'Portrait',
    height: '5.3',
    width: '4',
    description: 'Great for solo pictures and selfies',
  },
  {
    name: 'Landscape',
    height: '4',
    width: '6',
    description: 'Great for group pictures and nature',
  },
];

const getDescription = (shapeName: ShapeOption): string => {
  for (var shape of shapeOptions) {
    if (shape.name === shapeName) {
      return shape.description;
    }
  }
  return '';
};

export { shapeOptions, getDescription };
