import React, { useState } from 'react';

import { validateCouponCode } from '../../../api';
import { ClassicButton, TextInput } from '../../../components';
import { changeField } from '../../../helpers';

export type Props = {
  applyCoupon: (code: string) => void;
};

export const CouponArea = ({ applyCoupon }: Props) => {
  const [code, setCode] = useState<string>('');
  const [codeMessage, setCodeMessage] = useState<string>('');

  const checkCoupon = () => {
    validateCouponCode({
      code: code,
      shouldExist: true,
    })
      .then((res: any) => {
        setCodeMessage(res.data.message);
        if (!res.data.problem) {
          applyCoupon(code);
        }
      })
      .catch((err: any) => console.log(err));
  };

  return (
    <div className="add-coupon-section">
      <TextInput
        name="Coupon Code"
        value={code}
        setter={setCode}
        message={codeMessage}
        messageSetter={setCodeMessage}
        change={changeField}
        short
      />
      {!codeMessage && (
        <p className="dark-grey small">
          Only one coupon code per order is allowed
        </p>
      )}
      <div className="coupon-button">
        <ClassicButton value="Apply" color="blue" action={checkCoupon} />
      </div>
    </div>
  );
};
