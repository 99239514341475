import React, { useState, useEffect } from 'react';

import { SidewaysButton } from '../../components';
import { isMobile } from '../../helpers';
import Analytics from 'analytics'
import { AnalyticsProvider } from 'use-analytics'
import googleAnalyticsPlugin from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'BrixPix dev',
  plugins: [
    googleAnalyticsPlugin({
      trackingId: 'UA-190448776-2',
    })]
})

/*
 * StepSelectorSection
 * area to control step flow, continuing, and reversing
 */

export type Props = {
  stepNum: number;
  noNext: boolean;
  changeStep: (stepNum: number) => void;
  finishOrder: () => void;
};

export const StepSelectorSection = ({
  stepNum,
  noNext,
  changeStep,
  finishOrder,
}: Props) => {
  // component for next step button
  const [nextButton, setNextButton] = useState<JSX.Element | null>(null);
  const [mobile, setMobile] = useState<boolean>(isMobile());

  /* checks for window size on mount */
  useEffect(() => {
    const onResize = () => {
      setMobile(isMobile());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  /* makes button either for next step or finish order, whichever is appropriate */
  useEffect(() => {
    // last step means add to cart
    if (stepNum === 5) {
      setNextButton(
        <SidewaysButton
          value="Add to Cart"
          action={finishOrder}
          color="blue"
          right
          small={mobile}
        />
      );
    }
    // any other step means next step
    else {
      setNextButton(
        <SidewaysButton
          value="Next"
          action={() => {
            if (stepNum === 1) {
              analytics.track("Shape Step Completed", {
                category: "Order Process"
              });
            }
            else if (stepNum === 2) {
              analytics.track("Size Step Completed", {
                category: "Order Process"
              });
            }
            else if (stepNum === 3) {
              analytics.track("Upload Step Completed", {
                category: "Order Process"
              });
            }
            else if (stepNum === 4) {
              analytics.track("Edit Step Completed", {
                category: "Order Process"
              });
            }
            else {
              analytics.track("Review Step Completed", {
                category: "Order Process"
              });
            }

            changeStep(stepNum + 1);


          }}
          disabled={noNext}
          color="blue"
          right
          small={mobile}
        />
      );
    }
  }, [stepNum, noNext, changeStep, finishOrder, mobile]);

  return (
    <div className="step-selector-section">
      {stepNum !== 1 && (
        <SidewaysButton
          value="Start Over"
          action={() => changeStep(1)}
          color="blue"
          left
          small={mobile}
        />
      )}
      {stepNum !== 1 && (
        <SidewaysButton
          value="Back"
          action={() => changeStep(stepNum - 1)}
          color="blue"
          left
          small={mobile}
        />
      )}
      <div className="next-step-button">{nextButton}</div>
    </div>
  );
};
