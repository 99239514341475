import axios from 'axios';

import { Coupon, CouponType, Order, User } from '../../../common/types';
import { CartItem } from '../types';

const prodURL = '/api';
const devURL = 'http://localhost:5000/api';

const api = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? prodURL : devURL,
  // withCredentials: false,
  // headers: {
  //   'Access-Control-Allow-Credentials': 'true',
  //   'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
  //   'Access-Control-Allow-Origin': `${['http://localhost:5000', 'https://brixpix.herokuapp.com', 'http://localhost:5000/api', 'https://brixpix.herokuapp.com/api', 'https://www.brixpix.com', 'https://www.brixpix.com/api', 'https://brixpix.herokuapp.com/checkout', 'https://brixpix.herokuapp.com/checkout/api']}`,
  //   'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  // }
});

// const api = axios.create({
//   baseURL: process.env.NODE_ENV === 'production' ? prodURL : devURL,
// });
//END OG CODE...

const userID = process.env.UPS_ORDERS_USER;
const userPass = process.env.UPS_ORDERS_PASSWORD;
const accessKey = process.env.UPS_LICENSE_NUMBER;


//our 3 shipping options could be UPS Ground (2 - 8 days) (Code 03), UPS Next Day Air (1 - 2 days) (Code 01), 
//and UPS 2nd Day Air (2 - 3 days) (Code 02)

var config = {
  headers: { 'Content-Type': 'text/xml', 'Access-Control-Allow-Origin': `${['http://localhost:5000', 'https://brixpix.herokuapp.com', 'http://localhost:5000/api', 'https://brixpix.herokuapp.com/api', 'https://www.brixpix.com', 'https://www.brixpix.com/api', 'https://brixpix.herokuapp.com/checkout', 'https://brixpix.herokuapp.com/checkout/api']}`, 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS', 'Access-Control-Allow-Credentials': true }
};


/*******************************************************************/
/*                         user routes                             */
/*                           at /user                              */
/*                 interacts with users with accounts              */
/*******************************************************************/

/*
 * createUser
 * creates brand new user
 * returns (via res):
 *      success: bool if succeeded or not
 *      error: string (only if did NOT succeed)
 *      message: string of description of what happened
 *      id: id of user created (only if DID succeed)
 *      token: jwt for user in current session (only if DID succeed)
 */
export const createUser = (payload: {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: number;
  password: string;
}) => api.post(`/user/create`, payload);

/*
 * updateUser
 * updates field(s) in existing user
 * returns (via res):
 *      success: bool if succeeded or not
 *      error: string (only if did NOT succeed)
 *      message: string of description of what happened
 *      id: id of user updated (only if DID succeed)
 */
export const updateUser = (id: string, payload: Partial<User>) =>
  api.put(`/user/update/${id}`, payload);

/*
 * deleteUser
 * deletes existing user in database
 * returns (via res):
 *      success: bool if succeeded or not
 *      error: string (only if did NOT succeed)
 *      message: string of description of what happened
 *      data: object for user deleted (only if DID succeed)
 */
export const deleteUser = (id: string) => api.delete(`/user/delete/${id}`);

/*
 * getUserById
 * retrieves info for specific user
 * returns (via res):
 *      success: bool if succeeded or not
 *      error: string (only if did NOT succeed)
 *      data: object for user retrieved (only if DID succeed)
 */
export const getUserById = (id: string) => api.get(`/user/${id}`);

/*
 * getUsers
 * retrieves info for all users in database
 * returns (via res):
 *      success: bool if succeeded or not
 *      error: string (only if did NOT succeed)
 *      data: array of objects for users retrieved (only if DID succeed)
 */
export const getUsers = () => api.get('/user');

/*
 * loginUser
 * creates token for user logging in
 * returns (via res):
 *      err: stirng (only if did NOT succeed)
 *      success: bool if succeeded or not
 *      token: jwt for user in current session (only if DID succeed)
 */
export const loginUser = (payload: { email: string; password: string }) =>
  api.post('/user/login', payload);

/*
 * forgotPassword
 * creates token for user resetting password and sends them email with link
 * returns (via res):
 *      err: string, (only if did NOT succeed)
 *      success: bool, if succeeded or not
 *      id: string, email of user being reset
 *      message: string, message of what's happening
 */
export const forgotPassword = (id: string) => api.get(`/user/forgot/${id}`);

/*
 * checkResetLink
 * verifies token to see if valid
 * returns (via res):
 *      success: bool, if succeeded or not
 *      id: string, email of user being reset (if token valid)
 *      message: string, message of what's happening
 */
export const checkResetLink = (token: string) =>
  api.get(`/user/reset/${token}`);

/*******************************************************************/
/*                             coupon routes                       */
/*                              at /coupon                         */
/*                    interacts with coupon system                 */
/*******************************************************************/

/*
 * createCoupon
 * creates brand new coupon
 * returns (via res):
 *      success: bool if succeeded or not
 *      error: string (only if did NOT succeed)
 *      message: string of description of what happened
 *      id: id of coupon created (only if DID succeed)
 */
export const createCoupon = (payload: {
  _id: string;
  code: string;
  type: CouponType;
  quantity: number;
  active: boolean;
}) => api.post(`/coupon/create`, payload);

/*
 * updateCoupon
 * updates field(s) in existing coupon
 * returns (via res):
 *      success: bool if succeeded or not
 *      error: string (only if did NOT succeed)
 *      message: string of description of what happened
 *      id: id of coupon updated (only if DID succeed)
 */
export const updateCoupon = (
  id: string,
  payload: {
    code: string;
    type: CouponType;
    quantity: number;
    active: boolean;
  }
) => api.put(`/coupon/update/${id}`, payload);

/*
 * deleteCoupon
 * deletes existing coupon in database
 * returns (via res):
 *      success: bool if succeeded or not
 *      error: string (only if did NOT succeed)
 *      message: string of description of what happened
 *      data: object for coupon deleted (only if DID succeed)
 */
export const deleteCoupon = (id: string) => api.delete(`/coupon/delete/${id}`);

/*
 * getCouponById
 * retrieves info for specific coupon
 * returns (via res):
 *      success: bool if succeeded or not
 *      error: string (only if did NOT succeed)
 *      data: object for coupon retrieved (only if DID succeed)
 */
export const getCouponById = (id: string) => api.get(`/coupon/${id}`);

/*
 * getCoupons
 * retrieves info for all coupons in database
 * returns (via res):
 *      success: bool if succeeded or not
 *      error: string (only if did NOT succeed)
 *      data: array of objects for coupons retrieved (only if DID succeed)
 */
export const getCoupons = () => api.get('/coupon');

/*******************************************************************/
/*                         auth routes                             */
/*                           at /auth                              */
/*                  checks authorization of users                  */
/*******************************************************************/

/*
 * verifyUserToken
 * checks token from session for validity
 * returns (via res):
 *      success: bool if succeeded or not
 *      message: string for what happened
 *      user: object for user with id that was verified with token
 */
export const verifyUserToken = (payload: { token: string }) =>
  api.post('/auth/verify', payload);

/*
 * verifyAdmin
 * checks database if specified user has admin privileges or not
 * returns (via res):
 *      message: string, describes if user is admin or not
 *      isAdmin: bool, true if admin, otherwise false
 */
export const verifyAdmin = (id: string) => api.get(`/auth/admin/${id}`);

/*******************************************************************/
/*                     validation routes                           */
/*                        at /validate                             */
/*                    validates form inputs                        */
/*******************************************************************/

/*
 * validateName
 * checks for valid name
 * returns (via res):
 *      problem: bool if there's a problem or not
 *      message: string for message to show user
 */
export const validateName = (payload: {
  name: string;
  type: 'first' | 'last';
}) => api.post('/validate/name', payload);

/*
 * validateEmail
 * checks for valid email
 * returns (via res):
 *      problem: bool if there's a problem or not
 *      message: string for message to show user
 */
export const validateEmail = (payload: {
  email: string;
  shouldExist?: boolean;
}) => api.post('/validate/email', payload);

/*
 * validatePhone
 * checks for valid phone number
 * returns (via res):
 *      problem: bool if there's a problem or not
 *      message: string for message to show user
 */
export const validatePhone = (payload: { phone: string }) =>
  api.post('/validate/phone', payload);

/*
 * validatePasswords
 * checks for valid passwords
 * returns (via res):
 *      problem: bool if there's a problem or not
 *      message: string for message to show user (or array of size 2 if w/ confirmation)
 */
export const validatePasswords = (payload: {
  password: string;
  confirmation?: string;
  checkStrength?: boolean;
  user?: string;
}) => api.post('/validate/passwords', payload);

/*
 * validateAddress
 * checks for valid address
 * returns (via res):
 *      problem: bool if there's a problem or not
 *      message: string for message to show user
 */
export const validateAddress = (payload: {
  name: string;
  type: string; // e.g. 'street address'
}) => api.post('/validate/address', payload);

/*
 * validateState
 * checks for valid state
 * returns (via res):
 *      problem: bool if there's a problem or not
 *      message: string for message to show user
 */
export const validateState = (payload: { state: string }) =>
  api.post('/validate/state', payload);

/*
 * validateZip
 * checks for valid zip code
 * returns (via res):
 *      problem: bool if there's a problem or not
 *      message: string for message to show user
 */
export const validateZip = (payload: { zip: string }) =>
  api.post('/validate/zip', payload);

/*
 * validateCouponCode
 * checks for valid coupon code
 * returns (via res):
 *      problem: bool if there's a problem or not
 *      message: string for message to show user
 */
export const validateCouponCode = (payload: {
  code: string;
  shouldExist: boolean;
}) => api.post('/validate/code', payload);

/*
 * validateCouponType
 * checks for valid coupon type
 * parameters:
 *      payload: required, object, with fields
 *          type: string, 'Percent' or 'Dollars' (case insensitive)
 * returns (via res):
 *      problem: bool if there's a problem or not
 *      message: string for message to show user
 */
export const validateCouponType = (payload: { type: CouponType }) =>
  api.post('/validate/type', payload);

/*
 * validateNumber
 * checks for valid number
 * returns (via res):
 *      problem: bool if there's a problem or not
 *      message: string for message to show user
 */
export const validateNumber = (payload: {
  name: string;
  number: string | number;
}) => api.post('/validate/number', payload);

/*
 * validateMessage
 * checks for valid message
 * returns (via res):
 *      problem: bool if there's a problem or not
 *      message: string for message to show user
 */
export const validateMessage = (payload: { message: string; type: string }) =>
  api.post('/validate/message', payload);

/*******************************************************************/
/*                       shipping routes                           */
/*                         at /shipping                            */
/*                   retrieves shipping data                       */
/*******************************************************************/


/*
 * getShippingOptions
 * retrieves shipping prices for given order
 * returns (via res):
 *      success: bool if successful or not
 *      options: array of objects with
 *          name: string for name of shipping option (e.g. 'ground shipping')
 *          time: string for time needed (e.g. '3-5 days')
 *          cost: number of cost to ship these items with this option
 */

export const getXmlBodyStr = (serviceType: string, shape: string, size: string, userZip: string) => {
  const smallPortraitWeight = '8'
  const medPortraitWeight = '9'
  const largePortraitWeight = '13'

  const smallLandscapeWeight = '7'
  const medLandscapeWeight = '9'
  const largeLandscapeWeight = '11'

  const smallSquareWeight = '7'
  const medSquareWeight = '10'
  const largeSquareWeight = '13'

  const smallMedLength = '18.5'
  const smallMedWidth = '12.5'
  const smallMedHeight = '7'

  const largeLength = '20'
  const largeWidth = '12'
  const largeHeight = '12'

  let boxLength;
  let boxWidth;
  let boxHeight;
  let weight;

  if (shape === "Square" && size === "Small") {
    boxLength = smallMedLength;
    boxWidth = smallMedWidth;
    boxHeight = smallMedHeight;
    weight = smallSquareWeight;
  }
  if (shape === "Square" && size === "Medium") {
    boxLength = smallMedLength;
    boxWidth = smallMedWidth;
    boxHeight = smallMedHeight;
    weight = medSquareWeight;
  }
  if (shape === "Square" && size === "Large") {
    boxLength = largeLength;
    boxWidth = largeWidth;
    boxHeight = largeHeight;
    weight = largeSquareWeight;
  }
  if (shape === "Portrait" && size === "Small") {
    boxLength = smallMedLength;
    boxWidth = smallMedWidth;
    boxHeight = smallMedHeight;
    weight = smallPortraitWeight;
  }
  if (shape === "Portrait" && size === "Medium") {
    boxLength = smallMedLength;
    boxWidth = smallMedWidth;
    boxHeight = smallMedHeight;
    weight = medPortraitWeight;
  }
  if (shape === "Portrait" && size === "Large") {
    boxLength = largeLength;
    boxWidth = largeWidth;
    boxHeight = largeHeight;
    weight = largePortraitWeight;
  }
  if (shape === "Landscape" && size === "Small") {
    boxLength = smallMedLength;
    boxWidth = smallMedWidth;
    boxHeight = smallMedHeight;
    weight = smallLandscapeWeight;
  }
  if (shape === "Landscape" && size === "Medium") {
    boxLength = smallMedLength;
    boxWidth = smallMedWidth;
    boxHeight = smallMedHeight;
    weight = medLandscapeWeight;
  }
  if (shape === "Landscape" && size === "Large") {
    boxLength = largeLength;
    boxWidth = largeWidth;
    boxHeight = largeHeight;
    weight = largeLandscapeWeight;
  }


  var xmlBodyStr = `<?xml version="1.0"?>
  <AccessRequest xml:lang="en-US">
    <AccessLicenseNumber>ADAA124FA72A7A55</AccessLicenseNumber>
    <UserId>${userID}</UserId>
    <Password>${userPass}</Password>
  </AccessRequest>
  <?xml version="1.0"?>
  <RatingServiceSelectionRequest xml:lang="en-US">
    <Request>
      <TransactionReference>
        <CustomerContext>Rating and Service</CustomerContext>
        <XpciVersion>1.0</XpciVersion>
      </TransactionReference>
    <RequestAction>Rate</RequestAction>
    <RequestOption>Rate</RequestOption>
    </Request>
      <PickupType>
      <Code>07</Code>
      <Description>Rate</Description>
      </PickupType>
    <Shipment>
        <Description>Rate Description</Description>
      <Shipper>
        <Name>Name</Name>
        <PhoneNumber>1234567890</PhoneNumber>
        <ShipperNumber>Ship Number</ShipperNumber>
        <Address>
          <AddressLine1>Address Line</AddressLine1>
          <City>Chicago</City>
          <StateProvinceCode>IL</StateProvinceCode>
          <PostalCode>60642</PostalCode>
          <CountryCode>US</CountryCode>
        </Address>
      </Shipper>
      <ShipTo>
        <CompanyName>Company Name</CompanyName>
        <PhoneNumber>1234567890</PhoneNumber>
        <Address>
          <AddressLine1>Address Line</AddressLine1>
          <City></City>
          <PostalCode>${userZip}</PostalCode>
          <CountryCode>US</CountryCode>
        </Address>
      </ShipTo>
      <ShipFrom>
        <CompanyName>Company Name</CompanyName>
        <AttentionName>Attention Name</AttentionName>
        <PhoneNumber>1234567890</PhoneNumber>
        <FaxNumber>1234567890</FaxNumber>
        <Address>
          <AddressLine1>Address Line</AddressLine1>
      <City>Chicago</City>
          <StateProvinceCode>IL</StateProvinceCode>
          <PostalCode>60642</PostalCode>
          <CountryCode>US</CountryCode>
        </Address>
      </ShipFrom>
      <Service>
          <Code>${serviceType}</Code> 
      </Service>
      <PaymentInformation>
            <Prepaid>
              <BillShipper>
                  <AccountNumber>Ship Number</AccountNumber>
              </BillShipper>
            </Prepaid>
      </PaymentInformation>
      <Package>
            <PackagingType>
              <Code>02</Code>
              <Description>Customer Supplied</Description>
            </PackagingType>
            <Description>Rate</Description>
            <PackageWeight>
              <UnitOfMeasurement>
                <Code>LBS</Code>
              </UnitOfMeasurement>
              <Weight>${weight}</Weight>
            </PackageWeight>
            <Dimensions>
              <Length>${boxLength}</Length>
              <Width>${boxWidth}</Width>
              <Height>${boxHeight}</Height>
            </Dimensions>
      </Package>
      <ShipmentServiceOptions>
        <OnCallAir>
      <Schedule>
        <PickupDay>02</PickupDay>
        <Method>02</Method>
      </Schedule>
        </OnCallAir>
      </ShipmentServiceOptions>
    </Shipment>
  </RatingServiceSelectionRequest>`;
  return xmlBodyStr;
}


export const getShippingOptions = (payload: {
  items: CartItem[];
  zip: string;
}) => api.post('/shipping/options', payload);

//END OG CODE...

// export const getShippingOptions = (serviceCode: string, items: CartItem[], userZip: string, itemNum: number) => {
//   var xmlBodyStr;
//   if (items.length > 0) {
//     xmlBodyStr = getXmlBodyStr(serviceCode, items[itemNum].shape, items[itemNum].size, userZip); //should return a string with the new val inside
//   }
//   return api.post('https://serene-headland-32170.herokuapp.com/https://onlinetools.ups.com/ups.app/xml/Rate', xmlBodyStr, config);
// }
//'https://serene-headland-32170.herokuapp.com/https://wwwcie.ups.com/ups.app/xml/Rate'
/*******************************************************************/
/*                        payment routes                           */
/*                          at /payment                            */
/*                  interacts with stripe routes                   */
/*******************************************************************/

/*
 * createPaymentIntent
 * initialized payment intent to track payment lifecycle
 * returns (via res):
 *      clientSecret: client secret for this payment intent
 *      id: id for this payment intent
 */
export const createPaymentIntent = (payload: {
  items: CartItem[];
  shippingIndex: number;
  zip: string;
}) => api.post('/payment/create', payload);

/*
 * updatePaymentIntent
 * update payment intent after initialization, before payment
 * returns (via res):
 *      clientSecret: client secret for this payment intent
 */
export const updatePaymentIntent = (payload: {
  items: CartItem[];
  shippingIndex: number;
  zip: string;
  id: string;
}) => api.post('/payment/update', payload);

/*
 * getTax
 * retrieve tax so server is responsible for calculating
 * returns (via res):
 *      tax: number, price in dollars of tax to add on
 */
export const getTax = (payload: { subtotal: number }) =>
  api.post('/payment/tax', payload);

/*
 * getDiscount
 * retrieve discount so server is responsible for calculating based on coupon
 * returns (via res):
 *      coypon: number, price in dollars of discount to subtract (note: this is
 *              POSITIVE)
 */
export const getDiscount = (payload: { subtotal: number; coupon: Coupon }) =>
  api.post('/payment/discount', payload);

/*******************************************************************/
/*                            email routes                         */
/*                              at /email                          */
/*                        sends automated emails                   */
/*******************************************************************/

/*
 * sendConfirmationEmail
 * sends automated confirmation email
 * returns (via res): none
 */
export const sendConfirmationEmail = (payload: {
  firstName: string;
  email: string;
  order: Order;
}) => api.post('/email/confirmation', payload);

/*
 * sendTrackingEmail
 * sends automated tracking notification email
 * returns (via res): none
 */
export const sendTrackingEmail = (payload: {
  firstName: string;
  email: string;
  order: Order;
  trackingNumber: string;
}) => api.post('/email/tracking', payload);

/*
 * sendHelpEmail
 * sends automated help email
 * returns (via res): none
 */
export const sendHelpEmail = (payload: {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
  message: string;
}) => api.post('/email/help', payload);

/*
 * sendErrorEmail
 * sends automated error email if something goes wrong during order process
 * returns (via res): none
 */
export const sendErrorEmail = (payload: {
  email: string;
  originalImage: string;
  pixelatedImage: string;
}) => api.post('/email/error', payload);

/*******************************************************************/
/*                        inventory routes                         */
/*                          at /inventory                          */
/*                   handles inventory and items                   */
/*******************************************************************/

/*
 * getSizeData
 * retrieves all size data for one size, or all of them
 * returns (via res):
 *      data: array of size data objects
 * note: returns all options if keys and/or values are unspecified
 */
export const getSizeData = (payload: {
  keys?: string[]; // e.g. 'shape' or 'size'
  values?: (string | number)[]; // parallel to above, e.g. 'Square' or 'Large'
}) => api.post('/inventory/sizes', payload);
