import { Order } from '../../../common/types';
import { PrettyDate } from '../types';

/*
 * monthToString
 * converts 1-index number representation of month to string
 */
export const monthToString = (num: number) => {
  switch (num) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      return 'Error';
  }
};

/*
 * parsePrettyDate
 * converts string of ISO date into object with parsed fields
 */
export const parsePrettyDate = (dateString: Date): PrettyDate => {
  const date = new Date(dateString);
  const dateObj = {
    date: date.getDate(),
    month: monthToString(date.getMonth() + 1),
    year: date.getFullYear(),
  };

  return dateObj;
};

/*
 * sortByDate
 * helper function for sort algorithm to sort in chronological order
 */
export const sortByDate = (order1: Order, order2: Order) => {
  const d1 = order1.date;
  const d2 = order2.date;

  if (d1 < d2) {
    return -1;
  }
  if (d1 > d2) {
    return 1;
  }
  return 0;
};
