import React, { useState, useEffect } from 'react';

import { setCurrentBlade } from '../local-storage';

import { Blade, BladePosition, BladePagePosition } from './Blade';
import './BladeManager.css';

export type Props = {
  info: {
    component: (props: { position: BladePagePosition }) => JSX.Element;
    label: string;
    mobile?: () => JSX.Element;
    key?: string;
    url?: string;
  }[];
  defaultBlade: number;
};

export const BladeManager = ({ info, defaultBlade }: Props) => {
  const numBlades = info.length;
  const bladeWidth = '3em';

  const [currBlade, setCurrBlade] = useState<number>(0);
  // false = left, true = right
  const [bladePositions, setBladePositions] = useState<boolean[]>([
    false,
    true,
    true,
    true,
    true,
  ]);

  useEffect(() => {
    setCurrBlade(defaultBlade);
    setBladePositions(info.map((block, index) => index > defaultBlade));
  }, [defaultBlade, info]);

  // allows for multiple blades moving at once
  const slideBlades = (bladePosition: BladePosition, bladeNum: number) => {
    // moving left
    let tempBladePositions = bladePositions;
    setCurrBlade(bladeNum);
    setCurrentBlade(info[bladeNum].key ?? ''); // updates local storage

    if (bladePosition === 'right') {
      for (let i = 0; i <= bladeNum; i++) {
        tempBladePositions[i] = false;
      }
    }
    // moving right
    else {
      for (let i = numBlades - 1; i > bladeNum; i--) {
        tempBladePositions[i] = true;
      }
    }
    setBladePositions(tempBladePositions.slice());
  };

  const blades =
    info &&
    info.map((block, index) => (
      <Blade
        position={bladePositions[index] ? 'right' : 'left'}
        updateBlades={slideBlades}
        index={index}
        value={block.label}
        color="purple"
        totalBlades={numBlades}
        key={index}
      />
    ));

  const pages =
    info &&
    info.map((block, index) => {
      const TagName = block.component;

      const centerIndex = currBlade;
      let position;
      if (centerIndex === index) {
        position = 'center';
      } else if (centerIndex > index) {
        position = 'left';
      } else {
        position = 'right';
      }
      const style = {
        marginLeft: `calc(${bladeWidth} * ${index + 1})`,
        width: `calc(100vw - calc(${bladeWidth} * ${numBlades}))`,
      };

      return (
        <div
          className={`blade-manager-page ${position}`}
          style={style}
          key={index}
        >
          <TagName position={position as BladePagePosition} />
        </div>
      );
    });

  return (
    <div className="blade-manager">
      {blades}
      {pages}
    </div>
  );
};
