import React from 'react';
import { Link } from 'react-router-dom';

import { logo as mainLogo } from '../media';

import { BrixButton } from './BrixButton';

export type Props = {
  admin: boolean;
  loggedIn: boolean;
  cartNum: string;
  handleLogin: () => void;
  handleLogout: () => void;
  redirecter: (redirect: string) => void;
};

export const DesktopHeader = ({
  admin,
  loggedIn,
  cartNum,
  handleLogin,
  handleLogout,
  redirecter,
}: Props) => {
  // sets login / logout button appropriately
  const loginButton = loggedIn ? (
    <BrixButton action={handleLogout} value="Logout" color="blue" />
  ) : (
    <BrixButton action={handleLogin} value="Login" color="blue" />
  );

  return (
    <>
      {/* logo */}
      <Link to="/" className="header-item">
        <img src={mainLogo} className="header-logo" alt="brixpix logo" />
      </Link>
      {/* navbar */}
      <div className="header-item navbar-item">
        {admin && (
          <BrixButton
            value="Admin"
            action={() => redirecter('/admin')}
            color="black"
          />
        )}
        {loggedIn && (
          <BrixButton
            value="Account"
            action={() => redirecter('/account')}
            color="purple"
          />
        )}
        {loginButton}
        <BrixButton
          value={`Cart (${cartNum})`}
          action={() => redirecter('/cart')}
          color="rose"
        />
        <BrixButton
          value="Contact"
          action={() => redirecter('/contact')}
          color="gold"
        />
      </div>
    </>
  );
};
