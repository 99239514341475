import _ from 'lodash';

//historyelement is a pixel in this case

export class UndoHistory<HistoryElement> {
  past: HistoryElement[];
  future: HistoryElement[];
  onUndo: (newPresent: HistoryElement) => void;
  onRedo: (newPresent: HistoryElement) => void;

  constructor(
    onUndo: (newPresent: HistoryElement) => void,
    onRedo: (newPresent: HistoryElement) => void
  ) {
    this.past = [];
    this.future = [];
    this.onUndo = onUndo;
    this.onRedo = onRedo;
  }

  canUndo = () => {
    return this.past.length > 0;
  };

  canRedo = () => {
    return this.future.length > 0;
  };

  undo = async () => { //this is where onUndo is set
    if (!this.canUndo()) {
      return;
    }
    console.log("undo called in UndoHistory")
    console.log(this.past)
    const toUndo = _.last(this.past)!; //gets last elem in the array
    this.future.unshift(toUndo); //adds last elem to front of future
    this.past = _.dropRight(this.past); //removes last elem in array

    this.onUndo(toUndo);
  };

  addToFuture = async () => {
    if (!this.canUndo()) {
      return;
    }
    console.log("addToFuture called in UndoHistory")
    console.log(this.past)
    const toUndo = _.last(this.past)!; //gets last elem in the array
    this.future.unshift(toUndo); //adds last elem to front of future
    this.past = _.dropRight(this.past); //removes last elem in array

    return toUndo
  };


  redo = async () => {
    if (!this.canRedo()) {
      console.log("couldnt redo")
      return;
    }
    console.log("redo called in UndoHistory")
    console.log(this.future)
    const toRedo = _.head(this.future)!; //gets first elem of future
    console.log(toRedo)
    this.past.push(toRedo); //adds first elem to back of past
    this.future.shift(); //removes first elem of array

    this.onRedo(toRedo);
    //console.log(this.future)
    //this is weird - for some reason theres one less elem in the array
    //puts same color for multiple for some reason?
  };

  do = async (newPresent: HistoryElement) => {
    this.past.push(newPresent);
    //this.future = []; //why does it do this? 
    console.log(this.past, this.future);
  };
}
