import React from 'react';

import { Blade } from './Blade';
import './MobileBladePage.css';

export type Props = {
  component: (props: any) => JSX.Element;
  bladeLabel: string;
};

/* already shifted if already moved down below header from responsive page */
export const MobileBladePage = ({
  component: Component,
  bladeLabel,
}: Props) => {
  return (
    <div className="mobile-page">
      <Blade
        index={0}
        position="left"
        color="purple"
        value={bladeLabel}
        frozen
      />
      <div className="room-for-blade">
        <Component />
      </div>
    </div>
  );
};
