import React from 'react';
// @ts-ignore
import Board, { Line } from 'react-legra';

import { postImage } from '../../../../helpers';
import { colorPalette } from '../../../../pixelation/Colors';

import { getIndexArr, getBrixSize } from '../pixelationUtils';

const canvasWidth = 400; // width of canvas in px

/*
 * BrixCanvas
 * handles creation and upload of brixilated image
 */

export type Props = {
  colorMap: number[] | null;
  width: number;
  height: number;
  finish: (url: string) => void;
};

export class BrixCanvas extends React.Component<Props, {}> {
  makeBrix = () => {
    const brixCanvas = document.getElementById(
      'brix-canvas'
    )! as HTMLCanvasElement;
    const context = brixCanvas.getContext('2d', {
      alpha: false,
    })!;
    const width = brixCanvas.width;
    const height = brixCanvas.height;

    // @ts-ignore
    context.webkitImageSmoothingEnabled = false;
    // @ts-ignore
    context.mozImageSmoothingEnabled = false;
    context.imageSmoothingEnabled = false;

    /* have to do it this way because there's no other clean way to see exactly
     * when a canvas is finished loading
     */
    const timer = setInterval(() => {
      const { data } = context.getImageData(0, 0, width, height);
      // if last pixel not transparent, must be done
      if (data[data.length - 1]) {
        clearInterval(timer);
        postImage(brixCanvas, this.props.finish); // upload image, finish when done
      }
    }, 200);
  };

  render() {
    const canvasHeight = (canvasWidth / this.props.width) * this.props.height;

    return (
      <>
        <Board
          width={canvasWidth}
          height={canvasHeight}
          id="brix-canvas"
          className="offscreen"
        >
          {this.props.colorMap &&
            this.props.colorMap.map((color, index) => (
              <Line
                from={getIndexArr(index, this.props.width)}
                to={getIndexArr(index, this.props.width)}
                options={{ color: colorPalette[color] }}
                bs={getBrixSize(canvasWidth, this.props.width)}
                key={index}
              />
            ))}
        </Board>
      </>
    );
  }
}
