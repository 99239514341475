import React, { useState, useEffect } from 'react';

import { BladeManager, ResponsivePage } from '../../components';
import { isTablet } from '../../helpers';

import { info } from './adminPageInfo';
import OrdersMobile from './OrdersMobile';
import './Admin.css';

export const Admin = () => {
  const [tablet, setTablet] = useState<boolean>(isTablet());
  const defaultBlade = 0;

  /* checks for window size on mount */
  useEffect(() => {
    const onResize = () => {
      setTablet(isTablet());
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <>
      {tablet ? (
        <OrdersMobile />
      ) : (
        <ResponsivePage
          component={BladeManager}
          info={info as any}
          defaultBlade={defaultBlade}
        />
      )}
    </>
  );
};
