import React from 'react';

import { SizeOption } from '../../../../../../common/types';

/*
 * CustomizeHeader
 * displays sizing info for order in progress
 */

export type Props = {
  width: number;
  height: number;
  metricHeight: number;
  metricWidth: number;
  size: SizeOption;
};

export const CustomizeHeader = ({
  width,
  height,
  metricHeight,
  metricWidth,
  size,
}: Props) => {
  return (
    <div className="customize-heading">
      <h2 className="purple customize-header">Need a little touch-up?</h2>
      <p className="purple large-text">{size} BrixPix</p>
      <p className="purple">
        {width} x {height} inches / {metricWidth} cm x {metricHeight} cm
      </p>
    </div>
  );
};
