import React from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

import { addToOrder } from '../../api';
import { isTablet } from '../../helpers';
import { ItemInfo } from '../../types';

import { CustomizeStep } from './CustomizeStep';
import { ReviewStep } from './ReviewStep';
import { ShapeStep } from './ShapeStep';
import { SizeStep } from './SizeStep';
import { StepHeader } from './StepHeader';
import { StepSelectorSection } from './StepSelectorSection';
import { UploadStep } from './UploadStep';
import './OrderProcess.css';

/*
 * OrderProcess
 * displays entire order process from the first step up until before the cart
 */

export type State = {
  stepNum: number;
  info: Partial<ItemInfo>;
  noNext: boolean;
  redirect: string | null;
  tablet: boolean;
};

export class OrderProcess extends React.Component<{}, State> {
  customizeRef = React.createRef<CustomizeStep>();

  constructor(props: {}) {
    super(props);
    this.state = {
      stepNum: 1, // current step, 1-5
      info: {}, // info about item so far
      noNext: true, // if continue to next step should be prohibited

      redirect: null, // string to redirect to if necessary
      tablet: isTablet(),
    };
  }

  onResize = () => {
    this.setState({
      tablet: isTablet(),
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    return () => window.removeEventListener('resize', this.onResize);
  }

  addToCart = () => {
    addToOrder(this.state.info as ItemInfo);
  };

  /*
   * finishStep
   * wraps up loose ends in one step and prepares to move onto the next
   */
  finishStep = (info: Partial<ItemInfo>) => {
    this.setState((prevState) => ({
      noNext: false,
      info: _.merge(prevState.info, info),
    }));
  };

  /*
   * changeStep
   * moves from one step to another
   */
  changeStep = (stepNum: number) => {
    this.setState({
      noNext: true,
    });

    if (stepNum === 5) {
      this.customizeRef.current?.makeBrix();
    } else {
      this.setState({
        stepNum: stepNum,
      });
    }
  };

  finishBrixilating = (url: string) => {
    const tempInfo = this.state.info;
    tempInfo.newURL = url;
    this.setState({
      info: tempInfo,
      noNext: false,
      stepNum: 5,
    });
  };

  render() {
    const { info, redirect, stepNum, noNext } = this.state;

    return (
      <div className="page order-process-page">
        {/* redirect to cart */}
        {redirect && <Redirect to={redirect} />}
        {/* header */}
        <StepHeader stepNum={stepNum} />
        {/* steps based on stepNum */}
        {this.state.stepNum === 1 && <ShapeStep finish={this.finishStep} />}
        {this.state.stepNum === 2 && (
          <SizeStep
            info={info as Pick<ItemInfo, 'shape' | 'shapeDescription'>}
            finish={this.finishStep}
          />
        )}
        {this.state.stepNum === 3 && (
          <UploadStep
            info={info as Pick<ItemInfo, 'sizeData' | 'shape'>}
            finish={this.finishStep}
          />
        )}
        {this.state.stepNum === 4 && (
          <CustomizeStep
            info={
              info as Pick<ItemInfo, 'sizeData' | 'url' | 'width' | 'height'>
            }
            finish={this.finishStep}
            finishBrixilating={this.finishBrixilating}
            ref={this.customizeRef}
          />
        )}
        {this.state.stepNum === 5 && (
          <ReviewStep
            info={info as Pick<ItemInfo, 'shape' | 'sizeData' | 'newURL'>}
          />
        )}
        {/* footer for moving between steps */}
        <StepSelectorSection
          stepNum={stepNum}
          changeStep={this.changeStep}
          noNext={noNext}
          finishOrder={this.addToCart}
        />
      </div>
    );
  }
}
