import React, { useState, useEffect } from 'react';

import { createCoupon, getCoupons } from '../../api';
import { BladePosition, SidewaysButton } from '../../components';
import { Coupon } from '../../../../common/types';

import { CouponRecord, NewCoupon, ViewSelector } from './components';
import { CouponDisplay, couponViewOptions } from './types';

export type Props = {
  position: BladePosition;
};

export const Coupons = ({ position }: Props) => {
  const [coupons, setCoupons] = useState<Coupon[] | null>(null);
  const [activeCoupons, setActiveCoupons] = useState<Coupon[] | null>(null);
  const [inactiveCoupons, setInactiveCoupons] = useState<Coupon[] | null>(null);
  const [percentCoupons, setPercentCoupons] = useState<Coupon[] | null>(null);
  const [dollarsCoupons, setDollarsCoupons] = useState<Coupon[] | null>(null);

  const [couponComponents, setCouponComponents] = useState<
    JSX.Element[] | null
  >(null);
  const [activeComponents, setActiveComponents] = useState<
    JSX.Element[] | null
  >(null);
  const [inactiveComponents, setInactiveComponents] = useState<
    JSX.Element[] | null
  >(null);
  const [percentComponents, setPercentComponents] = useState<
    JSX.Element[] | null
  >(null);
  const [dollarsComponents, setDollarsComponents] = useState<
    JSX.Element[] | null
  >(null);

  const [display, setDisplay] = useState<CouponDisplay>('active');

  const [adding, setAdding] = useState<boolean>(false);

  useEffect(() => {
    getCoupons()
      .then((res: any) => {
        res.data.data && setCoupons(res.data.data);
      })
      .catch((err: any) => console.log(err));
  }, []);

  useEffect(() => {
    if (display === 'active') {
      if (!activeCoupons) {
        setActiveCoupons(coupons && coupons.filter((coupon) => coupon.active));
      }
    } else if (display === 'inactive') {
      if (!inactiveCoupons) {
        setInactiveCoupons(
          coupons && coupons.filter((coupon) => !coupon.active)
        );
      }
    } else if (display === 'percent') {
      if (!percentCoupons) {
        setPercentCoupons(
          coupons && coupons.filter((coupon) => coupon.type === 'Percent')
        );
      }
    } else if (display === 'dollars') {
      if (!dollarsCoupons) {
        setDollarsCoupons(
          coupons && coupons.filter((coupon) => coupon.type === 'Dollars')
        );
      }
    }
  }, [
    display,
    coupons,
    activeCoupons,
    dollarsCoupons,
    inactiveCoupons,
    percentCoupons,
  ]);

  useEffect(() => {
    if (!activeComponents) {
      setActiveComponents(
        activeCoupons &&
          activeCoupons.map((coupon, index) => (
            <CouponRecord key={index} coupon={coupon} />
          ))
      );
    }
  }, [activeCoupons, activeComponents]);

  useEffect(() => {
    if (!inactiveComponents) {
      setInactiveComponents(
        inactiveCoupons &&
          inactiveCoupons.map((coupon, index) => (
            <CouponRecord key={index} coupon={coupon} />
          ))
      );
    }
  }, [inactiveCoupons, inactiveComponents]);

  useEffect(() => {
    if (!percentComponents) {
      setPercentComponents(
        percentCoupons &&
          percentCoupons.map((coupon, index) => (
            <CouponRecord key={index} coupon={coupon} />
          ))
      );
    }
  }, [percentCoupons, percentComponents]);

  useEffect(() => {
    if (!dollarsComponents) {
      setDollarsComponents(
        dollarsCoupons &&
          dollarsCoupons.map((coupon, index) => (
            <CouponRecord key={index} coupon={coupon} />
          ))
      );
    }
  }, [dollarsCoupons, dollarsComponents]);

  useEffect(() => {
    if (!couponComponents) {
      setCouponComponents(
        coupons &&
          coupons.map((coupon, index) => (
            <CouponRecord key={index} coupon={coupon} />
          ))
      );
    }
  }, [coupons, couponComponents]);

  const makeCoupon = (coupon: Omit<Coupon, '_id'>) => {
    createCoupon({ ...coupon, _id: coupon.code })
      .then((res: any) => {
        window.location.reload();
      })
      .catch((err: any) => console.log(err));
  };

  let currComponents;
  switch (display) {
    case 'active':
      currComponents = activeComponents;
      break;
    case 'inactive':
      currComponents = inactiveComponents;
      break;
    case 'percent':
      currComponents = percentComponents;
      break;
    case 'dollars':
      currComponents = dollarsComponents;
      break;
    default:
      currComponents = couponComponents;
      break;
  }

  return (
    <div className={`slider-page coupons-page ${position}`}>
      <div className="coupons-container">
        <div className="admin-header">
          <h2>Coupons</h2>
        </div>
        <ViewSelector
          currView={display}
          changeView={setDisplay}
          viewOptions={couponViewOptions}
        />
        {/* header */}
        <div className="admin-coupons-header">
          <div className="flex-cell left-align-col vertical-padded-cell horizontal-padded-cell underline">
            <p className="large-text purple">Coupon Code</p>
          </div>
          <div className="flex-cell center-align-col vertical-padded-cell underline">
            <p className="large-text purple">Type</p>
          </div>
          <div className="flex-cell center-align-col vertical-padded-cell underline">
            <p className="large-text purple">Quantity</p>
          </div>
          <div className="flex-cell center-align-col vertical-padded-cell underline">
            <p className="large-text purple">Active</p>
          </div>
        </div>
        {/* main content */}
        <div className="admin-coupons-table custom-scroll">
          {adding && (
            <NewCoupon
              hide={() => setAdding(false)}
              createCoupon={makeCoupon}
            />
          )}
          {currComponents}
        </div>
      </div>
      <div className="new-coupon-button">
        <SidewaysButton
          value={adding ? 'Cancel' : 'Add Coupon'}
          action={() => setAdding(!adding)}
          color="eggplant"
          right
        />
      </div>
    </div>
  );
};
