import React, { useState, useEffect } from 'react';

import { Item, Order } from '../../../../common/types';
import { ClassicButton } from '../../components';
import { prepItemForCart } from '../../helpers';
import { ReorderItem as ReorderItemType } from '../../types';
import { addToCart } from '../../api';

import { ReorderItem } from './components';
import './Reorder.css';

/*
 * Reorder
 * Displays all items available to reorder for user
 */

export type Props = {
  email: string;
  orders: Order[] | null;
};

export const Reorder = ({ email, orders }: Props) => {
  const [items, setItems] = useState<Item[]>([]); // arr of items from all orders/suborders
  const [quantities, setQuantities] = useState<string[]>([]); // parallel arr of quants for each item

  /* initialize items and quantities upon receipt of props */
  useEffect(() => {
    // gather all items from all orders
    let tempItems: Item[] = [];
    if (Array.isArray(orders)) {
      orders.forEach((order) =>
        order.suborders.forEach((suborder) =>
          suborder.items.forEach((item) => tempItems.push(item))
        )
      );
    }

    // initialize quantities to empty parallel arr
    const numItems = tempItems.length;
    let tempQuantities: string[] = [];
    for (let i = 0; i < numItems; i++) {
      tempQuantities[i] = '';
    }

    setItems(tempItems.slice());
    setQuantities(tempQuantities.slice());
  }, [orders]);

  // updates state of appropriate item if triggered from that item component
  const updateQuantity = (index: number, value: string) => {
    var tempQuantities = quantities;
    quantities[index] = value;
    setQuantities(tempQuantities.slice());
  };

  // note: adds all items with non-zero, non-blank quantities, then redirects to cart
  const reorder = async () => {
    let cartItems: ReorderItemType[] = [];
    let cartItem: ReorderItemType;
    for (let i = 0; i < items.length; i++) {
      if (parseInt(quantities[i])) {
        cartItem = await prepItemForCart(items[i], parseInt(quantities[i]));
        cartItems.push(cartItem);
      }
    }
    addToCart(email, cartItems); // redirects automatically
  };

  // initializes reorder item components based on all previous items
  const reorderItems = items.map((item, index) => (
    <ReorderItem
      item={item}
      quantity={quantities[index]}
      updateQuantity={updateQuantity}
      index={index}
      key={index}
    />
  ));

  return (
    <div className="account-subpage reorder">
      <h3 className="purple account-header">Reorder</h3>
      <div className="reorder-content">
        <div className="reorder-table">
          <div className="underline" />
          <div>
            <p className="large-text purple underline reorder-table-header">
              Size
            </p>
          </div>
          <div>
            <p className="large-text purple underline center-align-col reorder-table-header">
              Quantity
            </p>
          </div>
          <div>
            <p className="large-text purple underline center-align-col reorder-table-header">
              Amount
            </p>
          </div>
        </div>
        <div className="reorder-table-container custom-scroll">
          <div className="reorder-table">{reorderItems}</div>
        </div>
        <div className="reorder-button-section">
          <div className="reorder-button-wrapper">
            <ClassicButton
              value="Add to Cart"
              color="eggplant"
              action={reorder}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
