import React from 'react';

export const NoAddresses = () => {
  return (
    <div className="no-addresses">
      <p className="purple">
        Looks like you don't have any addresses saved yet. Let's change that!
      </p>
    </div>
  );
};
