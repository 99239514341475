import React, { useState } from 'react';

import { states, abbrevs } from '../../../helpers';
import { select as arrow, minus } from '../../../media';
import { Suborder } from '../../../../../common/types';

import { shippingNames } from '../shippingNames';

export type Props = {
  suborder: Suborder;
};

export const ShippingInfo = ({ suborder }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const streetAdd = suborder.address ? suborder.address.streetAddress : '';
  const streetAdd2 = suborder.address ? suborder.address.streetAddress2 : '';
  const city = suborder.address ? suborder.address.city : '';
  const zip = suborder.address ? suborder.address.zip : '';
  const stateCode = suborder.address
    ? abbrevs[states.indexOf(suborder.address.state)]
    : '';

  const giftMessage = suborder.gift ? 'This IS a gift.' : 'This is NOT a gift.';
  const shipping = shippingNames[suborder.shipping];

  return (
    <div>
      <div className="admin-expand" onClick={() => setExpanded(!expanded)}>
        <p className="inline-block purple">{suborder.address.name}</p>
        {expanded ? (
          <img className="admin-expand-icon" src={minus} alt="click minimize" />
        ) : (
          <img
            className="admin-expand-icon"
            src={arrow}
            alt="click to expand"
          />
        )}
      </div>
      <div className={`pdf-section ${expanded ? 'expanded' : 'collapsed'}`}>
        <p className="purple">{streetAdd}</p>
        <p className="purple">{streetAdd2}</p>
        <p className="purple">
          {city}, {stateCode} {zip}
        </p>
        <br />
        <p className="purple">{giftMessage}</p>
        <br />
        <p className="purple">{shipping}</p>
      </div>
    </div>
  );
};
