import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { UndoHistory } from '../../../components';
import { getDecimal, getHex } from '../../../helpers';
import { colorFunUnpatterned } from '../../../media';
import { PIX_ACROSS_BLOCK, INCHES_PER_BLOCK } from '../../../pixelation';
import { colorPalette, fillOneBigPixel } from '../../../pixelation';


import { ItemInfo } from '../../../types';
import {
  AdvancedEditor,
  BrixCanvas,
  BrixilatedImage,
  BrixilatingMessage,
  CustomizeHeader,
  CustomizationOptions,
  ImageProcessor,
} from './components';
import './CustomizeStep.css';

/*
 * CustomizeStep
 * allows for tweaking and customizing of BrixPix
 */

/*these three arrays will be used to store the data from pixel changes
  so that they can be used after brightness is adjusted */

let xCoords: number[] = [];
let yCoords: number[] = [];
let oldColorIndexes: number[] = [];
let newColorIndexes: number[] = [];

export type Props = {
  info: Pick<ItemInfo, 'sizeData' | 'url' | 'width' | 'height'>;
  finish: (
    newInfo: Pick<ItemInfo, 'colorMap' | 'colorCounts' | 'imageData'>
  ) => void;
  finishBrixilating: (url: string) => void;
};

export type State = {
  newURL: string;
  brightness: number;
  contrast: number;
  saturation: number;
  loading: boolean;
  brixilating: boolean;
  colorCounts: number[] | null;
  colorMap: number[] | null;
  imageData: ImageData | null;
  openMessage: boolean;
  pixelsMessage: boolean;
  advancedEditor: boolean;
  selectedColor: string | null;
  canvasWidth: number;
  canvasHeight: number;
  callCounter: number;
};

export type PixelHistoryElement = {
  x: number;
  y: number;
  oldColorIndex: number;
  newColorIndex: number;
};

export class CustomizeStep extends React.Component<Props, State> {
  processorRef = React.createRef<ImageProcessor>();
  brixRef = React.createRef<BrixCanvas>();




  constructor(props: Props) {
    super(props);


    this.state = {
      newURL: '', // url for pixelated image

      brightness: 100, // brightness, 1-200
      contrast: 100, // contrast, 1-200
      saturation: 100, // saturation, 1-200

      loading: false, // if loading transformation
      brixilating: false, // if loading brix pattern

      colorCounts: null, // arr of how many of each color of Brix
      colorMap: null, // arr of each color in the pixelated image, row-by-row
      imageData: null, // imageData from canvas

      openMessage: false,
      pixelsMessage: false,
      advancedEditor: false,
      selectedColor: null,
      canvasWidth: 0,
      canvasHeight: 0,
      callCounter: 0
    };
  }

  undo = async (pixelInfo: PixelHistoryElement) => {
    // replace new color with old color
    console.log("undo called in CustomizeStep")
    const { x, y, oldColorIndex, newColorIndex } = pixelInfo;
    this.customizePixel(x, y, newColorIndex, oldColorIndex);
  };

  redo = async (pixelInfo: PixelHistoryElement) => {
    // replace old color with new color
    console.log("redo called in CustomizeStep")
    const { x, y, oldColorIndex, newColorIndex } = pixelInfo;
    this.customizePixel(x, y, oldColorIndex, newColorIndex);

  };

  undoHistory = new UndoHistory<PixelHistoryElement>(this.undo, this.redo); //passes undo and redo in as functions for onUndo and onRedo

  /* on mount, starts to initialize image */
  componentDidMount() {
    this.processImage(() => console.log("hi"));
  }

  getOldColorIndex = (
    data: Uint8ClampedArray | null,
    index: number
  ): number => {
    if (data == null) {
      return -1;
    }
    const oldColor = getHex(data[index], data[index + 1], data[index + 2]);
    return colorPalette.indexOf(oldColor.toUpperCase());
  };

  //perhaps change retrievePixelChanges to include the for loop, so 
  //that the image is updated only once which will heavily speed stuff up

  customizePixel = (
    x: number,
    y: number,
    givenOldColorIndex?: number,
    givenNewColorIndex?: number
  ) => {
    if (
      this.state.selectedColor == null ||
      this.state.colorMap == null ||
      this.state.colorCounts == null ||
      this.state.imageData == null
    ) {
      return;
    }
    console.log("x: " + x + " y: " + y);
    const bigPixAcross =
      (this.props.info.sizeData.imperial.width / INCHES_PER_BLOCK) *
      PIX_ACROSS_BLOCK;
    const bigPixDown =
      (this.props.info.sizeData.imperial.height / INCHES_PER_BLOCK) *
      PIX_ACROSS_BLOCK;

    const pixX = Math.floor((x / this.state.canvasWidth) * bigPixAcross);
    const pixY = Math.floor((y / this.state.canvasHeight) * bigPixDown);

    const data = this.state.imageData?.data.slice();
    const { width: pxWidth } = this.state.imageData;

    const pixToCombine = Math.floor(pxWidth / bigPixAcross);
    const startPixel = pxWidth * pixY * pixToCombine + pixX * pixToCombine;

    let index = startPixel * 4;

    const oldColorIndex =
      givenOldColorIndex ?? this.getOldColorIndex(data, index); //this calcuates on its own... hMMMmmmMM

    const newColor = givenNewColorIndex
      ? colorPalette[givenNewColorIndex]
      : this.state.selectedColor;
    const newColorIndex =
      givenNewColorIndex ?? colorPalette.indexOf(newColor.toUpperCase());
    const { red, green, blue } = getDecimal(newColor);

    if (!givenOldColorIndex && !givenNewColorIndex) {
      this.undoHistory.do({ x, y, oldColorIndex, newColorIndex });
    }

    // updates imageData
    fillOneBigPixel(data, index, pixToCombine, pxWidth, red, green, blue);

    // updates colorMap
    const newColorMap = this.state.colorMap.slice();
    newColorMap[bigPixAcross * pixY + pixX] = newColorIndex;

    // updates colorCounts
    const newColorCounts = this.state.colorCounts.slice();
    newColorCounts[oldColorIndex]--;
    newColorCounts[newColorIndex]++;

    // updates state
    const newImageData = new ImageData(data, pxWidth);
    this.setState({
      imageData: newImageData,
      colorMap: newColorMap,
      colorCounts: newColorCounts,
    });

    //adds the changes to the arrays

    xCoords.push(x);
    yCoords.push(y);
    oldColorIndexes.push(oldColorIndex);
    newColorIndexes.push(newColorIndex);

    this.processorRef.current?.updateCanvases(newImageData);
  };

  //it once again could be that updateCanvases is calling before new changes can be made
  //what if we retry old solution but successivly pass the updated pixel data to future calls or find a way to update all pixels at once using the history

  /* start process of retrieving and pixelating image */
  processImage = async (_callback) => {
    console.log("processing image...")
    this.setState({ loading: true });
    await this.processorRef.current?.getImage();
    _callback();
  };

  /* handoff process to brixilation */
  finishProcessing = (
    imageInfo: Pick<ItemInfo, 'colorCounts' | 'colorMap' | 'imageData'>
  ) => {
    const { colorCounts, colorMap, imageData } = imageInfo;
    this.setState({
      colorCounts,
      colorMap,
      imageData,

      loading: false,
    }, () => {
      setTimeout(() => this.retrieveAdvancedEdits(), 500)

    });
    //if we have a 2nd set state, perhaps it will fix the color problems we were experiencing earlier
    this.setState({
      colorCounts,
      colorMap,
      imageData,

      loading: false
    }, () => {
      console.log("WE HAVE FINISHED");
    });
    //this.processorRef.current?.updateCanvases(imageData);
    this.props.finish({
      colorCounts,
      colorMap,
      imageData,
    });
    if (this.state.pixelsMessage === true) {
      toast("If you made pixel changes, we must rebuild them to account for the new settings you just chose. This might take a while if you made a lot!")
      setTimeout(() => this.setState({ pixelsMessage: false }), 1000);
    }
    else {
      toast("We suggest changing the slider settings first, then making pixel changes. Otherwise, we will have to 'rebuild' the pixel changes you made whenever you change the slider settings.")
    }
  };


  redrawImage = () => {
    // from ImageProcessor
    const canvas = document.getElementById('hidden-canvas');

    // from BrixilatedImage
    const smallerCanvas = document.getElementById('smaller-canvas');
    const typedCanvas = smallerCanvas! as HTMLCanvasElement;
    const smallerContext = typedCanvas.getContext('2d');

    smallerContext?.drawImage(
      canvas as CanvasImageSource,
      0,
      0,
      typedCanvas.width,
      typedCanvas.height
    );
  };

  makeBrix = () => {
    this.setState({
      brixilating: true,
    });
    this.brixRef.current?.makeBrix();
  };

  /* finish process of brixilation and send info back to parent */
  finishBrixilating = (url: string) => {
    this.setState({
      brixilating: false,
    });
    this.props.finishBrixilating(url);
  };

  retrieveAdvancedEdits = async () => {
    let counter = 0
    let finalElem = this.undoHistory.past.length - 1
    if (this.undoHistory.past.length > 0) { //check if we should even be doing this first
      while (this.undoHistory.past.length > 0) {
        counter++;
        if (counter === 500) {
          break; //bandaid solution to fix infinite loop issue - seems to exist primarily when theres a ton of colors next to each other
          //could also fix this by checking if the length of the array just isn't going down
        }

        //const undoRes = await this.undoHistory.undo()
        let undoRes = await this.undoHistory.addToFuture()
        if (counter === finalElem) {
          if (undoRes !== undefined) {
            const { x, y, oldColorIndex, newColorIndex } = undoRes;
            let convert = colorPalette[newColorIndex];
            console.log(convert)
            this.setState({ selectedColor: convert })
          }
          //select color in advanced editor and set it to this elem's newColorIndex
        }

      }
      console.log("undoing done");

      while (this.undoHistory.future.length > 0) {
        const redoRes = await this.undoHistory.redo()
      }
    }


  }

  caller = async () => {
    this.setState({ pixelsMessage: true });
    this.trueRetrieval(this.state)
  }

  trueRetrieval = async (prevState) => {
    if (this.state.callCounter === 1) {
      //in 5 seconds, we can process the image again
      setTimeout(() => this.setState(prevState => ({ callCounter: 0 })), 4000)
      console.log(this.state.callCounter);
    }
    else if (this.state.callCounter > 1) {
      console.log(this.state.callCounter);
      //in this case, we have recently processed the image and don't want to again to reduce runtime
    }
    else {
      //we haven't processed the image recently so we can process it
      this.processImage(() => console.log("hi"));
      this.setState(prevState => ({ callCounter: prevState.callCounter + 1 }))
      console.log(this.state.callCounter);
    }


    //the processing of the image could be screwing things up --> what if i also updated pasts and futures
    //to hold an updated "old color index?"
    //setTimeout(() => this.retrieveAdvancedEdits(), 8000)
    //const result = await this.processImage(() => this.retrieveAdvancedEdits()) need to actually call the callback, could pass it down down down
  }


  /*
   * revert
   * return all filters to defaults
   * parameters: none
   * returns: none
   */
  revert = async () => {
    this.setState({
      brightness: 100,
      contrast: 100,
      saturation: 100,
    }, () => {
      this.processImage(console.log("hi"));
    });

  };

  initializeDimensions = (canvasWidth: number, canvasHeight: number) => {
    this.setState({
      canvasWidth,
      canvasHeight,
    });
  };

  renderMainContent = (width: number, height: number) => {
    const { width: metricWidth, height: metricHeight } =
      this.props.info.sizeData.metric;
    const { size } = this.props.info.sizeData;

    return (
      <>
        {/* <CustomizeHeader
          width={width}
          height={height}
          metricWidth={metricWidth} ORIGINAL PLACE
          metricHeight={metricHeight}
          size={size}
        /> */}
        {/* left main content */}
        <div className="visible-customization-container">
          <BrixilatedImage
            sizeData={this.props.info.sizeData}
            loading={this.state.loading}
            getPixelCoordinates={this.customizePixel}
            redrawImage={this.redrawImage}
            initializeDimensions={this.initializeDimensions}
          />
          {/* <p> Tip: To see the image fully zoomed out </p>
            <p> make a pinching motion on your trackpad </p>
            <p> while your cursor is over the image. </p> */}
          {/* right main content */}
          <div className="customization-right">
            <CustomizeHeader
              width={width}
              height={height}
              metricWidth={metricWidth}
              metricHeight={metricHeight}
              size={size}
            />
            {this.state.advancedEditor ? (
              <AdvancedEditor
                selectedColor={this.state.selectedColor}
                onSelectColor={(selectedColor) =>
                  this.setState({ selectedColor })
                }
                undo={this.undoHistory.undo}
                redo={this.undoHistory.redo}
                //picker={this.undoHistory.undo}
                canUndo={this.undoHistory.canUndo()}
                canRedo={this.undoHistory.canRedo()}
              />
            ) : (
              <CustomizationOptions
                options={{
                  brightness: this.state.brightness,
                  contrast: this.state.contrast,
                  saturation: this.state.saturation,
                }}
                updateOption={(
                  key: 'brightness' | 'contrast' | 'saturation',
                  value: number
                ) => {
                  // @ts-ignore
                  this.setState({ [key]: value });
                }
                }

                process={this.caller}
                revert={this.revert}
              />
            )}
            <p
              className="clickable blue advanced-settings-label"
              onClick={() =>
                this.setState((prevState) => ({
                  advancedEditor: !prevState.advancedEditor,
                }))
              }
            >
              {this.state.advancedEditor
                ? 'Back to Regular Editor'
                : 'Advanced Editor'}
            </p>
            <div className="edit-blurb purple small">
              <div
                className="edit-blurb-heading smallish clickable"
                onClick={() =>
                  this.setState((prevState) => ({
                    openMessage: !prevState.openMessage,
                  }))
                }
              >
                <p> Is the Brixilation not fabulous enough? </p>
              </div>
              {this.state.openMessage && (
                <p>
                  We can help. Some photos need a bit of optimization for the
                  best conversion. Email your original photo to
                  solutions@brixpix.com and let us know the shape and size you
                  are looking for. Our team of designers will optimize it and
                  send you a proof for approval before you order.
                </p>
              )}
            </div>
            <ToastContainer autoClose={7000} />
          </div>
        </div>
      </>
    );
  };

  renderOffscreenContent = () => {
    return (
      <>
        <ImageProcessor
          url={this.props.info.url}
          brightness={this.state.brightness}
          contrast={this.state.contrast}
          saturation={this.state.saturation}
          pxWidth={this.props.info.width}
          pxHeight={this.props.info.height}
          width={this.props.info.sizeData.imperial.width}
          height={this.props.info.sizeData.imperial.height}
          finish={this.finishProcessing}
          redrawImage={this.redrawImage}
          ref={this.processorRef}
        />
        <BrixCanvas
          colorMap={this.state.colorMap}
          width={this.props.info.sizeData.imperial.width}
          height={this.props.info.sizeData.imperial.height}
          finish={this.finishBrixilating}
          ref={this.brixRef}
        />
      </>
    );
  };

  renderLoading = () => {
    return <BrixilatingMessage />;
  };

  render() {
    return (
      <div className="customize-step">
        {this.state.brixilating
          ? this.renderLoading()
          :
          this.renderMainContent(
            this.props.info.sizeData.imperial.width,
            this.props.info.sizeData.imperial.height
          )}
        {this.renderOffscreenContent()}
      </div>
    );
  }
}
