import React, { useState, useEffect } from 'react';

import {
  register,
  validateName,
  validateEmail,
  validatePhone,
  validatePasswords,
} from '../../api';
import { Blade, ClassicButton, TextInput } from '../../components';
import {
  envelope as envelopeIcon,
  phone as phoneIcon,
  eye as eyeIcon,
} from '../../media';
import {
  changeField,
  punctuatePhone,
  unpunctuatePhone,
  removePhonePunctuation,
} from '../../helpers';

import './Signup.css';

/*
 * Signup
 * Top-level component for user creating new account
 * Displayed at /signup
 */

export const Signup = () => {
  // hold state of form fields
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  // hold error message (if applicable) for form fields
  const [firstNameMessage, setFirstNameMessage] = useState<string>('');
  const [lastNameMessage, setLastNameMessage] = useState<string>('');
  const [emailMessage, setEmailMessage] = useState<string>('');
  const [phoneMessage, setPhoneMessage] = useState<string>('');
  const [passwordMessage, setPasswordMessage] = useState<string>('');
  const [confirmPasswordMessage, setConfirmPasswordMessage] =
    useState<string>('');

  // tracks if phone number currently punctated/needs to be punctuated
  const [punctuated, setPunctuated] = useState<boolean>(false);

  // tracks visibility of password fields
  const [showPass, setShowPass] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  /*
   * punctuates phone every time it's updated
   */
  useEffect(() => {
    if (!punctuated && phone.length >= 4) {
      setPunctuated(true);
      setPhone(punctuatePhone(phone));
    }
  }, [phone, punctuated]);

  /*
   * typePhone
   * updates phone punctuation and formatting based on user typing
   */
  const typePhone = (value: string) => {
    if (value.length <= 14) {
      setPunctuated(false);

      let tempPhone = unpunctuatePhone(phone, value);
      if (tempPhone !== null) {
        setPhone(tempPhone);
      }
    }
  };

  /*
   * checkForm
   * validates form inputs when user submits
   * displays error messages if errors
   * otherwise creates user
   */
  const checkForm: React.MouseEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();
    var problem = false;

    validateName({ name: firstName, type: 'first' })
      .then((res: any) => {
        problem = problem || res.data.problem;
        setFirstNameMessage(res.data.message);

        validateName({ name: lastName, type: 'last' })
          .then((res: any) => {
            problem = problem || res.data.problem;
            setLastNameMessage(res.data.message);

            validateEmail({ email: email, shouldExist: false })
              .then((res: any) => {
                problem = problem || res.data.problem;
                setEmailMessage(res.data.message);

                validatePhone({ phone: removePhonePunctuation(phone) })
                  .then((res: any) => {
                    problem = problem || res.data.problem;
                    setPhoneMessage(res.data.message);

                    validatePasswords({
                      password: password,
                      confirmation: confirmPassword,
                    })
                      .then((res: any) => {
                        problem = problem || res.data.problem;
                        setPasswordMessage(res.data.message[0]);
                        setConfirmPasswordMessage(res.data.message[1]);

                        if (!problem) {
                          register(email, firstName, lastName, phone, password);
                          console.log('done');
                        }
                      })
                      .catch((err: any) => console.log(err));
                  })
                  .catch((err: any) => console.log(err));
              })
              .catch((err: any) => console.log(err));
          })
          .catch((err: any) => console.log(err));
      })
      .catch((err: any) => console.log(err));
  };

  /*
   * renders input fields and submit button
   */
  return (
    <div className="signup page">
      <Blade
        index={0}
        frozen
        position="left"
        value="Create Account"
        color="blue"
      />
      <div className="signup-container">
        <div className="signup-form-container">
          <h1 className="signup-title">Create your account</h1>
          <p className="purple signup-subtitle">
            Follow these easy steps to create your account. Your BrixPix account
            will allow you to save your images and track your orders.
          </p>
          <form className="signup-form">
            <div className="input-line multi-field">
              <TextInput
                name="First Name"
                value={firstName}
                change={changeField}
                setter={setFirstName}
                messageSetter={setFirstNameMessage}
                message={firstNameMessage}
              />
              <TextInput
                name="Last Name"
                value={lastName}
                change={changeField}
                setter={setLastName}
                messageSetter={setLastNameMessage}
                message={lastNameMessage}
              />
            </div>
            <div className="input-line">
              <TextInput
                name="Email"
                value={email}
                change={changeField}
                setter={setEmail}
                messageSetter={setEmailMessage}
                message={emailMessage}
              />
              <div className="input-icon-container">
                <img className="input-icon" src={envelopeIcon} alt="envelope" />
              </div>
            </div>
            <div className="input-line">
              <TextInput
                name="Phone"
                value={phone}
                change={changeField}
                setter={typePhone}
                messageSetter={setPhoneMessage}
                message={phoneMessage}
              />
              <div className="input-icon-container">
                <img className="input-icon" src={phoneIcon} alt="phone" />
              </div>
            </div>
            <div className="input-line">
              <TextInput
                name="Password"
                value={password}
                change={changeField}
                setter={setPassword}
                messageSetter={setPasswordMessage}
                password={!showPass}
                message={passwordMessage}
              />
              <div className="input-icon-container">
                <img
                  className="input-icon clickable"
                  src={eyeIcon}
                  onClick={() => setShowPass(!showPass)}
                  alt="eye"
                />
              </div>
            </div>
            <p className="form-note small blue">
              Your password must be at least 8 characters long and include at
              least one letter, number, and special character.
            </p>
            <div className="input-line">
              <TextInput
                name="Confirm Password"
                value={confirmPassword}
                change={changeField}
                setter={setConfirmPassword}
                messageSetter={setConfirmPasswordMessage}
                password={!showConfirm}
                message={confirmPasswordMessage}
              />
              <div className="input-icon-container">
                <img
                  className="input-icon clickable"
                  src={eyeIcon}
                  onClick={() => setShowConfirm(!showConfirm)}
                  alt="eye"
                />
              </div>
            </div>

            <div className="signup-button-container">
              <ClassicButton
                color="blue"
                action={checkForm}
                value="Create My Account"
              />
            </div>
          </form>
          <p className="purple signup-bottom">
            Already have an account?{' '}
            <b>
              <a href="/login">Sign in here.</a>
            </b>
          </p>
          <p className="small purple signup-bottom">
            We will never share or sell your information. At BrixPix we value
            privacy.
          </p>
        </div>
      </div>
    </div>
  );
};
