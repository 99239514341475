export type CouponDisplay =
  | 'active'
  | 'inactive'
  | 'percent'
  | 'dollars'
  | 'all';
export type OrderDisplay = 'open' | 'complete' | 'all';
export type AdminDisplay = CouponDisplay | OrderDisplay;

export const couponViewOptions = [
  'active',
  'inactive',
  'percent',
  'dollars',
  'all',
];
export const orderViewOptions = ['open', 'complete', 'all'];
