import React, { useState, useEffect } from 'react';

import { getSizeData } from '../../../api';

/*
 * ReorderItem
 * Displays info for a single item to be reordered
 */

export type Props = {
  item: {
    width: number;
    height: number;
    pixImageUrl: string;
  };
  quantity: string;
  updateQuantity: (index: number, value: string) => void;
  index: number;
};

export const ReorderItem = ({
  item,
  quantity,
  updateQuantity,
  index,
}: Props) => {
  const [size, setSize] = useState<string>('');
  const [price, setPrice] = useState<string>('');

  useEffect(() => {
    const initialize = async () => {
      const res = await getSizeData({
        keys: ['width', 'height'],
        values: [item.width, item.height],
      });
      const sizeData = res.data.data[0];
      setSize(sizeData.size);
      setPrice(sizeData.price);
    };
    if (item) {
      initialize();
    }
  }, [item]);

  return (
    <>
      <div className="center-align-col padded-cell flex-cell vertical-centered-cell reorder-thumbnail">
        <img
          src={item.pixImageUrl}
          className="item-thumbnail"
          alt="pixelated thumbnail"
        />
      </div>
      <div className="flex-cell vertical-centered-cell left-align-cell">
        <p className="purple large-text reorder-table-text">{size} BrixPix</p>
      </div>
      <div className="flex-cell vertical-centered-cell center-align-col">
        <input
          type="text"
          value={quantity}
          name="quantity"
          onChange={(e) => updateQuantity(index, e.target.value)}
          className="quantity-input"
        />
      </div>
      <div className="flex-cell vertical-centered-cell center-align-col">
        <p className="purple large-text reorder-table-text">${price}</p>
      </div>
    </>
  );
};
